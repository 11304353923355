import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { customInputForm } from 'ui/common'
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap'
import { getScheduleTasksV2, updateScheduleTaskV2 } from 'features/dashboard/Task.actionTypes'
import Scheduler from '../Scheduler'
import LoadingSpinner from 'components/utils/LoadingSpinner'

const ScheduledMissionEditModal = ({ slug, toggle, mission, toggleRemove, taskStore }) => {
    const dispatch = useDispatch()

    const handleSubmit = (data) => {
        const { uuid } = mission
        dispatch(updateScheduleTaskV2({ uuid, data })).then(({ error }) => {
            if (!error) {
                dispatch(getScheduleTasksV2({ slug }))
                toggle()
            }
        })
    }

    const initialValues = () => {
        switch (mission.schedule_type) {
            case 'once':
                return {
                    date: mission.date,
                    time: mission.time,
                }
            case 'week_days':
                return {
                    week_days: mission.week_days,
                    time_tz: mission.time_tz,
                }
            case 'execute_every':
                return {
                    execute_every: mission.execute_every,
                    active_from_tz: mission.active_from_tz,
                    active_to_tz: mission.active_to_tz,
                }
            default:
                return {}
        }
    }

    return taskStore.status === 'loading' ? (
        <div style={{ minHeight: '600px' }}>
            <LoadingSpinner />
            <span>Fetching actions...</span>
        </div>
    ) : (

        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`w-100 d-flex justify-content-end align-items-center`}
            >
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="x"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>Edit scheduled mission</h5>
            <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
                {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        {mission.schedule_type === 'once' && (
                            <div className="d-flex justify-content-between align-items-center py-3">
                                {' '}
                                <FormGroup className="w-50 mr-1">
                                    <Label className="modals-labels">Execution date</Label>
                                    <Field
                                        component={customInputForm}
                                        bsSize="md"
                                        type="date"
                                        className="modals-inputs"
                                        value={values.date || ''}
                                        name="date"
                                    />
                                </FormGroup>
                                <FormGroup className="w-50 ml-1">
                                    <Label className="modals-labels">Execution time</Label>
                                    <Field
                                        component={customInputForm}
                                        bsSize="md"
                                        type="time"
                                        timeZone="UTC"
                                        className="modals-inputs"
                                        value={values.time || ''}
                                        name="time"
                                    />
                                </FormGroup>
                            </div>
                        )}

                        {mission.schedule_type === 'week_days' && (
                            <div className="py-3">
                                <FormGroup className="d-flex flex-column ml-2">
                                    <Label className="modals-labels">Execution day(s)</Label>
                                    <div>
                                        <Scheduler
                                            days={values.week_days || []}
                                            onChange={(week_days) => {
                                                setFieldValue('week_days', week_days)
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="ml-2">
                                    <Label className="modals-labels">Execution time</Label>
                                    <Field
                                        component={customInputForm}
                                        type="time"
                                        timeZone="UTC"
                                        style={{ width: '108px' }}
                                        className="modals-inputs"
                                        value={values.time_tz || ''}
                                        name="time_tz"
                                    />
                                </FormGroup>
                            </div>
                        )}

                        {mission.schedule_type === 'execute_every' && (
                            <FormGroup className="d-flex align-items-start justify-content-between py-3">
                                <div>
                                    <Label className="modals-labels">Execute every</Label>
                                    <Field
                                        component={customInputForm}
                                        type="number"
                                        placeholder="00 minutes"
                                        className="modals-inputs"
                                        value={values.execute_every || ''}
                                        name="execute_every"
                                    />
                                </div>
                                <div
                                    className="d-flex flex-column justiy-content-center align-items-end"
                                    style={{ paddingTop: '32px' }}
                                >
                                    <div className="d-flex justify-content-start align-items-center">
                                        <Label className="modals-labels mr-2">from</Label>
                                        <Field
                                            component={customInputForm}
                                            className="modals-inputs"
                                            type="time"
                                            value={values.active_from_tz}
                                            name="active_from_tz"
                                            style={{ width: '180px' }}
                                        />
                                    </div>
                                    <div className="mt-2 d-flex justify-content-center align-items-center">
                                        <Label className="modals-labels mr-2">to</Label>
                                        <Field
                                            component={customInputForm}
                                            className="modals-inputs"
                                            type="time"
                                            value={values.active_to_tz}
                                            name="active_to_tz"
                                            style={{ width: '180px' }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        )}

                        <div
                            style={{ marginTop: '24px' }}
                            className={`w-100 d-flex justify-content-between align-items-center`}
                        >
                            <Button
                                color="none"
                                onClick={() => {
                                    toggleRemove()
                                }}
                                className="modals-delete-btn"
                            >
                                <HiOutlineTrash size={20} className="mr-1" />
                                Delete mission
                            </Button>
                            <div className="d-flex">
                                <Button
                                    color="white"
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                    onClick={() => toggle()}
                                >
                                    Discard
                                </Button>
                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                    {isSubmitting && <Spinner color="white" size="sm ml-2" />}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ScheduledMissionEditModal
