import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { Stage, Layer, Image } from 'react-konva'
import { Card, Row, Col, CardBody, UncontrolledTooltip } from 'reactstrap'
import configureStore from 'store'
import { fileClient } from 'middleware/api'
import { useToggler } from 'utils'
import {
    createPoint,
    stopRecording,
    createStation,
    saveMapStationsAndFinalize,
    getMapStations,
    createZonesPath,
    updateZonesPath,
    getZonesPath,
    getZonesPaths,
    createTraversal,
    resetPathsAndStations,
    getAreaZonesAll,
    canUndo,
    canRedo,
    doUndoRedo,
} from 'features/dashboard/Map.actionTypes'
import { getTeamVehicles, getVehicleCategories } from 'features/dashboard/Vehicle.actionTypes'
import { BiCurrentLocation } from 'react-icons/bi'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import useUnload from './useUnload'
import LeavingPrompt from './LeavingPrompt'

import StationRotate from './StationRotate'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import MiniNavbar from './MapEditorNavbar'
import MapLayerModal from './MapLayerModal'
import { TopButtons, RightButtons } from './MapLayerButtons'
import { handleConversion, handleWheel } from './helpers'
import NewPathLayer from './MapNewPathLayer'
import MapObstacle from './MapObstacle'
import IntermediatePoints from './IntermediatePoints'
import MapStation from './MapStation'
import RenderZonesPaths from './RenderZonesPaths'
import {
    createDevice,
    getDeviceModels,
    getDevices,
    resetDevices,
} from 'features/dashboard/RosSetup.actionTypes'
import MapDevice from './MapDevice'
import MapDeviceNew from './MapDeviceNew'
import useImage from 'use-image'
import InfoHover from './InfoHover'
import { getMapSource, saveMapSource } from 'db'

const MapLayer = ({ map, slug, devices, parentStatus }) => {
    const dispatch = useDispatch()
    const store = configureStore()
    const isActive = 'map-layer'
    const [traversalSelected] = useImage('/svgs/map-layer/traversal-selected.svg')
    const [sizes, setSize] = useState({
        width: 1,
        height: 600,
        ratio: 4,
        scale: 0.5,
    })
    const [modal, toggleModal] = useToggler()
    const [image, setImage] = useState(null)
    const [status, setStatus] = useState('loading')
    const [newPoint, setNewPoints] = useState(false)
    const [initStations, setInitStations] = useState(() => map.stations)
    const [stations, setStations] = useState(() => map.stations)
    const [initialDevices, setInitialDevices] = useState(() => devices)
    const [stateDevices, setDevices] = useState(() => devices)
    const [zonesPaths, setZonesPaths] = useState(() => map.zonesPaths)
    const [zones, setZones] = useState(() => map.zones)
    const [cursor, setCursor] = useState(null)
    const [pointdata, setPointdata] = useState(null)
    const [hover, setHover] = useState('')
    const [stageRef, setStageRef] = useState(null)
    const [recording, setRecording] = useState(false)
    const [preventStuckOnNavigation, setPreventStuckOnNavigation] = useState(false)
    const [windows, setWindows] = useState({
        point: false,
        path: false,
        intermediatePoint: false,
        save: false,
        reset: false,
        removePath: false,
        clickPoint: false,
        manualPath: false,
        editStation: false,
        createStation: false,
        zonesPath: false,
        editZonesPath: false,
        removeZonesPath: false,
        removeStation: false,
        createDevice: false,
        editDevice: false,
        removeDevice: false,
    })
    const [editPoint, setEditpoint] = useState(false)
    const [popover, setPopover] = useState(false)
    const [pathPopover, setPathPopover] = useState(false)
    const [path, setPath] = useState({})
    const [connectPoint, setConnectPoint] = useState([])
    const [icon, setIcon] = useState(false)
    const [destinationPoints, setDestinationPoints] = useState([])
    const [intermediate, setIntermediate] = useState([])
    const [rectangles, setRectangles] = useState([])
    const [selectedId, selectShape] = useState(null)
    const [rotationAdded, setRotationAdded] = useState(false)
    const [pos, setPos] = useState('')
    const [changes, setChanges] = useState(false)
    const [draw, setdraw] = useState(false)
    const [prompt, togglePrompt] = useToggler()
    const [triggerExit, setTriggerExit] = useState({
        onOk: false,
        path: '',
    })
    const [recordingData, setRecordingData] = useState(null)
    const [clickedStation, setClickedStation] = useState(null)
    const [clickedDevice, setClickedDevice] = useState(null)
    const [traversalCreate, setTraversalCreate] = useState(false)
    const [deviceData, setDeviceData] = useState(null)
    const [newDevice, setNewDevice] = useState(false)
    const [hidePaths, setHidePaths] = useState(false)
    const [showTagZonesNames, setShowTagZonesNames] = useState(false)
    const [pathHovered, setPathHovered] = useState(null)
    const [dataHovered, setDataHovered] = useState(null)
    const [canUndoState, setCanUndo] = useState(map.canUndo)
    const [canRedoState, setCanRedo] = useState(map.canRedo)

    const [sizeText, setSizeText] = useState(14 / stageRef?.scaleX())

    const winRef = useRef(null)
    const newZoneRef = useRef(null)

    const biggerMapSize = 1000000
    const areaUuid = map?.areas?.uuid
    const { x, y, width, height } = map.aoi
    const { original_height, original_width, resolution, origin_x, origin_y } = map?.areaDetails
    const icon_proportion = 0.03614457831
    const iconsize = icon_proportion * width
    const ox = Math.abs(Math.floor(origin_x / resolution))
    const oy = original_height - Math.abs(Math.floor(origin_y / resolution))
    const Xcords = ((pos[0] - ox) * resolution).toFixed(2)
    const YCords = -((pos[1] - oy) * resolution).toFixed(2)

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)

        return () => {
            document.removeEventListener('keydown', escFunction, false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateMapSize = () => {
        if ((status !== 'image-error' || status !== 'loading') && winRef.current) {
            const { original_height, original_width } = map.areaDetails
            const { height, width } = map.aoi
            const largestPoint = original_height > original_width ? original_height : original_width
            const ratio = width / height
            setSize({
                width: winRef.current.offsetWidth,
                height: winRef.current.offsetWidth / ratio,
                largestPoint,
                scale: (winRef.current?.offsetWidth - 100) / width,
                x: winRef.current.offsetWidth,
            })
        }
    }

    useEffect(() => {
        if (modal && (windows.createDevice || windows.editDevice)) {
            dispatch(getDeviceModels({ slug }))
        }
    }, [modal, windows.createDevice, windows.editDevice]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (modal && (windows.editStation || windows.createStation)) {
            dispatch(getVehicleCategories({ slug }))
            dispatch(getTeamVehicles({ slug, text: 'status=true' }))
        }
    }, [modal, windows.editStation, windows.createStation]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Initial size update
        updateMapSize()

        // Handle window resize events
        const handleResize = () => {
            updateMapSize()
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [winRef, map, slug]) // eslint-disable-line react-hooks/exhaustive-deps

    const getImage = async () => {
        if(parentStatus !== 'loaded') return
        const imagePath = map?.areaDetails?.image;
        const extracted = imagePath?.match(/\/areas\/(\d+)-/)[1];
        const localStorageKey = `gen-svg-${extracted}-${areaUuid}`
        setStatus('loading')

        // Helper function to set the image and status
        const setImageAndStatus = (imageSrc) => {
            const image = new window.Image()
            image.src = imageSrc
            setImage(image)
            setStatus('loaded')
        }

        try {
            // Try to fetch from IndexedDB first
            const cachedImage = await getMapSource(localStorageKey)
            if (cachedImage) {
                Promise.all([
                    dispatch(getAreaZonesAll(areaUuid)),
                    dispatch(getMapStations({ uuid: areaUuid })),
                    dispatch(getZonesPaths(areaUuid)),
                    dispatch(getDevices({ slug })),
                    dispatch(canUndo(areaUuid)),
                    dispatch(canRedo(areaUuid)),
                ]).then(() => {
                    setImageAndStatus(cachedImage) // Use cached image if available
                })
                return // Exit early if cached image is used
            }
            // Fetch from the server if no cached image
            const [imageRes] = await Promise.all([
                fileClient.get(`/indoors/area/${areaUuid}/gen.svg`),
                dispatch(getAreaZonesAll(areaUuid)),
                dispatch(getMapStations({ uuid: areaUuid })),
                dispatch(getZonesPaths(areaUuid)),
                dispatch(getDevices({ slug })),
                dispatch(canUndo(areaUuid)),
                dispatch(canRedo(areaUuid)),
            ])

            const reader = new window.FileReader()
            reader.readAsDataURL(imageRes.data)

            reader.onload = async () => {
                const imageData = reader.result

                // Save the image in IndexedDB and set image
                try {
                    await saveMapSource(localStorageKey, imageData)
                    setImageAndStatus(imageData)
                } catch (error) {
                    console.error('Error saving image to storage:', error)
                    setStatus('image-error')
                }
            }
        } catch (error) {
            console.error('Error fetching image:', error)
            setStatus('image-error')
        }
    }

    useEffect(() => {
        getImage()
    }, [slug, parentStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    //if the modal is closed by clicking outside, this changes all windows to false, because otherwise
    //the next time you open the same modal, it will be empty
    useEffect(() => {
        if (modal === false) {
            setWindows({
                point: false,
                path: false,
                intermediatePoint: false,
                save: false,
                reset: false,
                removePath: false,
                clickPoint: false,
                editStation: false,
                createStation: false,
                zonesPath: false,
                editZonesPath: false,
                removeZonesPath: false,
                removeStation: false,
                createDevice: false,
                editDevice: false,
                removeDevice: false,
            })
            setPopover(false)
            setPathPopover(false)
        }
    }, [modal])

    useEffect(() => {
        setStations([...map?.stations])
        setInitStations([...map?.stations])
    }, [dispatch, map?.stations])

    useEffect(() => {
        setZones({ ...map.zones })
    }, [dispatch, map?.zones])

    useEffect(() => {
        setZonesPaths([...map.zonesPaths])
    }, [dispatch, map.zonesPaths])

    useEffect(() => {
        setDevices([...devices])
        setInitialDevices([...devices])
    }, [dispatch, devices])

    useEffect(() => {
        setCanUndo(map.canUndo)
    }, [map.canUndo])

    useEffect(() => {
        setCanRedo(map.canRedo)
    }, [map.canRedo])

    useUnload((e) => {
        if (recording) {
            e.preventDefault()
            e.returnValue = ''
            dispatch(stopRecording(recordingData))
        }
        if (changes) {
            e.preventDefault()
            e.returnValue = ''
        }
    })

    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            dispatch(getMapStations({ uuid: areaUuid }))
            setDestinationPoints([])
            setConnectPoint([])
            setIntermediate([])
            setdraw(false)
            setWindows({ manualPath: false })
            setClickedStation(null)
            setWindows({
                editStation: false,
                createStation: false,
                removeStation: false,
            })
            setNewPoints(false)
            setEditpoint(false)
            setRectangles([])
            setRotationAdded(false)
            setNewDevice(false)
            setPointdata(null)
            setDeviceData(null)
            setTraversalCreate(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleMouse = (e) => {
        const stage = stageRef
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setSizeText(14 / stage.scaleX())
        setCursor(pos)
        setPos(pos)
    }

    const onStageRefUpdate = (node) => {
        if (node && !stageRef) {
            setStageRef(node)
        }
    }

    const handleDragStart = (e) => {
        if (!editPoint) return
    }

    const handleDragEndStation = (event, point) => {
        const { x, y } = event.target.attrs
        const findIndex = stations.findIndex((station) => station.uuid === point.uuid)
        const { points_in_meters, ...hasMovedPoint } = stations[findIndex]
        stations[findIndex] = {
            ...hasMovedPoint,
            points: [Math.floor(x), Math.floor(y)],
        }
        setStations([...stations])
        if (
            hasMovedPoint?.points[0] !== stations[findIndex]?.points[0] ||
            hasMovedPoint?.points[1] !== stations[findIndex]?.points[1]
        )
            setChanges(true)
    }

    const handleDragEndDevice = (event, device) => {
        const { x, y } = event.target.attrs
        const findIndex = stateDevices.findIndex((init) => init.uuid === device.uuid)
        const movedDevice = stateDevices[findIndex]
        stateDevices[findIndex] = {
            ...movedDevice,
            points: [Math.floor(x), Math.floor(y)],
        }
        setDevices([...stateDevices])
        if (
            movedDevice?.points[0] !== stateDevices[findIndex]?.points[0] ||
            movedDevice?.points[1] !== stateDevices[findIndex]?.points[1]
        )
            setChanges(true)
    }

    const getCurrentMousePointer = (stage) => {
        if (!stage.children[0]?.attrs.x && !stage.children[0]?.attrs.y) {
            return {
                x: (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX(),
                y: (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY(),
            }
        }
        return {
            x:
                (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX() -
                stage.children[0].attrs.x,
            y:
                (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY() -
                stage.children[0].attrs.y,
        }
    }

    const handleNewPoint = (point) => {
        setPointdata(point)
        if (!point) return
        const stage = stageRef
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setNewPoints(true)
        setCursor(pos)
        setRotationAdded(true)
        setRectangles([point])
    }

    const handleNewDevice = (device) => {
        if (!device) return
        const stage = stageRef
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setPointdata(device)
        setDeviceData([device])
        setNewDevice(true)
        setCursor(pos)
    }

    const handleMouseMove = () => {
        const stage = stageRef
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setSizeText(14 / stage.scaleX())
        if (newPoint || newDevice) {
            setCursor(pos)
        }
    }

    const handleStageClick = async (e) => {
        if (!windows.manualPath && destinationPoints.length === 0) return
        const stage = stageRef
        const mousePointTo = getCurrentMousePointer(stage)
        e.evt.preventDefault()
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        if (!pathHovered) {
            setConnectPoint([...connectPoint, ...pos])
        }
        setIntermediate([...intermediate, [...pos]])
    }

    const handleApiRequest = (uuid) => {
        if (intermediate.length > 0) {
            const data = {
                source_point: destinationPoints[0],
                destination_point: uuid,
                intermediate_points: intermediate,
            }

            dispatch(createZonesPath({ uuid: areaUuid, data })).then((res) => {
                dispatch(getMapStations({ uuid: areaUuid }))
                dispatch(getZonesPaths(areaUuid))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
                setWindows({
                    manualPath: false,
                })
                setConnectPoint([])
                setDestinationPoints([])
                setIntermediate([])
                setdraw(false)
            })
        }

        if (intermediate.length === 0) {
            const data = {
                source_point: destinationPoints[0],
                destination_point: uuid,
            }
            dispatch(createZonesPath({ uuid: areaUuid, data })).then((res) => {
                dispatch(getMapStations({ uuid: areaUuid }))
                dispatch(getZonesPaths(areaUuid))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
                setWindows({
                    manualPath: false,
                })
                setConnectPoint([])
                setDestinationPoints([])
                setIntermediate([])
                setdraw(false)
            })
        }
    }

    const resetMap = () => {
        if (
            stations.length > 0 ||
            zonesPaths.length > 0 ||
            destinationPoints.length > 0 ||
            stateDevices.length > 0
        ) {
            setZonesPaths([])
            setStations([])
            setDevices([])
            dispatch(resetDevices({ uuid: areaUuid }))
            dispatch(resetPathsAndStations(areaUuid)).then(() => {
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
            })
        }
    }

    const handlePathMove = (e) => {
        const stage = stageRef
        const newPathLayer = newZoneRef.current
        stage.container().style.cursor = 'pointer'
        // if (connectPoint?.length === 0) return
        const { x, y } = getCurrentMousePointer(stage)
        const mousePointTo = getCurrentMousePointer(stage)
        const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
        setCursor(pos)
        newPathLayer.setPoints([...connectPoint, x, y])
        newPathLayer.draw()
        newPathLayer.parent.draw()
    }

    const handleSaveMap = () => {
        if (rectangles.length > 0) {
            let data = { ...rectangles[0], rotation: 0 }

            return dispatch(
                createStation({
                    uuid: areaUuid,
                    data: handleConversion(data),
                })
            ).then(() => {
                dispatch(getMapStations({ uuid: areaUuid }))
                setRotationAdded(false)
                setRectangles([])
            })
        } else {
            let stationsData = [...stations]
            let devicesData = [...stateDevices]
            const changedStations = stationsData.filter(
                (station, i) => JSON.stringify(station) !== JSON.stringify(initStations[i])
            )

            const changedDevices = devicesData.filter(
                (device, i) => JSON.stringify(device) !== JSON.stringify(initialDevices[i])
            )
            if (changedStations.length === 0 && changedDevices.length === 0) return

            dispatch(
                saveMapStationsAndFinalize({
                    slug,
                    uuid: areaUuid,
                    stations: handleConversion(changedStations),
                    devices: changedDevices,
                })
            ).then(() => {
                setChanges(false)
                setEditpoint(false)
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
            })
        }
    }

    const handleMouseDown = (point) => {
        if (newPoint) {
            let data

            data = {
                ...point,
                points: [cursor[0], cursor[1]],
                id: 'newstation',
            }
            selectShape('newstation')
            setRotationAdded(true)
            setRectangles([data])
            setNewPoints(false)
        }

        if (newDevice) {
            let data

            data = {
                ...point,
                points: [cursor[0], cursor[1]],
                id: 'newstation',
            }

            dispatch(createDevice({ data, uuid: areaUuid })).then((res) => {
                dispatch(getDevices({ slug }))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
                setPointdata(null)
                setNewDevice(false)
            })
        }

        //without uuid, you can click anywhere to create a new point, after you have used advanced or quick point option
        if (editPoint && point.uuid && rectangles.length === 0) {
            let data = {
                ...point,
            }
            const stage = stageRef
            const mousePointTo = getCurrentMousePointer(stage)
            const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
            setCursor(pos)
            selectShape(point.id)
            setStations([...stations.filter((station) => station.uuid !== data.uuid)])
            setRotationAdded(true)
            setRectangles([data])
        }
    }

    const history = useHistory()

    const handleGoToIntendedPage = useCallback((location) => history.push(location), [history])

    useEffect(() => {
        if (triggerExit.onOk) {
            handleGoToIntendedPage(triggerExit.path)
        }
        const unblock = history.block((location) => {
            if (location.pathname !== `/dashboard/${slug}/maps/`) {
                if (changes === true || recording === true) togglePrompt()
            }
            setTriggerExit((obj) => ({ ...obj, path: location.pathname }))
            if (changes === false && recording === false) {
                return true
            }

            return false
        })
        return () => {
            unblock()
        }
    }, [changes, recording, history, preventStuckOnNavigation]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleTraversal = (path) => {
        const data = traversalCreate
            ? {
                  ...pointdata,
                  points: cursor,
                  path: path.uuid,
              }
            : {
                  ...pointdata,
                  points: cursor,
                  path: path.uuid,
                  source_point: path.source_point,
              }

        dispatch(createTraversal({ uuid: areaUuid, data })).then((res) => {
            if (traversalCreate) {
                setNewPoints(false)
                setRotationAdded(false)
                setTraversalCreate(false)
            }
            if (res.error) return
            if (draw) {
                const data =
                    intermediate.length >= 1
                        ? {
                              source_point: destinationPoints[0],
                              destination_point: res.payload.data.uuid,
                              intermediate_points: intermediate,
                          }
                        : {
                              source_point: destinationPoints[0],
                              destination_point: res.payload.data.uuid,
                          }

                setDestinationPoints([])
                setConnectPoint([])
                setIntermediate([])
                setWindows({ manualPath: false })
                setdraw(false)
                dispatch(createZonesPath({ uuid: areaUuid, data }))
            }
            dispatch(getZonesPaths(areaUuid))
            dispatch(getMapStations({ uuid: areaUuid }))
            dispatch(canRedo(areaUuid))
            dispatch(canUndo(areaUuid))
        })
    }

    const handleStationRotate = (data, i) => {
        const rects = rectangles.slice()

        rects[i] = data
        if (!newPoint && editPoint) {
            setStations([...stations, data])
            setRotationAdded(false)
            setRectangles([])
        } else {
            dispatch(
                createStation({
                    uuid: areaUuid,
                    data: handleConversion(data),
                })
            ).then((res) => {
                dispatch(getMapStations({ uuid: areaUuid }))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
                setRotationAdded(false)
                setRectangles([])
            })
        }
    }

    const handleDragChangeInterPoints = (e, path, intermediatepoint) => {
        const uuid = path.uuid
        let data
        let pointsArray = [...path.intermediate_points]
        const foundItem = pointsArray.findIndex((interPoint) => interPoint === intermediatepoint)

        pointsArray[foundItem] = [Math.round(e.target.x()), Math.round(e.target.y())]

        data = {
            source_point: path.source_point,
            destination_point: path.destination_point,
            intermediate_points: pointsArray,
        }

        dispatch(
            updateZonesPath({
                uuid,
                data,
            })
        ).then(({ error }) => {
            if (!error) return
            dispatch(getZonesPaths(areaUuid))
            dispatch(getZonesPath(uuid))
            dispatch(canRedo(areaUuid))
            dispatch(canUndo(areaUuid))
        })
    }

    const handleUndo = () => {
        dispatch(doUndoRedo(areaUuid, 'undo')).then(() => {
            dispatch(getMapStations({ uuid: areaUuid }))
            dispatch(getZonesPaths(areaUuid))
            dispatch(getDevices({ slug }))
            dispatch(canRedo(areaUuid))
        })
    }

    const handleRedo = () => {
        dispatch(doUndoRedo(areaUuid, 'redo')).then(() => {
            dispatch(getMapStations({ uuid: areaUuid }))
            dispatch(getZonesPaths(areaUuid))
            dispatch(getDevices({ slug }))
            dispatch(canUndo(areaUuid))
        })
    }

    return (
        <>
            <LeavingPrompt
                modal={prompt}
                toggle={togglePrompt}
                changes={changes}
                setChanges={setChanges}
                recording={recording}
                setRecording={setRecording}
                setTriggerExit={setTriggerExit}
                recordingData={recordingData}
                setRecordingData={setRecordingData}
                handleSaveMap={handleSaveMap}
                preventStuckOnNavigation={preventStuckOnNavigation}
                setPreventStuckOnNavigation={setPreventStuckOnNavigation}
            />

            <div className="w-100" style={{ marginTop: '-45px' }} data-testid="map">
                <h3>Maps</h3>
            </div>

            <Row
                className="mt-3"
                onClick={() => {
                    setPopover(false)
                    setPathPopover(false)
                }}
            >
                <Col>
                    {status !== 'image-error' ? (
                        <div ref={winRef}>
                            <Card
                                className="card-box shadow-none border-0 overflow-auto"
                                style={{ maxHeight: '100vh' }}
                            >
                                {status === 'loading' && <LoadingSpinner />}
                                <div className="d-flex justify-content-between align-items-center pt-2 mr-3 my-2">
                                    <MiniNavbar slug={slug} isActive={isActive} />
                                    <div className="ml-5">
                                        <BiCurrentLocation size={28} />
                                        <span>
                                            {' '}
                                            {isNaN(Xcords) ? 0 : Xcords}(m) /{' '}
                                            {isNaN(YCords) ? 0 : YCords}(m)
                                            <HiOutlineInformationCircle
                                                size={18}
                                                className="mb-3"
                                                id="info"
                                            />
                                        </span>
                                    </div>
                                    <UncontrolledTooltip placement="right" target="info">
                                        <div>
                                            Resolution: <small>{resolution}</small>-{' '}
                                            <small>
                                                {original_width} x {original_height}{' '}
                                            </small>{' '}
                                            Grid_size - <small className="px-2">{1} Meters</small>{' '}
                                        </div>
                                    </UncontrolledTooltip>
                                </div>

                                <CardBody className="m-0 p-0 d-flex overflow-hidden">
                                    <Col>
                                        <Stage
                                            role="stage"
                                            width={sizes.width || 400}
                                            height={window.innerHeight - 160}
                                            scaleX={sizes.scale ? sizes.scale : 1}
                                            scaleY={sizes.scale ? sizes.scale : 1}
                                            x={-x * sizes?.scale || 1}
                                            y={-y * sizes?.scale + 50 || 1}
                                            ref={(node) => onStageRefUpdate(node)}
                                            onWheel={(e) => {
                                                const isScrollingDown = e.evt.deltaY > 0
                                                handleWheel(e, stageRef, isScrollingDown)
                                                setSizeText(14 / stageRef.scaleX())
                                            }}
                                            onMouseDown={(e) => {
                                                const clickedOnEmpty =
                                                    e.target === e.target.getStage()
                                                if (clickedOnEmpty) {
                                                    selectShape(null)
                                                }
                                                if (!pointdata || traversalCreate) return
                                                handleMouseDown(pointdata)
                                            }}
                                            onMouseMove={(e) => {
                                                handleMouse(e)
                                                handleMouseMove(e)
                                                if (
                                                    draw === true &&
                                                    destinationPoints.length === 1
                                                ) {
                                                    windows.manualPath && handlePathMove(e)
                                                    windows.manualPath &&
                                                        destinationPoints.length === 1 &&
                                                        setHover('Press ESC to cancel')
                                                }
                                            }}
                                            onClick={(e, id) => {
                                                if (draw === true) {
                                                    handleStageClick(e, id)
                                                }
                                            }}
                                        >
                                            <Provider store={store}>
                                                <Layer md={7} draggable={!editPoint}>
                                                    <Image
                                                        width={original_width}
                                                        height={original_height}
                                                        image={image}
                                                        onMouseEnter={(e) => {
                                                            e.target
                                                                .getStage()
                                                                .container().style.cursor =
                                                                'default'
                                                        }}
                                                    />

                                                    {status !== 'loading'
                                                        ? zones?.tag.map((zone) => (
                                                              <MapObstacle
                                                                  cursor={cursor}
                                                                  stage={stageRef}
                                                                  getCurrentMousePointer={
                                                                      getCurrentMousePointer
                                                                  }
                                                                  width={width}
                                                                  key={zone.uuid}
                                                                  zone={zone}
                                                                  size={sizeText}
                                                                  showTagZonesNames={
                                                                      showTagZonesNames
                                                                  }
                                                              />
                                                          ))
                                                        : null}

                                                    {status !== 'loading'
                                                        ? zones?.charging.map((zone, i) => (
                                                              <MapObstacle
                                                                  cursor={cursor}
                                                                  stage={stageRef}
                                                                  getCurrentMousePointer={
                                                                      getCurrentMousePointer
                                                                  }
                                                                  width={width}
                                                                  key={zone.uuid}
                                                                  zone={zone}
                                                              />
                                                          ))
                                                        : null}
                                                    {status !== 'loading'
                                                        ? zones?.resting.map((zone) => (
                                                              <MapObstacle
                                                                  cursor={cursor}
                                                                  stage={stageRef}
                                                                  getCurrentMousePointer={
                                                                      getCurrentMousePointer
                                                                  }
                                                                  width={width}
                                                                  key={zone.uuid}
                                                                  zone={zone}
                                                              />
                                                          ))
                                                        : null}

                                                    {windows.manualPath &&
                                                        destinationPoints.length !== 0 && (
                                                            <NewPathLayer
                                                                pos={pos}
                                                                size={sizeText}
                                                                hover={hover}
                                                                aoi={map.aoi}
                                                                newZoneRef={newZoneRef}
                                                                connectPoint={connectPoint}
                                                                scale={sizes.scale}
                                                                toggleModal={toggleModal}
                                                            />
                                                        )}

                                                    {pathHovered && (
                                                        <Image
                                                            image={traversalSelected}
                                                            width={
                                                                width * height > biggerMapSize
                                                                    ? iconsize / 2
                                                                    : iconsize
                                                            }
                                                            height={
                                                                width * height > biggerMapSize
                                                                    ? iconsize / 2
                                                                    : iconsize
                                                            }
                                                            offsetX={
                                                                width * height > biggerMapSize
                                                                    ? iconsize / 4
                                                                    : iconsize / 2
                                                            }
                                                            offsetY={
                                                                width * height > biggerMapSize
                                                                    ? iconsize / 4
                                                                    : iconsize / 2
                                                            }
                                                            x={cursor[0]}
                                                            y={cursor[1]}
                                                            onMouseMove={(e) => handleMouse(e)}
                                                            onClick={() => {
                                                                if (pathHovered) {
                                                                    handleTraversal(pathHovered)
                                                                    setPathHovered(null)
                                                                }
                                                            }}
                                                        />
                                                    )}

                                                    {status === 'loaded' &&
                                                        !hidePaths &&
                                                        zonesPaths?.map((path) => (
                                                            <RenderZonesPaths
                                                                key={path?.uuid}
                                                                path={path}
                                                                aoi={map.aoi}
                                                                setIcon={setIcon}
                                                                scale={sizes.scale}
                                                                setPath={setPath}
                                                                setWindows={setWindows}
                                                                toggleModal={toggleModal}
                                                                traversalCreate={traversalCreate}
                                                                editPoint={editPoint}
                                                                setTraversalCreate={
                                                                    setTraversalCreate
                                                                }
                                                                slug={slug}
                                                                pointdata={pointdata}
                                                                cursor={cursor}
                                                                draw={draw}
                                                                destinationPoints={
                                                                    destinationPoints
                                                                }
                                                                setConnectPoint={setConnectPoint}
                                                                setDestinationPoints={
                                                                    setDestinationPoints
                                                                }
                                                                setRotationAdded={setRotationAdded}
                                                                intermediate={intermediate}
                                                                handleTraversal={handleTraversal}
                                                                setPathHovered={setPathHovered}
                                                            />
                                                        ))}

                                                    {status !== 'loading'
                                                        ? zones?.action.map((zone) => (
                                                              <MapObstacle
                                                                  cursor={cursor}
                                                                  stage={stageRef}
                                                                  getCurrentMousePointer={
                                                                      getCurrentMousePointer
                                                                  }
                                                                  width={width}
                                                                  key={zone.uuid}
                                                                  zone={zone}
                                                              />
                                                          ))
                                                        : null}

                                                    {status !== 'loading'
                                                        ? zones?.forbidden.map((zone) => (
                                                              <MapObstacle
                                                                  cursor={cursor}
                                                                  stage={stageRef}
                                                                  getCurrentMousePointer={
                                                                      getCurrentMousePointer
                                                                  }
                                                                  width={width}
                                                                  key={zone.uuid}
                                                                  zone={zone}
                                                              />
                                                          ))
                                                        : null}

                                                    {zonesPaths && status === 'loaded' && editPoint
                                                        ? zonesPaths?.map((path) =>
                                                              path?.intermediate_points?.map(
                                                                  (intermediatepoint, i) => (
                                                                      <IntermediatePoints
                                                                          key={i}
                                                                          path={path}
                                                                          width={width}
                                                                          height={height}
                                                                          slug={slug}
                                                                          intermediatepoint={
                                                                              intermediatepoint
                                                                          }
                                                                          handleMouse={handleMouse}
                                                                          onDragEndChange={
                                                                              handleDragChangeInterPoints
                                                                          }
                                                                      />
                                                                  )
                                                              )
                                                          )
                                                        : null}

                                                    {status === 'loaded' &&
                                                        stateDevices?.map((device) => (
                                                            <MapDevice
                                                                key={device?.uuid}
                                                                device={device}
                                                                size={sizeText}
                                                                aoi={map.aoi}
                                                                editPoint={editPoint}
                                                                setDataHovered={setDataHovered}
                                                                handleDragStart={handleDragStart}
                                                                handleDragEnd={handleDragEndDevice}
                                                                handleMouse={handleMouse}
                                                                largestPoint={sizes.largestPoint}
                                                                icon={icon}
                                                                handleClickDevice={(data) => {
                                                                    setWindows({
                                                                        editDevice: true,
                                                                    })
                                                                    toggleModal()
                                                                    setClickedDevice(data)
                                                                }}
                                                            />
                                                        ))}

                                                    {newDevice &&
                                                        deviceData.map((device, i) => (
                                                            <MapDeviceNew
                                                                key={i}
                                                                device={device}
                                                                aoi={map.aoi}
                                                                size={sizeText}
                                                                cursor={cursor}
                                                                x={
                                                                    newDevice
                                                                        ? cursor && cursor[0] // Check if cursor is defined before accessing its properties
                                                                        : (device.points &&
                                                                              device.points[0]) ||
                                                                          (cursor && cursor[0]) // Check cursor and device.points
                                                                }
                                                                y={
                                                                    newDevice
                                                                        ? cursor && cursor[1]
                                                                        : (device.points &&
                                                                              device.points[1]) ||
                                                                          (cursor && cursor[1])
                                                                }
                                                            />
                                                        ))}

                                                    {status === 'loaded' &&
                                                        stations?.map((station) => (
                                                            <MapStation
                                                                key={station?.uuid}
                                                                size={sizeText}
                                                                station={station}
                                                                stage={stageRef}
                                                                aoi={map.aoi}
                                                                icon={icon}
                                                                setdraw={setdraw}
                                                                setDataHovered={setDataHovered}
                                                                largestPoint={sizes.largestPoint}
                                                                editPoint={editPoint}
                                                                path={windows.manualPath}
                                                                connectPoint={connectPoint}
                                                                handleMouseDown={handleMouseDown}
                                                                setHover={setHover}
                                                                destinationPoints={
                                                                    destinationPoints
                                                                }
                                                                handleApiRequest={handleApiRequest}
                                                                setDestinationPoints={
                                                                    setDestinationPoints
                                                                }
                                                                setConnectPoint={setConnectPoint}
                                                                handleDragStart={handleDragStart}
                                                                handleDragEnd={handleDragEndStation}
                                                                handleMouse={handleMouse}
                                                                handleClickStation={(data) => {
                                                                    setWindows({
                                                                        editStation: true,
                                                                    })
                                                                    toggleModal()
                                                                    setClickedStation(data)
                                                                }}
                                                            />
                                                        ))}

                                                    {rotationAdded &&
                                                        rectangles?.map((rect, i) => {
                                                            return (
                                                                <StationRotate
                                                                    key={i}
                                                                    stage={stageRef}
                                                                    size={sizeText}
                                                                    cursor={pos}
                                                                    newPoint={newPoint}
                                                                    aoi={map.aoi}
                                                                    shape={rect}
                                                                    onSelect={() => {
                                                                        selectShape(rect?.id)
                                                                    }}
                                                                    isSelected={
                                                                        rect?.id === selectedId
                                                                    }
                                                                    rotation={rect.rotation}
                                                                    width={
                                                                        width * height >
                                                                        biggerMapSize
                                                                            ? iconsize / 2
                                                                            : iconsize
                                                                    }
                                                                    height={
                                                                        width * height >
                                                                        biggerMapSize
                                                                            ? iconsize / 2
                                                                            : iconsize
                                                                    }
                                                                    offsetX={
                                                                        width * height >
                                                                        biggerMapSize
                                                                            ? iconsize / 4
                                                                            : iconsize / 2
                                                                    }
                                                                    offsetY={
                                                                        width * height >
                                                                        biggerMapSize
                                                                            ? iconsize / 4
                                                                            : iconsize / 2
                                                                    }
                                                                    x={
                                                                        newPoint
                                                                            ? cursor && cursor[0] // Check if cursor is defined before accessing its properties
                                                                            : (rect.points &&
                                                                                  rect.points[0]) ||
                                                                              (cursor && cursor[0]) // Check cursor and rect.points
                                                                    }
                                                                    y={
                                                                        newPoint
                                                                            ? cursor && cursor[1]
                                                                            : (rect.points &&
                                                                                  rect.points[1]) ||
                                                                              (cursor && cursor[1])
                                                                    }
                                                                    onChange={(data) =>
                                                                        handleStationRotate(data, i)
                                                                    }
                                                                />
                                                            )
                                                        })}
                                                </Layer>
                                            </Provider>
                                        </Stage>
                                    </Col>
                                    <TopButtons
                                        icon={icon}
                                        setIcon={setIcon}
                                        setWindows={setWindows}
                                        toggleModal={toggleModal}
                                        setEditpoint={setEditpoint}
                                        hidePaths={hidePaths}
                                        canRedoState={canRedoState}
                                        canUndoState={canUndoState}
                                        setHidePaths={setHidePaths}
                                        handleRedo={handleRedo}
                                        handleUndo={handleUndo}
                                        showTagZonesNames={showTagZonesNames}
                                        setShowTagZonesNames={setShowTagZonesNames}
                                    />
                                    <RightButtons
                                        setSize={setSize}
                                        hidePaths={hidePaths}
                                        setHidePaths={setHidePaths}
                                        sizes={sizes}
                                        stageRef={stageRef}
                                        editPoint={editPoint}
                                        setEditpoint={setEditpoint}
                                        setWindows={setWindows}
                                        windows={windows}
                                        toggleModal={toggleModal}
                                        setNewPoints={setNewPoints}
                                        handleNewPoint={handleNewPoint}
                                        setPathPopover={setPathPopover}
                                        setPopover={setPopover}
                                        popover={popover}
                                        pathPopover={pathPopover}
                                        setIcon={setIcon}
                                        setSizeText={setSizeText}
                                    />
                                    <InfoHover dataHovered={dataHovered} mapEditors={true} />
                                </CardBody>
                            </Card>
                        </div>
                    ) : (
                        <Redirect to={`/dashboard/${slug}/maps/upload/`} />
                    )}
                </Col>
            </Row>

            <MapLayerModal
                modal={modal}
                toggleModal={toggleModal}
                windows={windows}
                setWindows={setWindows}
                slug={slug}
                handleCreatePoint={({ data }) => createPoint({ data })}
                handleNewPoint={handleNewPoint}
                editPoint={editPoint}
                setEditpoint={setEditpoint}
                path={path}
                areaUuid={areaUuid}
                handleSaveMap={handleSaveMap}
                resetMap={resetMap}
                clickedStation={clickedStation}
                setClickedStation={setClickedStation}
                clickedDevice={clickedDevice}
                setClickedDevice={setClickedDevice}
                setTraversalCreate={setTraversalCreate}
                setIcon={setIcon}
                handleStation={handleStationRotate}
                handleNewDevice={handleNewDevice}
            />
        </>
    )
}

export default MapLayer
