export const getFilteredVehicles = (vehicles, values) => {
    return vehicles?.teamVehicle?.filter((vehicle) => {
        if (
            (vehicle.vehicle_type === 'amr' || vehicle.vehicle_type === 'agv_manageable') &&
            values.required_vehicle_category
        ) {
            return vehicle.category === values.required_vehicle_category
        }
        return true
    })
}
