import React, { useState } from 'react'
import { Button, Spinner, Alert, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import { CommonErrors } from 'ui/common'
import { formDispatchHandler } from 'utils'
import { useHistory } from 'react-router-dom'
import { getMapPaths } from 'features/dashboard/Map.actionTypes'

const MapSaveForm = ({ mapData, getMapData, saveAction }) => {
    const dispatch = useDispatch()
    const { slug } = useParams()
    const [modal, setModal] = useState(false)
    const errors = useSelector(selectors.getMapError)
    const customValue = useSelector(selectors.getCustom)
    let history = useHistory()

    const primaryColor = {
        backgroundColor: customValue.primary_color,
        color: 'white',
    }
    const defaultColor = {
        backgroundColor: '#670D95',
        color: 'white',
    }
    const toggle = () => {
        setModal(!modal)
    }

    const handleSaveMap = formDispatchHandler(dispatch, saveAction)
    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(data, action) => {
                handleSaveMap(
                    Object.assign({}, mapData, (getMapData && getMapData()) || {}, { slug }),
                    action
                )
                dispatch(getMapPaths(slug))
            }}
        >
            {({ isSubmitting, status, isValid }) => (
                <Form>
                    <Button
                        clasname="mr-3"
                        style={customValue.primary_color ? primaryColor : defaultColor}
                        size="lg"
                        onClick={() => {
                            history.push('./../')
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        clasname="mr-3"
                        style={customValue.primary_color ? primaryColor : defaultColor}
                        size="lg"
                        onClick={toggle}
                        data-testid="savebtn"
                    >
                        Save
                        {isSubmitting && (
                            <Spinner size="sm" className="ml-3" data-testid="submitting" />
                        )}
                    </Button>
                    {status === 'saved' && (
                        <Modal isOpen={modal} toggle={toggle} className="model-position">
                            <ModalBody>Map updated successfully.</ModalBody>
                            <ModalFooter>
                                <Button
                                    color="secondary"
                                    style={customValue.primary_color ? primaryColor : defaultColor}
                                    onClick={toggle}
                                >
                                    Ok
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )}

                    {!isValid && errors.area && (
                        <Alert className="col-4 mt-2 text-center ml-auto" color="danger">
                            <p> Ensure area has title and at least 3 elements.</p>
                            <CommonErrors extraFields={['areas', 'figures', 'points', 'title']} />
                        </Alert>
                    )}
                    {!isValid && errors.obstacles && (
                        <Alert className="col-4 mt-2 text-center ml-auto" color="danger">
                            <p>
                                {' '}
                                Ensure obstacles has at least 3 elements and its inside the Area.
                            </p>
                            <CommonErrors extraFields={['areas', 'figures', 'points', 'title']} />
                        </Alert>
                    )}
                </Form>
            )}
        </Formik>
    )
}

export default MapSaveForm
