import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { FormGroup, Label, Button, Input } from 'reactstrap'
import { customInputForm } from 'ui/common'
import {
    cleanFormValues,
    createNewPreset,
    createNewTask,
    createScheduleTaskV2,
    getScheduleTasksV2,
    getTasksV2,
} from 'features/dashboard/Task.actionTypes'
import RenderDropDown from './RenderDropDown'
import Scheduler from '../Scheduler'
import { removeEmptyStringsFromObj, removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'
import { getFilteredVehicles } from './helpers'

const CreateTaskNewVersion = ({
    slug,
    setIcon,
    toggle,
    stations,
    devices,
    categories,
    vehicles,
    actions,
    actionsPresets,
}) => {
    const dispatch = useDispatch()
    const movementOptions = [
        { title: 'Everywhere', uuid: 'everywhere' },
        { title: 'Zone presets', uuid: 'zone_presets' },
        { title: 'Nowhere', uuid: 'nowhere' },
    ]
    const [movement, setMovement] = useState('')
    const [category, setCategory] = useState('')
    const [robot, setRobot] = useState('')
    const mergedArray = [...actions, ...actionsPresets]

    const handleSubmit = async (outputs) => {
        let {
            definition,
            device_state,
            point,
            auto_confirmationSubtask,
            schedule_later,
            new_mission_preset,
            several_occasions_type,
            by,
            run_when,
            number,
            time,
            date,
            ...rest
        } = outputs
        const subtasks = definition?.map(async (def, i) => ({
            index: i + 1,
            auto_confirmation: auto_confirmationSubtask[i],
            action: {
                definition: def.definition || def.uuid,
                ...(def.form_values && {
                    form_values: def.form_values,
                }),
                ...(def.device_type === 'iot'
                    ? {
                          point: def.point_information ? def.point_information.uuid : point[i],
                          device_state: device_state[i],
                      }
                    : def.slug === 'follow_path'
                    ? {
                          path: def.point_information
                              ? def.point_information.uuid
                              : point[i].map((p) => p.uuid),
                      }
                    : {
                          point: def.point_information ? def.point_information.uuid : point[i],
                      }),
            },
        }))
        const updatedSubtask = await Promise.all(subtasks)

        const presetData = {
            ...rest,
            title: number,
            subtasks: updatedSubtask,
        }

        const nullRemoved = removeNullFromObj(presetData)
        const stringRemove = removeEmptyStringsFromObj(nullRemoved)

        if (schedule_later) {
            dispatch(createNewPreset({ slug, data: stringRemove })).then((res) => {
                if (res.error) return
                if (res) {
                    const dataSchedule = {
                        ...rest,
                        task_preset: res.payload.data.uuid,
                        date,
                        time,
                    }
                    const nullRemoved = removeNullFromObj(dataSchedule)
                    const stringRemoveData = removeEmptyStringsFromObj(nullRemoved)
                    const type =
                        run_when === 'run_once'
                            ? 'simple'
                            : several_occasions_type === 'week_days'
                            ? 'repeated'
                            : 'cron'
                    dispatch(createScheduleTaskV2({ slug, data: stringRemoveData, type })).then(
                        ({ error }) => {
                            if (!error) {
                                dispatch(cleanFormValues())
                                setIcon(false)
                                toggle()
                                dispatch(getScheduleTasksV2({ slug }))
                                dispatch(getTasksV2(slug))
                            }
                        }
                    )
                }
            })
        } else {
            if (new_mission_preset) {
                dispatch(createNewPreset({ slug, data: stringRemove })).then(({ error }) => {
                    if (!error) {
                        dispatch(cleanFormValues())
                        setIcon(false)
                        toggle()
                    }
                })
            }
            const data = {
                ...rest,
                number,
                subtasks: updatedSubtask,
            }

            const nullRemoved = removeNullFromObj(data)
            const stringRemoveData = removeEmptyStringsFromObj(nullRemoved)

            dispatch(createNewTask({ slug, data: stringRemoveData })).then(({ error }) => {
                if (!error) {
                    dispatch(cleanFormValues())
                    setIcon(false)
                    toggle()
                    dispatch(getTasksV2(slug))
                }
            })
        }
    }

    return (
        <>
            <div
                className="w-100"
                style={{
                    borderBottom: '1px solid lightgray',
                    padding: '16px 0px',
                }}
            >
                <h5>
                    <strong>Create new mission</strong>
                </h5>
                <span>Set up a new mission for your robots.</span>
            </div>
            <Formik
                initialValues={{
                    point: [],
                    definition: [],
                    looped_task: false,
                    schedule_later: false,
                    by: 'week_days',
                    new_mission_preset: false,
                    run_when: '',
                    priority_level: 0,
                    auto_confirmation: false,
                    several_occasions_type: '',
                    auto_confirmationSubtask: false,
                    device_state: [],
                }}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form className="pt-3" style={{ position: 'relative' }}>
                        <FormGroup>
                            <Label className="modals-labels">Mission name</Label>
                            <Field
                                type="text"
                                name="number"
                                component={customInputForm}
                                value={values.number || ''}
                                className="modals-inputs"
                                placeholder="Mission name"
                            ></Field>
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels">Description</Label>
                            <Field
                                type="text"
                                name="description"
                                className="modals-inputs"
                                placeholder="Description"
                                component={customInputForm}
                                value={values.description || ''}
                            ></Field>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                className="modals-labels"
                                for="definition"
                                style={{
                                    position: 'sticky',
                                    top: '0',
                                    zIndex: '1000',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: '50px',
                                }}
                            >
                                Actions *
                            </Label>
                            <RenderDropDown
                                definitionValues={values.definition}
                                pointValues={values.point}
                                setFieldValue={setFieldValue}
                                device_state={values.device_state}
                                auto_confirmation={values.auto_confirmationSubtask}
                                actions={mergedArray}
                                stations={stations}
                                devices={devices}
                                property="definition"
                                values={values}
                            />

                            <Field
                                component={customInputForm}
                                type="hidden"
                                id="definition"
                                name="definition"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="auto-movement">
                                Autogenerate movement
                            </Label>
                            <DropdownSelect
                                selected={movement}
                                setSelected={setMovement}
                                options={['', ...movementOptions]}
                                setFieldValue={setFieldValue}
                                fieldValue="autogenerate_movement"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="cat">
                                Vehicle category
                            </Label>
                            <DropdownSelect
                                selected={category}
                                setSelected={setCategory}
                                options={['', ...categories]}
                                setFieldValue={setFieldValue}
                                fieldValue="required_vehicle_category"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="vehicle">
                                Robot
                            </Label>
                            <DropdownSelect
                                selected={robot}
                                setSelected={setRobot}
                                options={['', ...(getFilteredVehicles(vehicles, values) || [])]}
                                setFieldValue={setFieldValue}
                                fieldValue="vehicle_uuid"
                            />
                            {/* <Field
                                component={customInputForm}
                                type="select"
                                name="vehicle_uuid"
                                className="modals-inputs"
                                value={values.vehicle_uuid || ''}
                            >
                                <option value=""></option>
                                {vehicles?.teamVehicle?.map(
                                    (vehicle) =>
                                        vehicle.vehicle_type === 'amr' && (
                                            <option key={vehicle.uuid} value={vehicle.uuid}>
                                                {vehicle.verbose_name}
                                            </option>
                                        )
                                )}
                            </Field> */}
                        </FormGroup>
                        <FormGroup>
                            <Label className="modals-labels" for="task_note">
                                Misssion note
                            </Label>
                            <Field
                                component={customInputForm}
                                type="text"
                                id="task_note"
                                name="task_note"
                                placeholder="Mission note"
                                className="modals-inputs"
                                value={values.task_note || ''}
                            />
                        </FormGroup>
                        <FormGroup style={{ marginTop: '24px' }} className="d-flex flex-column">
                            <Label className="modals-labels" for="priority_level">
                                Priority
                            </Label>
                            <Field
                                id="priority_level"
                                name="range"
                                type="range"
                                value={values.priority_level || 0}
                                onChange={(e) => {
                                    setFieldValue('priority_level', Number(e.target.value))
                                }}
                            />
                            <output>{values.priority_level}</output>
                        </FormGroup>
                        <FormGroup switch className="mb-2 ml-3">
                            <Input
                                style={{ height: '20px', width: '36px' }}
                                type="switch"
                                className="custom-switch-form modals-labels"
                                id="auto_confirmation"
                                checked={values.auto_confirmation}
                                name="auto_confirmation"
                                onChange={(e) => {
                                    setFieldValue(e.target.name, !values.auto_confirmation)
                                }}
                            />
                            <Label check className="ml-3 mt-1">
                                Auto confirmation
                            </Label>
                        </FormGroup>
                        <FormGroup switch className="d-flex flex-column mb-2 ml-3">
                            <Input
                                style={{ height: '20px', width: '36px' }}
                                role="switch"
                                type="switch"
                                id="looped_task"
                                className="custom-switch-form modals-labels"
                                checked={values.looped_task}
                                name="looped_task"
                                onChange={(e) => {
                                    setFieldValue(e.target.name, !values.looped_task)
                                    setFieldValue('schedule_later', false)
                                }}
                            />
                            <Label check className="ml-3 mt-1">
                                Looped mission
                            </Label>
                        </FormGroup>

                        <FormGroup switch className="d-flex flex-column mb-2 ml-3">
                            <Input
                                style={{ height: '20px', width: '36px' }}
                                type="switch"
                                role="switch"
                                data-testid="schedule_later"
                                id="schedule_later"
                                checked={values.schedule_later}
                                name="schedule_later"
                                onChange={(e) => {
                                    setFieldValue(e.target.name, !values.schedule_later)
                                    setFieldValue('looped_task', false)
                                }}
                                className="custom-switch-form modals-labels"
                            />
                            <Label check className="ml-3 mt-1">
                                Schedule for later
                            </Label>
                            {values.schedule_later && (
                                <small className="info-text text-meili">
                                    Note: Team's timezone will be used
                                </small>
                            )}
                        </FormGroup>

                        {values.schedule_later ? (
                            <div className="p-2">
                                <FormGroup switch className="ml-3">
                                    <Input
                                        style={{ height: '20px', width: '36px' }}
                                        type="switch"
                                        role="switch"
                                        id="run_once"
                                        checked={values.run_when === 'run_once' || ''}
                                        name="run_once"
                                        onChange={(e) => {
                                            delete values['several_occasions']
                                            setFieldValue('run_when', 'run_once')
                                        }}
                                        className="custom-switch-form modals-labels"
                                    />
                                    <Label check className="ml-3 mt-1">
                                        {' '}
                                        Runs only once
                                    </Label>
                                </FormGroup>
                                {values.run_when === 'run_once' ? (
                                    <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                                        {' '}
                                        <FormGroup>
                                            <Label className="modals-labels">Execution date</Label>
                                            <Field
                                                component={customInputForm}
                                                bsSize="md"
                                                type="date"
                                                className="modals-inputs"
                                                value={values.date || ''}
                                                name="date"
                                            />
                                        </FormGroup>
                                        <FormGroup className="">
                                            <Label className="modals-labels">Execution time</Label>
                                            <Field
                                                component={customInputForm}
                                                bsSize="md"
                                                timeZone="UTC"
                                                type="time"
                                                className="modals-inputs"
                                                value={values.time || ''}
                                                name="time"
                                            />
                                        </FormGroup>
                                    </div>
                                ) : null}

                                <FormGroup switch className="ml-3">
                                    <Input
                                        style={{ height: '20px', width: '36px' }}
                                        type="switch"
                                        role="switch"
                                        id="several_occasions"
                                        checked={values.run_when === 'several_occasions' || ''}
                                        name="several_occasions"
                                        onChange={(e) => {
                                            delete values['run_once']
                                            setFieldValue('run_when', 'several_occasions')
                                        }}
                                        className="custom-switch-form modals-labels"
                                    />
                                    <Label check className="ml-3 mt-1">
                                        Run on several occasions
                                    </Label>
                                </FormGroup>
                                {values.run_when === 'several_occasions' ? (
                                    <div className="p-2">
                                        <FormGroup switch className="ml-3">
                                            <Input
                                                style={{
                                                    height: '20px',
                                                    width: '36px',
                                                }}
                                                type="switch"
                                                role="switch"
                                                id="week_Days"
                                                checked={
                                                    values.several_occasions_type === 'week_days' ||
                                                    ''
                                                }
                                                name="week_days"
                                                onChange={(e) => {
                                                    delete values['execute_every']
                                                    setFieldValue(
                                                        'several_occasions_type',
                                                        'week_days'
                                                    )
                                                }}
                                                className="custom-switch modals-labels"
                                            />
                                            <Label check className="ml-3 mt-1">
                                                Week days{' '}
                                            </Label>
                                        </FormGroup>
                                        {values.several_occasions_type === 'week_days' && (
                                            <>
                                                <FormGroup className="d-flex flex-column">
                                                    <Label className="modals-labels">
                                                        Execution day(s)
                                                    </Label>
                                                    <div>
                                                        <Scheduler
                                                            isDisabled={values.by !== 'week_days'}
                                                            days={values.week_days || []}
                                                            onChange={(week_days) => {
                                                                setFieldValue(
                                                                    'week_days',
                                                                    week_days
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="">
                                                    <Label className="modals-labels">
                                                        Execution time
                                                    </Label>
                                                    <Field
                                                        component={customInputForm}
                                                        type="time"
                                                        timeZone="UTC"
                                                        style={{ width: '108px' }}
                                                        className="modals-inputs"
                                                        value={values.time || ''}
                                                        name="time"
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                        <FormGroup switch className="ml-3">
                                            <Input
                                                style={{
                                                    height: '20px',
                                                    width: '36px',
                                                }}
                                                type="switch"
                                                role="switch"
                                                id="execute_every"
                                                checked={
                                                    values.several_occasions_type ===
                                                        'execute_every' || ''
                                                }
                                                name="execute_every"
                                                onChange={(e) => {
                                                    delete values['week_days']
                                                    setFieldValue(
                                                        'several_occasions_type',
                                                        'execute_every'
                                                    )
                                                }}
                                                className="custom-switch modals-labels"
                                            />
                                            <Label check className="ml-3 mt-1">
                                                Execute every{' '}
                                            </Label>
                                        </FormGroup>
                                        {values.several_occasions_type === 'execute_every' && (
                                            <FormGroup className="d-flex align-items-start justify-content-between">
                                                <div>
                                                    <Label className="modals-labels">
                                                        Execute every
                                                    </Label>
                                                    <Field
                                                        component={customInputForm}
                                                        type="number"
                                                        placeholder="00 minutes"
                                                        className="modals-inputs"
                                                        value={values.execute_every || ''}
                                                        name="execute_every"
                                                        style={{ width: '132px' }}
                                                    />
                                                </div>
                                                <div
                                                    className="d-flex flex-column justiy-content-center align-items-end"
                                                    style={{ paddingTop: '32px' }}
                                                >
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <Label className="modals-labels mr-2">
                                                            from
                                                        </Label>
                                                        <Field
                                                            component={customInputForm}
                                                            className="modals-inputs"
                                                            type="time"
                                                            value={
                                                                values.active_from_tz ||
                                                                values.active_from
                                                            }
                                                            name="active_from"
                                                            style={{ width: '108px' }}
                                                        />
                                                    </div>
                                                    <div className="mt-2 d-flex justify-content-center align-items-center">
                                                        <Label className="modals-labels mr-2">
                                                            to
                                                        </Label>
                                                        <Field
                                                            component={customInputForm}
                                                            className="modals-inputs"
                                                            type="time"
                                                            value={
                                                                values.active_to_tz ||
                                                                values.active_to
                                                            }
                                                            name="active_to"
                                                            style={{ width: '108px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {!values.schedule_later ? (
                            <FormGroup switch className="ml-3">
                                <Input
                                    style={{ height: '20px', width: '36px' }}
                                    type="switch"
                                    role="switch"
                                    id="new_mission_preset"
                                    data-testid="new_mission_preset"
                                    value={values.new_mission_preset === true ? true : false}
                                    name="new_mission_preset"
                                    onChange={(e) => {
                                        setFieldValue(e.target.name, !values.new_mission_preset)
                                    }}
                                    className="custom-switch-form modals-labels"
                                />
                                <Label check className="ml-3 mt-1">
                                    Save as a new mission preset
                                </Label>
                            </FormGroup>
                        ) : null}

                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 justify-content-end align-items-center`}
                        >
                            <div className="d-flex mb-4 mt-2">
                                <Button
                                    onClick={() => {
                                        toggle()
                                        setIcon(false)
                                    }}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    type="submit"
                                    // disabled={isSubmitting}
                                >
                                    Run mission
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CreateTaskNewVersion
