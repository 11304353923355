import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import { selectors } from 'features/dashboard'
import RunMissionPreset from '../Tasks/RunMissionPreset'
import CreateTaskNewVersion from '../Tasks/CreateTaskNewVersion'

const RunMissionModal = ({ slug, modal, toggle, setCurrentPresetPage, setIcon }) => {
    const taskStore = useSelector(selectors.getTask)
    const vehicles = useSelector(selectors.getVehicles)
    const teamType = useSelector(selectors.getTeamType)
    const map = useSelector(selectors.getTeamMap)
    const devices = useSelector(selectors.getDevicess)
    const categories = useSelector(selectors.getVehiclesCategoires)
    const [isCreateNew, setIsCreateNew] = useState(false)

    const getMapPointsByTeamType = () => {
        if (teamType === 'inside') return map.stations
        const points = Object.values(map.outdoorPoints).reduce((acc, point) => {
            acc[point.properties.uuid] = { ...point.properties }
            return acc
        }, {})
        return points
    }

    useEffect(() => {
        if (modal) {
            setIcon(true)
        } else {
            setIcon(false)
        }
    }, [modal, setIcon])

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="md">
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/modal-icons/modal-station-icon.svg"
                        alt="edit"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>

                {!isCreateNew ? (
                    <RunMissionPreset
                        presets={taskStore.presetsV2.results}
                        setCurrentPage={setCurrentPresetPage}
                        nextPagePresets={taskStore.presetsV2.next}
                        slug={slug}
                        setIcon={setIcon}
                        toggle={toggle}
                        isCreateNew={isCreateNew}
                        setIsCreateNew={setIsCreateNew}
                        categories={categories}
                        vehicles={vehicles}
                    />
                ) : (
                    <CreateTaskNewVersion
                        slug={slug}
                        setIcon={setIcon}
                        toggle={() => {
                            toggle()
                            setIsCreateNew(false)
                        }}
                        actions={taskStore?.taskDefinitions}
                        actionsPresets={taskStore?.actionsPresets}
                        categories={categories}
                        vehicles={vehicles}
                        stations={getMapPointsByTeamType()}
                        devices={devices}
                    />
                )}
            </ModalBody>
        </Modal>
    )
}

export default RunMissionModal
