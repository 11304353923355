import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { cancelAllTask } from 'features/dashboard/Task.actionTypes'

const CancelAllTasks = ({ toggleCancelAll, cancelAllModal, slug, setCancelTask }) => {
    const dispatch = useDispatch()
    return (
        <Modal isOpen={cancelAllModal} toggle={toggleCancelAll} centered className="new-modals">
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/modal-icons/modal-station-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleCancelAll}
                    />
                </div>
                <div className="d-flex flex-column pb-4">
                    <span className="org-modal-header">Cancel all missions</span>
                    <span>Are you sure you want to cancel all missions? This cannot be undone.</span>
                </div>
                <div className="d-flex pb-3 pull-right">
                    <Button className="cancel-btn-modal modals-new-btns" onClick={toggleCancelAll}>
                        Close
                    </Button>
                    <Button
                        className="save-btn-modal modals-new-btns"
                        style={{ marginLeft: '12px' }}
                        onClick={() => {
                            dispatch(cancelAllTask({ slug, data: 'slug' })).then(() => {
                                setTimeout(() => {
                                    setCancelTask(false)
                                }, 2000)
                            })
                            toggleCancelAll()
                        }}
                    >
                        Cancel all
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default CancelAllTasks
