import React, { useState, forwardRef, useEffect } from 'react'
import ScheduleTaskDetailV2 from './Tasks/ScheduleTaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))
const ScheduleTasksGridView = ({
    tasks,
    filterLabel,
    slug,
    upcomingTask,
    setTaskHovered,
    currentLayout,
}) => {
    const [upcominglist, setUpcominglist] = useState(upcomingTask)

    useEffect(() => {
        if (JSON.stringify(tasks) !== JSON.stringify(upcominglist))
            setUpcominglist(
                tasks?.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [tasks]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="bg-white shadow-none cards-wrap">
            <div
                tag={Wrapper}
                handle=".handle"
                list={upcominglist}
                style={{
                    overflow: 'auto',
                    display:
                        currentLayout && upcominglist.length > 0 &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'grid'
                            : 'flex',
                    gridTemplateColumns:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 columns for grid layout
                            : 'none',
                    gridTemplateRows:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 rows for grid layout
                            : 'none',
                    justifyContent:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'flex-start'
                            : 'initial',
                    alignItems:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'center'
                            : 'initial',
                }}
            >
                {upcominglist?.length === 0 && (
                    <h5 className="pl-3 py-3">There are currently no upcoming missions.</h5>
                )}
                {upcominglist?.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        {
                            <ScheduleTaskDetailV2
                                task={task}
                                slug={slug}
                                setTaskHovered={setTaskHovered}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ScheduleTasksGridView
