import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import emailjs from 'emailjs-com'
import { selectors } from 'features/dashboard'
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap'
import { Field, Formik } from 'formik'
import { customInputForm } from 'ui/common'
import { getTeams } from 'features/dashboard/Team.actionTypes'

const Contact = () => {
    const user = useSelector(selectors.getUser)
    const dispatch = useDispatch()
    const team = useSelector(selectors.getTeam)

    useEffect(() => {
        dispatch(getTeams())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (data, action) => {
        const message = `Subject: ${data.subject}
      Priority: ${data.priority}
      Organisation: ${data.organization}
      Team : ${data.team}
      Ticket type: ${data.ticketType}
      
      Message :  
      
      ${data.message}`

        emailjs
            .send(
                'service_s3eskod',
                'template_5a3g3pi',
                {
                    reply_to: user.profile.email,
                    from_name: user.profile.first_name + ' ' + user.profile.last_name,
                    to_name: 'Support Page',
                    message: message,
                },
                '7bwzaSsAzO3ErgaXk'
            )
            .then(
                () => {
                    action.setSubmitting(false)
                    action.setStatus('sent')
                    action.resetForm()
                },
                () => {
                    action.setSubmitting(false)
                    action.setStatus('error')
                }
            )
    }
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center my-5">
                <span className="text-meili">Get in touch</span>
                <h1>Contact us</h1>
                <span className="w-50">
                    <strong> Note: </strong> Our documentation will occasionally be updated with new
                    information, and might have the answer you’re looking for. If you cannot find
                    the answer, please feel free to contact us below.
                </span>
            </div>
            <div className="w-50">
                <Formik
                    initialValues={{
                        priority: 'Medium',
                        team: '',
                        organization: team.details.organization?.title,
                        ticketType: '',
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ values, isSubmitting, setFieldValue, handleSubmit, resetForm }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleSubmit()
                                    resetForm()
                                }}
                            >
                                <FormGroup row>
                                    <Label for="subject" />
                                    Subject
                                    <Field
                                        id="subject"
                                        component={customInputForm}
                                        type="text"
                                        name="subject"
                                        placeholder="Add subject line"
                                        required
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '3rem',
                                        }}
                                        value={values.subject || ''}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="priority" />
                                    Priority
                                    <Field
                                        id="priority"
                                        component={customInputForm}
                                        type="select"
                                        name="priority"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '3rem',
                                        }}
                                        value={values.priority || 'Medium'}
                                    >
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                    </Field>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="organization" />
                                    Organisation
                                    <Field
                                        id="organization"
                                        component={customInputForm}
                                        type="string"
                                        name="organization"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '3rem',
                                        }}
                                        value={values.organization || ''}
                                    ></Field>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="team" />
                                    Team
                                    <Field
                                        id="team"
                                        component={customInputForm}
                                        type="select"
                                        name="team"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '3rem',
                                        }}
                                        value={values.team || ''}
                                        onChange={(e) => {
                                            const team = e.target.value
                                            if (!team) return
                                            setFieldValue('team', team)
                                        }}
                                    >
                                        <option value=""> -- Select team -- </option>
                                        {team.list?.map((team) => (
                                            <option key={team.uuid} value={team.slug}>
                                                {team.title}
                                            </option>
                                        ))}
                                    </Field>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="message" />
                                    Message
                                    <Field
                                        component={customInputForm}
                                        type="textarea"
                                        name="message"
                                        required
                                        placeholder="Let us know what we can help you with..."
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '15rem',
                                        }}
                                        value={values.message || ''}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="ticketType" />
                                    Type of ticket
                                    <Field
                                        id="ticketType"
                                        component={customInputForm}
                                        type="select"
                                        name="ticketType"
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '10px',
                                            padding: '8px',
                                            fontSize: '16px',
                                            height: '3rem',
                                        }}
                                        value={values.ticketType || ''}
                                    >
                                        <option value=""> -- Ticket type --</option>
                                        <option value="Bug reporting">Bug reporting</option>
                                        <option value="Issue">Issue</option>
                                        <option value="Administration">Administration</option>
                                        <option value="Other">Other</option>
                                    </Field>
                                </FormGroup>
                                <FormGroup row>
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        style={{
                                            width: '100%',
                                            borderRadius: '0.5rem',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        Send message
                                        {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                                    </Button>
                                </FormGroup>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default Contact
