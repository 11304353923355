import { selectors } from 'features/dashboard'
import { getReleaseNotes } from 'features/dashboard/Organization.actionTypes'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Loading } from 'ui/common'

const ReleaseNotes = () => {
    const dispatch = useDispatch()
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const status = useSelector(selectors.getOrg).status
    const release_notes = useSelector(selectors.getReleaseNotes)

    useEffect(() => {
        dispatch(getReleaseNotes(currentOrg?.uuid))
    }, [dispatch, currentOrg?.uuid])

    // Function to format the release date
    const formatReleaseDate = (dateString) => {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }).format(date)
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    if (status === 'loading') return <Loading />
    if (release_notes?.length === 0)
        return (
            <div className="d-flex flex-column justify-content-center align-items-center p-5">
                <h3>There is no release notes yet</h3>
            </div>
        )

    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-5 gap-5">
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                <span className="text-meili">Meili FMS</span>
                <h1>Release notes</h1>
            </div>
            <div style={{ maxWidth: '50%' }}>
                {release_notes?.map((note) => (
                    <div key={note.version} className="mb-3 d-flex flex-column">
                        <h3>Meili FMS {note.version}</h3>
                        <span
                            style={{
                                backgroundColor: '#F9EFFE',
                                border: '1px solid #F9EFFE',
                                borderRadius: '16px',
                                color: '#670D95',
                                width: 'fit-content',
                                padding: '2px 8px',
                                marginTop: '16px',
                                marginBottom: '32px',
                            }}
                        >
                            {formatReleaseDate(note.release_date)}
                        </span>
                        <div style={{ marginBottom: '16px' }}>
                            <span>What's new</span>
                            {!note.features || note.features.length === 0 ? (
                                <p>- No new features in this release.</p>
                            ) : (
                                <ul>
                                    {note.features.map((feature, i) => (
                                        <li key={i}>{capitalizeFirstLetter(feature)}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <span>Bug fixes</span>
                            {!note.bug_fixes || note.bug_fixes.length === 0 ? (
                                <p>- No bug fixes in this release.</p>
                            ) : (
                                <ul>
                                    {note.bug_fixes.map((fix, i) => (
                                        <li key={i}>{capitalizeFirstLetter(fix)}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ReleaseNotes
