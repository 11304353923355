import React, { forwardRef, useEffect, useState } from 'react'
import TaskDetailV2 from './TaskDetailV2'
import ScheduleTaskDetailV2 from './ScheduleTaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))

const AllTasksGridView = ({
    allTasks,
    upcoming,
    robot,
    slug,
    upcomingTask,
    setTaskHovered,
    currentLayout,
}) => {
    const [upcominglist, setUpcominglist] = useState(upcomingTask)

    let finallist = [...(allTasks?.all || [])]

    useEffect(() => {
        if (JSON.stringify(upcoming) !== JSON.stringify(upcominglist))
            setUpcominglist(
                upcoming?.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [upcoming]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="bg-white shadow-none cards-wrap rounded" data-testid="alltasks">
            <div
                tag={Wrapper}
                handle=".handle"
                list={finallist}
                finallist={(sortedList) => {
                    if (sortedList.length > 0) finallist(sortedList)
                }}
                style={{
                    overflowY: 'auto',
                    display:
                        currentLayout &&
                        (finallist.length > 0 || upcominglist?.length > 0) &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'grid'
                            : 'flex',
                    gridTemplateColumns:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 columns for grid layout
                            : 'none',
                    gridTemplateRows:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 rows for grid layout
                            : 'none',
                    justifyContent:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'flex-start'
                            : 'initial',
                    alignItems:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'center'
                            : 'initial',
                }}
            >
                {finallist?.length === 0 && upcominglist?.length === 0 && (
                    <h5 className="pl-3 py-3">There are currently no missions.</h5>
                )}
                {finallist.map((task) => (
                    <div key={task.uuid} className="mx-3">
                        {
                            <TaskDetailV2
                                task={task}
                                robot={robot}
                                slug={slug}
                                setTaskHovered={setTaskHovered}
                            />
                        }
                    </div>
                ))}
                {upcominglist?.map((task) => (
                    <div key={task.uuid} className="mx-3">
                        {
                            <ScheduleTaskDetailV2
                                task={task}
                                slug={slug}
                                setTaskHovered={setTaskHovered}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllTasksGridView
