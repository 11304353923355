import { actionTypes } from './RosSetup.actionTypes'

export const rosSetupState = {
    status: 'loading',
    details: {},
    setups: {},
    device_models: [],
    device_models_select: [],
    devices: [],
}

export const rosSetupReducer = (state = rosSetupState, action) => {
    switch (action.type) {
        // Loading Actions
        case actionTypes.CREATE_SETUP:
        case actionTypes.CREATE_ORG_SETUP:
        case actionTypes.UPDATE_SETUP:
        case actionTypes.CREATE_DEVICE_MODEL:
        case actionTypes.CREATE_ORG_DEVICE_MODEL:
        case actionTypes.UPDATE_DEVICE_MODEL:
        case actionTypes.DELETE_DEVICE_MODEL:
        case actionTypes.CREATE_DEVICES:
        case actionTypes.UPDATE_DEVICES:
        case actionTypes.DELETE_DEVICES:
        case actionTypes.RESET_DEVICES:
        case actionTypes.REMOVE_SETUP:
        case actionTypes.UPDATE_CHANNEL:
        case actionTypes.GET_CHANNEL:
        case actionTypes.GET_SETUP_LIST:
        case actionTypes.GET_ORG_SETUP_LIST:
        case actionTypes.GET_ORG_DEVICE_MODELS:
            return { ...state, status: 'loading' }

        // Error Actions
        case actionTypes.GET_SETUP_FAIL:
        case actionTypes.CREATE_DEVICE_MODEL_FAIL:
        case actionTypes.CREATE_ORG_DEVICE_MODEL_FAIL:
        case actionTypes.GET_DEVICES_FAIL:
        case actionTypes.GET_DEVICE_MODELS_FAIL:
        case actionTypes.GET_ORG_DEVICE_MODELS_FAIL:
        case actionTypes.GET_ORG_DEVICE_MODELS_SELECT_FAIL:
        case actionTypes.UPDATE_DEVICE_MODEL_FAIL:
        case actionTypes.DELETE_DEVICE_MODEL_FAIL:
        case actionTypes.CREATE_DEVICES_FAIL:
        case actionTypes.UPDATE_DEVICES_FAIL:
        case actionTypes.DELETE_DEVICES_FAIL:
        case actionTypes.UPDATE_CHANNEL_FAIL:
        case actionTypes.UPDATE_SETUP_FAIL:
        case actionTypes.GET_CHANNEL_FAIL:
            return { ...state, status: 'error' }

        // Success Actions
        case actionTypes.GET_SETUP_SUCCESS:
            return { ...state, status: 'loaded', details: action.payload?.data }
        case actionTypes.REMOVE_SETUP_SUCCESS:
            return {
                ...state,
                status: 'deleted',
                setups: {
                    ...state.setups,
                    results: state.setups?.results.filter(
                        (item) => item.uuid !== action.payload.data.uuid
                    ),
                },
            }
        case actionTypes.CREATE_SETUP_SUCCESS:
        case actionTypes.CREATE_ORG_SETUP_SUCCESS:
            return {
                ...state,
                status: 'created',
                setups: {
                    ...state.setups,
                    results: [...state.setups.results, action.payload.data],
                },
            }
        case actionTypes.UPDATE_SETUP_SUCCESS: {
            const updatedSetup = action.payload?.data
            const updatedSetupsArray = state.setups.results?.map((setup) =>
                setup.uuid === updatedSetup.uuid ? updatedSetup : setup
            )
            return {
                ...state,
                setups: { ...state.setups, results: updatedSetupsArray },
                status: 'updated',
            }
        }
        case actionTypes.GET_SETUP_LIST_SUCCESS:
        case actionTypes.GET_ORG_SETUP_LIST_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                setups: !action.payload.data.previous
                    ? action.payload.data
                    : {
                          ...action.payload.data,
                          results: [
                              ...state.setups.results,
                              ...action.payload.data.results.filter(
                                  (item) =>
                                      !state.setups.results.some(
                                          (existingItem) => existingItem.uuid === item.uuid
                                      )
                              ),
                          ],
                      },
            }
        case actionTypes.CREATE_DEVICE_MODEL_SUCCESS:
        case actionTypes.CREATE_ORG_DEVICE_MODEL_SUCCESS:
            return {
                ...state,
                status: 'created',
                device_models: {
                    ...state.device_models,
                    results: [...state.device_models?.results, action.payload?.data],
                },
            }
        case actionTypes.GET_DEVICE_MODELS_SUCCESS:
        case actionTypes.GET_ORG_DEVICE_MODELS_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                device_models: !action.payload.data.previous
                    ? action.payload.data
                    : {
                          ...action.payload.data,
                          results: [
                              ...state.device_models.results,
                              ...action.payload.data.results.filter(
                                  (item) =>
                                      !state.device_models.results.some(
                                          (existingItem) => existingItem.uuid === item.uuid
                                      )
                              ),
                          ],
                      },
            }
        case actionTypes.GET_ORG_DEVICE_MODELS_SELECT_SUCCESS:
            return { ...state, status: 'loaded', device_models_select: action.payload.data }
        case actionTypes.UPDATE_DEVICE_MODEL_SUCCESS: {
            const updatedDeviceModel = action.payload.data
            const updatedDeviceModelsArray = state.device_models.results.map((deviceModel) =>
                deviceModel.uuid === updatedDeviceModel.uuid ? updatedDeviceModel : deviceModel
            )
            return {
                ...state,
                device_models: { ...state.device_models, results: updatedDeviceModelsArray },
                status: 'updated',
            }
        }
        case actionTypes.DELETE_DEVICE_MODEL_SUCCESS: {
            const deletedDeviceModelId = action.payload.data.id
            return {
                ...state,
                status: 'deleted',
                device_models: state.device_models.results?.filter(
                    (deviceModel) => deviceModel.id !== deletedDeviceModelId
                ),
            }
        }
        case actionTypes.CREATE_DEVICES_SUCCESS: {
            return {
                ...state,
                status: 'created',
                devices: [...state.devices, action.payload?.data],
            }
        }
        case actionTypes.GET_DEVICES_SUCCESS: {
            return { ...state, status: 'loaded', devices: action.payload.data }
        }
        case actionTypes.UPDATE_DEVICES_SUCCESS: {
            const updatedDevice = action.payload.data
            const index = state.devices.findIndex((device) => device.uuid === updatedDevice.uuid)
            const updatedDevices = [...state.devices]
            updatedDevices[index] = updatedDevice
            return { ...state, devices: updatedDevices, status: 'loaded' }
        }
        case actionTypes.UPDATE_DEVICES_FROM_WEBHOOK: {
            const updatedDevicesFromWebhook = action.payload.data
            const updatedDevicesArray = [...state.devices]

            updatedDevicesFromWebhook.forEach((updatedDevice) => {
                const index = updatedDevicesArray.findIndex(
                    (device) => device.uuid === updatedDevice.uuid
                )
                if (index !== -1) {
                    updatedDevicesArray[index] = {
                        ...updatedDevicesArray[index],
                        ...updatedDevice,
                    }
                }
            })

            return { ...state, devices: updatedDevicesArray, status: 'loaded' }
        }

        case actionTypes.DELETE_DEVICES_SUCCESS: {
            return {
                ...state,
                status: 'deleted',
                devices: state.devices.filter((device) => device.uuid !== action.payload.data.uuid),
            }
        }
        case actionTypes.RESET_DEVICES_SUCCESS: {
            return { ...state, status: 'reset', devices: [] }
        }
        case actionTypes.RESET_DEVICE_MODELS: {
            return { ...state, status: 'reset', device_models: {} }
        }
        case actionTypes.RESET_SETUPS: {
            return { ...state, status: 'reset', setups: {} }
        }
        case actionTypes.UPDATE_CHANNEL_SUCCESS: {
            const updatedChannel = action.payload?.data
            const indexSetup = state.setups?.results?.findIndex(
                (setup) => setup.uuid === updatedChannel.setupuuid
            )
            const updatedList = [...state.setups?.results]
            updatedList[indexSetup] = { ...updatedList[indexSetup], channel: updatedChannel }
            return {
                ...state,
                status: 'updated',
                setups: { ...state.setups, results: updatedList },
            }
        }
        case actionTypes.GET_CHANNEL_SUCCESS: {
            const iSetup = state.setups.results.findIndex(
                (setup) => setup.uuid === action.payload.config.setupuuid
            )
            const updatedListChannel = [...state.setups.results]
            updatedListChannel[iSetup] = {
                ...updatedListChannel[iSetup],
                channel: action.payload.data,
            }
            return {
                ...state,
                status: 'loaded',
                setups: { ...state.setups, results: updatedListChannel },
            }
        }
        default:
            return state
    }
}
