import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { Button, Label, FormGroup, Table, Modal, ModalBody } from 'reactstrap'

import { customInputForm } from 'ui/common'
import { rossetupSchema } from 'schemas/dashboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
// import { useToggler } from 'utils'
import RosSetupVehicleForm from './RosSetupVehicleForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import { useToggler } from 'utils'
import DeviceModal from './RosSetupDeviceForm'
import { createOrgSetup, getOrgSetups } from 'features/dashboard/RosSetup.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'

const RosSetupAddModal = ({ slug, modal, toggle, currentPageNumber, filters }) => {
    const dispatch = useDispatch()
    const [modalSmall, toggleSmall] = useToggler()
    const vehicles = useSelector(selectors.getVehicles)
    const teams = useSelector(selectors.getOrganizationTeams)
    const { device_models } = useSelector(selectors.getSetup)
    const [currentVehicle, setCurrentVehicle] = useState({})
    const [currentDevice, setCurrentDevice] = useState({})
    const [scrollPosition, setScrollPosition] = useState('top')
    const [team, setTeam] = useState('')
    const [communicationProtocol, setCommunicationProtocol] = useState('')
    const [communicationFormat, setCommunicationFormat] = useState('')
    const [type, setType] = useState('')
    const communicationOptions = ['raw', 'yaml', 'json', 'xml']
    const communication_protocols = ['http', 'mqtt', 'websocket', 'opc_ua']

    useEffect(() => {
        if (!modalSmall) {
            setCurrentVehicle({})
            setCurrentDevice({})
        }
    }, [modalSmall])

    const handleSubmit = (values) => {
        let data
        let { type, vehicles, device_models, ...rest } = values
        if (type === 'device') {
            data = {
                ...rest,
                device_model: device_models[0].uuid,
            }
        }
        if (type === 'vehicle') {
            data = {
                ...rest,
                vehicles,
            }
        }
        dispatch(createOrgSetup({ data, slug })).then(({ error }) => {
            if (!error) {
                dispatch(getOrgSetups({ slug, page: currentPageNumber, filters }))
                toggle()
            }
        })
    }

    const vehicleObject = useMemo(
        () =>
            vehicles?.teamVehicle.reduce((acc, vehicle) => {
                acc[vehicle.uuid] = vehicle
                return acc
            }, {}),
        [vehicles]
    )

    const renderArrow = () => {
        if (scrollPosition === 'bottom') {
            return (
                <img
                    src="/svgs/user-icon/arrow.svg"
                    alt="arrow"
                    className="rotated-up-arrow-modals"
                />
            )
        } else if (scrollPosition === 'top') {
            return (
                <img
                    src="/svgs/user-icon/arrow.svg"
                    alt="arrow"
                    className="rotated-down-arrow-modals"
                />
            )
        } else {
            return (
                <div className="d-flex">
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-up-arrow-modals"
                    />
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        alt="arrow"
                        className="rotated-down-arrow-modals"
                    />
                </div>
            )
        }
    }

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 5 <= e.target.clientHeight
        const top = e.target.scrollTop === 0

        if (bottom) {
            setScrollPosition('bottom')
        } else if (top) {
            setScrollPosition('top')
        } else {
            setScrollPosition('middle')
        }
    }

    const VehicleList = ({ list }) => {
        if (list.length === 0)
            return <p className="py-4">This fleet setup does not have any vehicles yet.</p>
        return (
            <div className="big-org-container" style={{ padding: '0', marginTop: '32px' }}>
                <Table bordered className="my-custom-table border-0">
                    <thead>
                        <tr>
                            <th className="react-table-header">Vehicle name</th>
                            <th className="react-table-header">Prefix</th>
                            <th className="react-table-header">Overrides</th>
                            <th className="react-table-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((vehicle, index) => (
                            <tr key={index}>
                                <td className="react-table-style">
                                    {vehicleObject[vehicle.uuid]?.verbose_name}
                                </td>
                                <td className="react-table-style">{vehicle.prefix || '-'}</td>
                                <td className="react-table-style">
                                    {vehicle.topic_overrides
                                        .map((topic) => topic.label)
                                        .join(', ') || '-'}
                                </td>
                                <td className="text-center react-table-style">
                                    <img
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        src="/svgs/trigger-actions/edit.svg"
                                        alt="edit"
                                        onClick={() => {
                                            setCurrentVehicle({ vehicle, index })
                                            toggleSmall()
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    const DeviceModelsList = ({ list }) => {
        if (list.length === 0)
            return <p className="py-4">This fleet setup does not have any device models yet.</p>
        return (
            <div className="big-org-container" style={{ padding: '0', marginTop: '32px' }}>
                
                <Table className="my-custom-table">
                    <thead>
                        <tr>
                            <th className="custom-header">Device name</th>
                            <th className="custom-header">Model type</th>
                            <th className="custom-header"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((device, index) => (
                            <tr key={index}>
                                <td className="custom-cell">{device.name}</td>
                                <td className="custom-cell">{device.device_model_type}</td>
                                <td className="text-center custom-cell">
                                    <img
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        src="/svgs/trigger-actions/edit.svg"
                                        alt="edit"
                                        onClick={() => {
                                            setCurrentDevice({ device, index })
                                            toggleSmall()
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: team === '' ? 'hidden' : 'auto' }}
                onScroll={handleScroll}
            >
                <div style={{ marginBottom: '32px' }}>
                    <div
                        className="w-100 d-flex justify-content-between"
                        style={{ marginBottom: '24px' }}
                    >
                        <img
                            src="/svgs/task-modal/smiley-robot.svg"
                            alt="obstacle-icon"
                            width="48px"
                            height="48px"
                        />
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                            onClick={toggle}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <span className="org-modal-header">New fleet setup</span>
                    </div>
                </div>
                <Formik
                    initialValues={{ vehicles: [], device_models: [], type: '' }}
                    validationSchema={rossetupSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <FormGroup>
                                    <Label className="modals-labels" for="verbose_name">
                                        {' '}
                                        Name *
                                    </Label>
                                    <Field
                                        className="modals-inputs"
                                        component={customInputForm}
                                        type="text"
                                        required
                                        value={values.verbose_name || ''}
                                        name="verbose_name"
                                        placeholder="Name"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" for="communication_protocol">
                                        Team *
                                    </Label>
                                    <DropdownSelect
                                        selected={team}
                                        setSelected={setTeam}
                                        options={teams.map((team) => team)}
                                        setFieldValue={setFieldValue}
                                        fieldValue="team"
                                    />
                                    {/* <Field
                                        className="modals-inputs"
                                        component={customInputForm}
                                        required
                                        type="select"
                                        value={values.team || ''}
                                        name="team"
                                    >
                                        <option value=""></option>
                                        {teams.map((team) => (
                                            <option key={team.uuid} value={team.uuid}>
                                                {team.title}
                                            </option>
                                        ))}
                                    </Field> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" for="communication_protocol">
                                        Communication protocol *
                                    </Label>
                                    <DropdownSelect
                                        selected={communicationProtocol}
                                        setSelected={setCommunicationProtocol}
                                        options={communication_protocols.map((protocol) => {
                                            return { uuid: protocol, name: protocol }
                                        })}
                                        setFieldValue={setFieldValue}
                                        fieldValue="communication_protocol"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" for="communication_format">
                                        Communication format *
                                    </Label>
                                    <DropdownSelect
                                        selected={communicationFormat}
                                        setSelected={setCommunicationFormat}
                                        options={communicationOptions.map((option) => {
                                            return { uuid: option, name: option }
                                        })}
                                        setFieldValue={setFieldValue}
                                        fieldValue="communication_format"
                                    />
                                </FormGroup>
                                {values.team && (
                                    <FormGroup>
                                        <Label className="modals-labels" for="type">
                                            Setup type *
                                        </Label>
                                        <DropdownSelect
                                            selected={type}
                                            setSelected={setType}
                                            options={[
                                                { uuid: 'vehicle', name: 'Vehicle' },
                                                { uuid: 'device', name: 'Device model' },
                                            ]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="type"
                                        />
                                    </FormGroup>
                                )}
                                {values.type === 'vehicle' && (
                                    <div className="my-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>Vehicles</span>
                                            <Button className="add-new-temp" onClick={toggleSmall}>
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{ marginRight: '8px' }}
                                                />
                                                Add new
                                            </Button>
                                        </div>
                                        <VehicleList list={values.vehicles} />
                                        <RosSetupVehicleForm
                                            slug={slug}
                                            modal={modalSmall}
                                            toggle={toggleSmall}
                                            vehicles={vehicles}
                                            team={values.team}
                                            initialValues={currentVehicle.vehicle}
                                            handleAdd={(data) => {
                                                setFieldValue('vehicles', [
                                                    ...values.vehicles,
                                                    data,
                                                ])
                                                toggleSmall()
                                            }}
                                            handleRemove={(uuid) => {
                                                setFieldValue(
                                                    'vehicles',
                                                    values.vehicles.filter((v) => v.uuid !== uuid)
                                                )
                                                toggleSmall()
                                            }}
                                            handleUpdate={(data) => {
                                                const updatedVehicles = [...values.vehicles]
                                                const index = updatedVehicles.findIndex(
                                                    (vehicle) =>
                                                        vehicle.uuid === currentVehicle.vehicle.uuid
                                                )
                                                if (index === -1) return
                                                updatedVehicles[index] = data
                                                setFieldValue('vehicles', updatedVehicles)
                                                toggleSmall()
                                            }}
                                        />
                                    </div>
                                )}
                                {values.type === 'device' && (
                                    <div className="my-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>Device models</span>
                                            <Button
                                                className="add-new-temp"
                                                onClick={toggleSmall}
                                                disabled={values.device_models.length === 1}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{ marginRight: '8px' }}
                                                />
                                                Add new
                                            </Button>
                                        </div>
                                        <DeviceModelsList list={values.device_models} />
                                        <DeviceModal
                                            modal={modalSmall}
                                            toggle={toggleSmall}
                                            slug={slug}
                                            team={values.team}
                                            device_models={device_models}
                                            setFieldValueTop={setFieldValue}
                                            initialValues={currentDevice.device}
                                            deviceModelsList={values.device_models}
                                        />
                                    </div>
                                )}
                                <div className="new-modal-footer">
                                    {renderArrow(values.type)}
                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={() => {
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default RosSetupAddModal
