import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik } from 'formik'
import {
    Button,
    ButtonGroup,
    Input,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    UncontrolledTooltip,
} from 'reactstrap'
import { customInputForm } from 'ui/common'
import { getUpdatedPathPlanning, updatePathPlanning } from 'features/dashboard/Team.actionTypes'
import { BsInfoCircle } from 'react-icons/bs'
import { selectors } from 'features/dashboard'

const TeamSiteSettings = ({ slug, modal, toggle }) => {
    const dispatch = useDispatch()
    const pathOptions = useSelector(selectors.getTeamPathPlanning)
    const [tab, setTab] = useState('mission')
    const [step, setStep] = useState(0.01)

    const handleSubmit = (data) => {
        dispatch(updatePathPlanning({ slug, data })).then(({ error }) => {
            dispatch(getUpdatedPathPlanning({ slug }))
            toggle()
        })
    }

    const handleOnWaypointsChange = (e, values, setValues, setFieldValue) => {
        const newValue = Number(e.target.value)
        const currentValue = values.distance_between_waypoints

        if (newValue >= 0 && currentValue < 0) {
            // Switch to decimal step when crossing from negative to non-negative
            setStep(0.01)
        } else if (newValue < 0 && currentValue >= 0) {
            // Switch to integer step when crossing from non-negative to negative
            setStep(1)
        }

        // Ensure the minimum value is -1 immediately when going below -1
        let formattedValue

        if (newValue < -1) {
            formattedValue = -1
        } else if (newValue === -0.01) {
            // Handle the case where the value is briefly -0.01 or -0.99
            formattedValue = -1
        } else if (newValue === -0.99) {
            formattedValue = 0
        } else {
            formattedValue = newValue
        }

        setValues({
            ...values,
            distance_between_waypoints: formattedValue,
        })
        setFieldValue('distance_between_waypoints', formattedValue, 'distance_between_waypoints')
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg" className="vehicle-modal-org">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'auto' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/organization/members/site-icon.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">Site settings</span>
                    <div className="modals-container-buttons">
                        <ButtonGroup className="org-button-group">
                            <Button
                                className={`${
                                    tab === 'mission' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('mission')
                                }}
                            >
                                Mission management
                            </Button>
                            <Button
                                className={`${
                                    tab === 'path' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('path')
                                }}
                            >
                                Path planning
                            </Button>
                            <Button
                                className={`${
                                    tab === 'traffic' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('traffic')
                                }}
                            >
                                Traffic control
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Formik initialValues={pathOptions} onSubmit={handleSubmit}>
                    {({ values, setFieldValue, handleSubmit, setValues }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            {tab === 'mission' && (
                                <>
                                    {' '}
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="alerttime">
                                                Robot disconnect alert time (sec)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l ml-2 info-icon rounded-circle"
                                                id="alert-time"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="alerttime"
                                            type="number"
                                            min="0"
                                            placeholder="Robot disconnect Alert Time"
                                            name="task_alert_time"
                                        />
                                        <UncontrolledTooltip placement="auto" target="alert-time">
                                            The time (in seconds) until the user is notified about a
                                            robot disconnecting when the robot has an active task.
                                            The task will also be terminated after this time
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="timeout">
                                                Mission timeout (sec)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l ml-2 info-icon rounded-circle"
                                                id="timeout"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="timeout"
                                            type="number"
                                            min="0"
                                            placeholder="Mission timeout"
                                            name="task_timeout"
                                        />
                                        <UncontrolledTooltip placement="auto" target="timeout">
                                            The time (in seconds) a task has been inactive before
                                            the task is terminated. The time is determined by when
                                            the most recent subtask was created or started
                                            execution, whichever is more recent.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="resting">
                                                Time to resting zone after mission (sec)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2  rounded-circle"
                                                id="rest-zone"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="resting"
                                            type="number"
                                            min="-1"
                                            placeholder="Time to resting zone after mission"
                                            name="after_finished_task_wait_time"
                                        />
                                        <small>
                                            Note: if the value is set up to -1,the system won't send
                                            robots to resting zones.
                                        </small>
                                        <UncontrolledTooltip placement="auto" target="rest-zone">
                                            The time a robot will wait after completing its task,
                                            before it will be sent to a resting zone
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="safety">
                                                On station allowed deviation (mm)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2  rounded-circle"
                                                id="deviations"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="deviation"
                                            min="0"
                                            type="number"
                                            placeholder="On station allowed deviation"
                                            name="on_station_allowed_deviation"
                                        />
                                        <UncontrolledTooltip placement="auto" target="deviations">
                                            The maximum distance between the robot and the station
                                            where the robot will be considered to be on that
                                            station.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label
                                                className="modals-labels"
                                                for="vehicle_cache_ttl"
                                            >
                                                Vehicle cache TTL (sec)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="vehicle_cache_ttl"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="number"
                                            min="0"
                                            placeholder="Vehicle cache TTL"
                                            name="vehicle_cache_ttl"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="vehicle_cache_ttl"
                                        >
                                            The amount of seconds the vehicle details will be cached
                                            in the system (location, speed, battery).
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup check className="mt-4 ml-2">
                                        <Input
                                            type="checkbox"
                                            id="move_to_rest_zone_when_charged"
                                            checked={values.move_to_rest_zone_when_charged}
                                            name="move_to_rest_zone_when_charged"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'move_to_rest_zone_when_charged',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label check> Move to resting zone when charge </Label>

                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="charged"
                                        />
                                        <UncontrolledTooltip placement="auto" target="charged">
                                            If set to true, robots will move to a resting zone when
                                            they are charged. Otherwise, they will stay in a
                                            charging zone.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup check className="mt-4 ml-2">
                                        <div>
                                            <Input
                                                type="checkbox"
                                                label="Move to charging zone after timeout"
                                                id="move_to_charging_zone_after_timeout"
                                                checked={values.move_to_charging_zone_after_timeout}
                                                name="move_to_charging_zone_after_timeout"
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'move_to_charging_zone_after_timeout',
                                                        e.target.checked
                                                    )
                                                }}
                                            />
                                            <Label check>
                                                Move to charging zone after timeout{' '}
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                                id="after_timeout"
                                            />
                                        </div>
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="after_timeout"
                                        >
                                            If set to true, robots will move to a charging zone
                                            after timeout. Otherwise, they will move to a resting
                                            zone.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </>
                            )}

                            {tab === 'path' && (
                                <>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="distance">
                                                Distance between waypoints (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2  rounded-circle"
                                                id="waypoint-distance"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="distance"
                                            type="number"
                                            min="-1"
                                            step={step}
                                            placeholder="Distance between waypoints"
                                            value={values.distance_between_waypoints}
                                            onChange={(e) =>
                                                handleOnWaypointsChange(
                                                    e,
                                                    values,
                                                    setValues,
                                                    setFieldValue
                                                )
                                            }
                                            name="distance_between_waypoints"
                                        />
                                        <small>
                                            Note: if the value is set up to -1,the system will use a
                                            minimal number of waypoints (only corner points) to
                                            define the route.
                                        </small>
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="waypoint-distance"
                                        >
                                            Determines the distance between waypoints in path in
                                            meters.
                                        </UncontrolledTooltip>
                                    </FormGroup>

                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label
                                                className="modals-labels"
                                                for="common_path_multiplier"
                                            >
                                                Path cost multiplier
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="common_path_multiplier"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            type="number"
                                            min="0"
                                            max="10"
                                            step={0.1}
                                            name="common_path_multiplier"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="common_path_multiplier"
                                        >
                                            When the value is greater than 0, it will raise the cost
                                            (or prevent when the value reaches 10) associated with
                                            sending a mission that includes a path already being
                                            utilized by an ongoing mission.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup check className="mt-4 ml-2">
                                        <Input
                                            type="checkbox"
                                            id="disable_station_locking"
                                            checked={values.disable_station_locking}
                                            name="disable_station_locking"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'disable_station_locking',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label check> Disable station locking </Label>
                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="station-lockingU"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="station-lockingU"
                                        >
                                            Enables to send simultaneously more than one task to the
                                            same station
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="mt-4 ml-2" check>
                                        <Input
                                            type="checkbox"
                                            label="Disable automatic path planning"
                                            id="disable_automatic_path_planning"
                                            checked={values.disable_automatic_path_planning}
                                            name="disable_automatic_path_planning"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'disable_automatic_path_planning',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label check> Disable automatic path planning</Label>
                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="disable_automatic_path_planningU"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="disable_automatic_path_planningU"
                                        >
                                            Disabling this option means that the Meili path planner
                                            will not be employed for path creation. As a result,
                                            automatic obstacle avoidance will not be enabled.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="mt-4 ml-2" check>
                                        <Input
                                            type="checkbox"
                                            label="Disable zone obstacle validation"
                                            id="disable_zone_obstacle_validation"
                                            checked={values.disable_zone_obstacle_validation}
                                            name="disable_zone_obstacle_validation"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'disable_zone_obstacle_validation',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label>Disable zone obstacle validation </Label>
                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="disable_zone_obstacle_validationU"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="disable_zone_obstacle_validationU"
                                        >
                                            Disables the obstacles check for all types of zones.
                                            WARNING: if set true, zones can be on top of obstacles.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </>
                            )}

                            {tab === 'traffic' && (
                                <>
                                    <FormGroup className="mt-4 ml-3" check>
                                        <Input
                                            type="checkbox"
                                            label="Disable zone obstacle validation"
                                            id="enable_traffic_control"
                                            checked={values.enable_traffic_control}
                                            name="enable_traffic_control"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'enable_traffic_control',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label>Enable Traffic control</Label>
                                        <BsInfoCircle
                                            className="font-size-l ml-2 info-icon mr-0 rounded-circle"
                                            id="enable_traffic_controlU"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="enable_traffic_controlU"
                                        >
                                            Enable/Disable traffic control
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="collision">
                                                Stationary collision detection threshold (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="collision-detect"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="collision"
                                            type="number"
                                            disabled={!values.enable_traffic_control}
                                            min="0"
                                            step={0.01}
                                            placeholder="Stationary collision detection threshold"
                                            name="stationary_collision_detection_threshold"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="collision-detect"
                                        >
                                            Determines the distance to find collision between the
                                            robots.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="safety">
                                                Safety distance threshold (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="safty-dist"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="safety"
                                            type="number"
                                            disabled={!values.enable_traffic_control}
                                            min="0"
                                            step={0.001}
                                            placeholder="Safety distance threshold"
                                            name="safety_distance_threshold_in_m"
                                        />
                                        <UncontrolledTooltip placement="right" target="safty-dist">
                                            Determines the Safety Distance between obstacles and
                                            vehicles.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="stayOnDistance">
                                                Stay On Collision Safety Distance (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="stay-on-distance-info"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="stayOnDistance"
                                            disabled={!values.enable_traffic_control}
                                            type="number"
                                            min="0"
                                            step={0.01}
                                            placeholder="Stay On Collision Safety Distance"
                                            name="traffic_control_stay_on_distance"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="stay-on-distance-info"
                                        >
                                            Determines the safety distance for stay on collisions in
                                            meters.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label
                                                className="modals-labels"
                                                for="crossConflictDistance"
                                            >
                                                Cross Conflict Collision Safety Distance (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="cross-conflict-distance-info"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="crossConflictDistance"
                                            disabled={!values.enable_traffic_control}
                                            type="number"
                                            min="0"
                                            step={0.01}
                                            placeholder="Cross Conflict Collision Safety Distance"
                                            name="traffic_control_cross_conflict_distance"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="cross-conflict-distance-info"
                                        >
                                            Determines the safety distance for cross conflict
                                            collisions in meters.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="headOnDistance">
                                                Head On Collision Safety Distance (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="head-on-distance-info"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            id="headOnDistance"
                                            disabled={!values.enable_traffic_control}
                                            type="number"
                                            min="0"
                                            step={0.01}
                                            placeholder="Head On Collision Safety Distance"
                                            name="traffic_control_head_on_distance"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="head-on-distance-info"
                                        >
                                            Determines the safety distance for head on collisions in
                                            meters.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                    <FormGroup className="d-flex flex-column mt-3">
                                        <div className="d-flex justify-content-start">
                                            <Label className="modals-labels" for="agvDistance">
                                                AGV Collision Safety Distance (m)
                                            </Label>
                                            <BsInfoCircle
                                                className="font-size-l info-icon mr-0 ml-2 rounded-circle"
                                                id="agv-distance-info"
                                            />
                                        </div>
                                        <Field
                                            className="modals-inputs"
                                            component={customInputForm}
                                            disabled={!values.enable_traffic_control}
                                            id="agvDistance"
                                            type="number"
                                            min="0"
                                            step={0.01}
                                            placeholder="AGV Collision Safety Distance"
                                            name="traffic_control_agv_distance"
                                        />
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="agv-distance-info"
                                        >
                                            Determines the safety distance for agv collisions in
                                            meters.
                                        </UncontrolledTooltip>
                                    </FormGroup>
                                </>
                            )}
                            <div className="new-modal-footer">
                                <div></div>
                                <div className="d-flex">
                                    <Button
                                        className="cancel-btn-modal modals-new-btns"
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{ marginLeft: '12px' }}
                                        type="submit"
                                        disabled={values === pathOptions}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default TeamSiteSettings
