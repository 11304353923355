import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap'
import { TITLE } from 'config'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import { AgGridReact } from 'ag-grid-react'
import {
    getOrgTeams,
    getOrgMembers,
    removeOrgMember,
} from 'features/dashboard/Organization.actionTypes'
import ManageOrganizationModals from './ManageOrganizationModals'
import { selectors } from 'features/dashboard'
import dayjs from 'dayjs'
import { teamsColours, validateDate } from './OrgTeamHelpers'
import { deleteTeam } from 'features/dashboard/Team.actionTypes'
// import Filter from 'layout-components/Filter'

const OrganizationManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const members = useSelector(selectors.getOrgMembers)
    const adminOrgs = useSelector(selectors.getAdminOrgs)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [form, setForm] = useState(null)
    const [modal, setModal] = useState(false)
    const [dataOnClick, setDataOnClick] = useState({})
    const [activeTab, setActiveTab] = useState(localStorage.getItem('org-manage-tab') || 'members')
    const [btnText, setButtonText] = useState('Add member')
    // const [dropdownOpen, setDropdownOpen] = useState(false)
    // const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)
    const [api, setApi]= useState('')

    const [searchText, setSearchText] = useState('')

    // References to each grid's API
    const [gridApi1, setGridApi1] = useState(null)
    const [gridApi2, setGridApi2] = useState(null)
    const gridRef = useRef()
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setApi(params.api)
    }

    const onGridReady1 = useCallback((params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setGridApi1(params.api)
    }, [])

    const onGridReady2 = useCallback((params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setGridApi2(params.api)
    }, [])

    useEffect(() => {
        dispatch(getOrgTeams(currentOrg.slug, true))
        dispatch(getOrgMembers(currentOrg.slug))
    }, [currentOrg.slug, dispatch])

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        // Trigger filtering for both grids
        gridApi1 && gridApi1.onFilterChanged()
        gridApi2 && gridApi2.onFilterChanged()
        api && api.onFilterChanged()
    }

    useEffect(() => {
        if (activeTab === 'members') {
            document.title = `${TITLE} - Members manage`
            setButtonText('Add member')
        }
        if (activeTab === 'teams') {
            document.title = `${TITLE} - Teams manage`
            setButtonText('Add team')
        }
    }, [activeTab, TITLE]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveFromOrg = ({ uuid }) => {
        if (activeTab === 'members') {
            dispatch(removeOrgMember({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgMembers(currentOrg.slug))
                    dispatch(getOrgTeams(currentOrg.slug, true))
                }
                toggle()
            })
        }

        if (activeTab === 'teams') {
            dispatch(deleteTeam({ uuid })).then(({ error }) => {
                if (!error) {
                    dispatch(getOrgTeams(currentOrg.slug, true))
                    dispatch(getOrgMembers(currentOrg.slug))
                }
                toggle()
            })
        }
    }

    const RenderOrgActions = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )
    const isExternalFilterPresent = () => searchText !== ''

    // Filtering logic for Table 1
    const doesExternalFilterPassForTable1 = (node) => {
        const { name, teams } = node.data
        return name.toLowerCase().includes(searchText) || teams.toString().includes(searchText)
    }

    const defaultColDef = {
        cellClass: 'custom-cell', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
        valueFormatter: (params) => {
            return params.value
        },
    }

    const adminsList = useMemo(() => {
        return members
            .filter(
                (member) => member.membership_type === 'owner' || member.membership_type === 'admin'
            )
            .map(({ user, membership_type, created_at, uuid }) => ({
                uuid: user.uuid,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                type: <div className="team-div-cell">{membership_type}</div>,
                created_at: <div className="text-dark">{validateDate(dayjs(created_at))}</div>,
                teams: (
                    <div className="teams-grid">
                        {teams.filter((team) =>
                            team?.memberships?.some((member) => member.user.uuid === user.uuid)
                        ).length > 0 ? (
                            teams
                                .filter((team) =>
                                    team?.memberships?.some(
                                        (member) => member.user.uuid === user.uuid
                                    )
                                )
                                .map((team, index) => {
                                    const colorIndex = index % teamsColours.length // Cycles through 0 to 3
                                    const { bg, text } = teamsColours[colorIndex]
                                    return (
                                        <div
                                            key={team.uuid}
                                            className="team-div-cell"
                                            style={{
                                                backgroundColor: bg,
                                                color: text,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/dashboard/organization/teams/${team.slug}/`,
                                                    state: { slug: team.slug, team: team },
                                                })
                                            }
                                        >
                                            {team.title}
                                        </div>
                                    )
                                })
                        ) : (
                            <div className="team-div-cell">No teams found</div> // or <div className="no-teams">/</div> for a slash
                        )}
                    </div>
                ),
                action: (
                    <RenderOrgActions
                        item={{ user, uuid, membership_type }}
                        form={{ edit: 'edit-member', remove: 'remove-member' }}
                    />
                ),
            }))
    }, [members, teams]) // eslint-disable-line react-hooks/exhaustive-deps

    const membersList = useMemo(() => {
        return members
            .filter(
                (member) => member.membership_type !== 'owner' && member.membership_type !== 'admin'
            )
            .map(({ user, membership_type, created_at, uuid }) => ({
                uuid: user.uuid,
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                type: <div className="team-div-cell">{membership_type}</div>,
                created_at: <div className="text-dark">{validateDate(dayjs(created_at))}</div>,
                teams: (
                    <div className="teams-grid">
                        {teams.filter((team) =>
                            team?.memberships?.some((member) => member.user.uuid === user.uuid)
                        ).length > 0 ? (
                            teams
                                .filter((team) =>
                                    team?.memberships?.some(
                                        (member) => member.user.uuid === user.uuid
                                    )
                                )
                                .map((team, index) => {
                                    const colorIndex = index % teamsColours.length // Cycles through 0 to 3
                                    const { bg, text } = teamsColours[colorIndex]
                                    return (
                                        <div
                                            key={team.uuid}
                                            className="team-div-cell"
                                            style={{
                                                backgroundColor: bg,
                                                color: text,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/dashboard/organization/teams/${team.slug}/`,
                                                    state: { slug: team.slug, team: team },
                                                })
                                            }
                                        >
                                            {team.title}
                                        </div>
                                    )
                                })
                        ) : (
                            <div className="team-div-cell">No teams found</div> // or <div className="no-teams">/</div> for a slash
                        )}
                    </div>
                ),
                action: (
                    <RenderOrgActions
                        item={{ user, uuid, membership_type }}
                        form={{ edit: 'edit-member', remove: 'remove-member' }}
                    />
                ),
            }))
    }, [members, teams]) // eslint-disable-line react-hooks/exhaustive-deps

    const membersColumns = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: true,
            unSortIcon: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return (
                    <div className="d-flex flex-column justify-centent-center">
                        <span>{params.data.name}</span>
                        <span style={{ fontWeight: '200' }}>{params.data.email}</span>
                    </div>
                )
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            cellRenderer: (params) => {
                return <div>{params.data.type}</div>
            },
            valueFormatter: (params) => {
                return params.value
            },
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            cellRenderer: (params) => {
                return <div>{params.data.created_at.props.children}</div>
            },
            valueFormatter: (params) => {
                return params.value
            },
        },
        {
            field: 'teams',
            headerName: 'Teams',
            cellRenderer: (params) => {
                return <div>{params.data.teams}</div>
            },
            valueFormatter: (params) => {
                return params.value
            },
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            cellStyle: { marginTop: '15px' },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
            valueFormatter: (params) => {
                return params.value
            },
        },
    ]

    const teamsList = useMemo(() => {
        return teams.map((team) => ({
            uuid: team.uuid,
            team,
            title: team.title || '-',
            owners: team?.memberships
                ?.filter((member) => member.membership_type === 'owner')
                .map(
                    ({ user }) =>
                        `${user?.first_name || '-'} ${user?.last_name || ''}` || user?.email
                )
                .join(', '),
            team_members: (
                <div className="teams-grid">
                    {team?.memberships?.map(({ user }) => (
                        <div key={user.uuid} className="team-div-cell">
                            {user.first_name && user.last_name
                                ? `${user.first_name} ${user.last_name}`
                                : user.email}
                        </div>
                    ))}
                </div>
            ),
            action: (
                <RenderOrgActions item={team} form={{ edit: 'edit-team', remove: 'remove-team' }} />
            ),
        }))
    }, [teams])

    const teamsColumns = [
        {
            field: 'title',
            headerName: 'Title',
            sortable: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${params.data.team.slug}/`,
                                state: { slug: params.data.team.slug, team: params.data.team },
                            })
                        }
                    >
                        {params.data.team.title}
                    </div>
                )
            },
        },
        {
            field: 'owners',
            headerName: 'Owners',
            cellStyle: { marginTop: '25px' },
        },
        {
            field: 'team_members',
            headerName: 'Team members',
            cellStyle: { display: 'inline' },
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.team_members}</div>
            },
        },
        {
            field: 'action',
            headerName: '',
            headerClass: 'custom-header',
            cellClass: 'action-style',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="buttons-container-org">
                <ButtonGroup className="org-button-group">
                    <Button
                        className={`${
                            activeTab === 'members' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setSearchText('')
                            setActiveTab('members')
                            localStorage.setItem('org-manage-tab', 'members')
                        }}
                    >
                        Organisation members
                    </Button>
                    <Button
                        className={`${
                            activeTab === 'teams' ? 'org-button-selected' : ''
                        } org-button`}
                        style={{ borderRadius: '6px' }}
                        onClick={() => {
                            setSearchText('')
                            setActiveTab('teams')
                            localStorage.setItem('org-manage-tab', 'teams')
                           
                        }}
                    >
                        Teams
                    </Button>
                </ButtonGroup>
            </div>
            <div className="heading-container-org">
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">
                            {activeTab === 'members' ? 'Organisation members' : 'Teams'}
                        </span>

                        <span
                            className="text-meili rounded px-2"
                            style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                        >
                            {adminOrgs.status === 'loaded' &&
                                (activeTab === 'members'
                                    ? `${adminOrgs?.results?.length} members`
                                    : `${adminOrgs?.teams?.length} teams`)}
                        </span>
                    </div>
                    <span className="small-text-org">
                        {activeTab === 'members'
                            ? ' Manage your organisation members, their teams, and their account permissions here.'
                            : 'Manage your teams and their members here. Click the edit icon to change your team’s individual details and settings.'}
                    </span>
                </div>
                <div className="d-flex">
                    {/* <Filter dropdownOpen={dropdownOpen} toggle={dropdownToggle} /> */}
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm(activeTab === 'members' ? 'add-member' : 'add-team')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        {btnText}
                    </Button>
                </div>
            </div>
            <div className="search-header">
                {' '}
                <input
                    type="text"
                    className="search-box"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearch}
                ></input>
            </div>
            {adminOrgs.status === 'loading' ? (
                <LoadingSpinner />
            ) : activeTab === 'members' ? (
                <div>
                    <div className="d-flex pb-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Admin users
                            </span>
                            <span className="">
                                Admins can add and remove users and manage organisation-level
                                settings.
                            </span>
                        </div>
                        <div
                            className="rounded-table-container ag-theme-quartz"
                            style={{ height: '530px', width: '90%' }}
                        >
                            {adminsList?.length > 0 ? (
                                <AgGridReact
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    rowData={adminsList}
                                    columnDefs={membersColumns}
                                    onGridReady={onGridReady1}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                    defaultColDef={defaultColDef}
                                    rowHeight={70}
                                    isExternalFilterPresent={isExternalFilterPresent}
                                    doesExternalFilterPass={doesExternalFilterPassForTable1}
                                />
                            ) : (
                                <h5 className="p-4">There are currently no admins or owners.</h5>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex py-3 justify-content-between">
                        <div
                            className="d-flex flex-column"
                            style={{ width: '300px', marginRight: '24px', marginBottom: '8px' }}
                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Account users
                            </span>
                            <span className="">
                                Account users can access and manage the Meili FMS dashboard, mission
                                control, maps, actions, and vehicles.
                            </span>
                        </div>
                        <div
                            className="rounded-table-container ag-theme-quartz"
                            style={{ height: '530px', width: '90%' }}
                        >
                            {membersList?.length > 0 ? (
                                <AgGridReact
                                    classes="my-custom-table"
                                    keyField="uuid"
                                    rowData={membersList}
                                    columnDefs={membersColumns}
                                    onGridReady={onGridReady2}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                    defaultColDef={defaultColDef}
                                    rowHeight={70}
                                    isExternalFilterPresent={isExternalFilterPresent}
                                    doesExternalFilterPass={doesExternalFilterPassForTable1}
                                />
                            ) : (
                                <h5 className="p-4">There are currently no members.</h5>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="rounded-table-container ag-theme-quartz"
                    style={{ height: '530px', width: '100%' }}
                >
                    {teamsList?.length > 0 ? (
                        <AgGridReact
                            classes="my-custom-table"
                            keyField="uuid"
                            rowData={teamsList}
                            columnDefs={teamsColumns}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            rowHeight={70}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 20, 50, 100]}
                            quickFilterText={searchText}
                        />
                    ) : (
                        <h5 className="p-4">There are currently no teams.</h5>
                    )}
                </div>
            )}

            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveFromOrg}
            />
        </div>
    )
}

export default OrganizationManage
