import React, { useState, forwardRef, useEffect } from 'react'
import TaskDetailV2 from './TaskDetailV2'

const Wrapper = forwardRef((props, ref) => (
    <div className="row" ref={ref}>
        {props.children}
    </div>
))

const TasksGridView = ({ tasks, filterLabel, robot, slug, setTaskHovered, currentLayout }) => {
    const [list, setList] = useState([])

    useEffect(() => {
        if (JSON.stringify(tasks) !== JSON.stringify(list))
            setList(
                tasks.map((task) => ({
                    id: task.uuid,
                    ...task,
                }))
            )
    }, [tasks]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="bg-white shadow-none cards-wrap">
            <div
                tag={Wrapper}
                handle=".handle"
                list={list}
                style={{
                    overflowY: 'auto',
                    display:
                        currentLayout && list.length > 0 &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'grid'
                            : 'flex',
                    gridTemplateColumns:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 columns for grid layout
                            : 'none',
                    gridTemplateRows:
                        currentLayout &&
                        (currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'repeat(3, 1fr)' // 3 rows for grid layout
                            : 'none',
                    justifyContent:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'flex-start'
                            : 'initial',
                    alignItems:
                        currentLayout &&
                        !(currentLayout === 'layout2' || currentLayout === 'layout3')
                            ? 'center'
                            : 'initial',
                }}
            >
                {list.length === 0 && (
                    <h5 className="pl-3 py-3">There are currently no missions in {filterLabel}.</h5>
                )}
                {list.map((task) => (
                    <div key={task.uuid} className="mb-3 mx-3">
                        <TaskDetailV2 task={task} robot={robot} slug={slug} setTaskHovered={setTaskHovered} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TasksGridView
