import React, { memo } from 'react'
import ChangePresetModal from './ChangePresetModal'
import CancelAllTasks from '../Tasks/CancelAllTasksModal'
import RunMissionModal from './RunMissionModal'

const CustomPageModals = memo(
    ({
        setIcon,
        modal,
        toggle,
        layouts,
        modalType,
        currentLayout,
        handleLayoutChange,
        setCurrentPresetPage,
        slug,
        setCancelTask,
    }) => {
        const ModalContent = () => {
            switch (modalType) {
                case 'changepreset':
                    return (
                        <ChangePresetModal
                            modal={modal}
                            toggle={toggle}
                            layouts={layouts}
                            currentLayout={currentLayout}
                            handleLayoutChange={handleLayoutChange}
                        />
                    )
                case 'cancelall':
                    return (
                        <CancelAllTasks
                            slug={slug}
                            cancelAllModal={modal}
                            toggleCancelAll={toggle}
                            setCancelTask={setCancelTask}
                        />
                    )
                case 'runmission':
                    return (
                        <RunMissionModal
                            slug={slug}
                            setIcon={setIcon}
                            modal={modal}
                            toggle={toggle}
                            setCurrentPresetPage={setCurrentPresetPage}
                        />
                    )
                default:
                    return
            }
        }

        return <ModalContent />
    }
)

export default CustomPageModals
