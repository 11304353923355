export const actionTypes = {
    GET_PROFILE: 'GET_PROFILE',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_FAIL: 'UPDATE_PROFILE_FAIL',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    SIGNUP: 'SIGNUP',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAIL: 'SIGNUP_FAIL',
    LOGIN: 'LOGIN',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
    REQUEST_RESET_PASSWORD_FAIL: 'REQUEST_RESET_PASSWORD_FAIL',
    REQUEST_RESET_PASSWORD_SUCCESS: 'REQUEST_RESET_PASSWORD_SUCCESS',
    GET_WS_TOKEN: 'GET_WS_TOKEN',
    GET_WS_TOKEN_FAIL: 'GET_WS_TOKEN_FAIL',
    GET_WS_TOKEN_SUCCESS: 'GET_WS_TOKEN_SUCCESS',
    VALIDATE_TOKEN: 'VALIDATE_TOKEN',
    VALIDATE_TOKEN_FAIL: 'VALIDATE_TOKEN_FAIL',
    VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
    ACTIVATE_TOKEN: 'ACTIVATE_TOKEN',
    ACTIVATE_TOKEN_FAIL: 'ACTIVATE_TOKEN_FAIL',
    ACTIVATE_TOKEN_SUCCESS: 'ACTIVATE_TOKEN_SUCCESS',
    ACTIVATE_EMAIL: 'ACTIVATE_EMAIL',
    ACTIVATE_EMAIL_FAIL: 'ACTIVATE_EMAIL_FAIL',
    ACTIVATE_EMAIL_SUCCESS: 'ACTIVATE_EMAIL_SUCCESS',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_PASSWORD_FAIL: 'SET_PASSWORD_FAIL',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    VALIDATE_EMAIL: 'VALIDATE_EMAIL',
    VALIDATE_EMAIL_FAIL: 'VALIDATE_EMAIL_FAIL',
    VALIDATE_EMAIL_SUCCESS: 'VALIDATE_EMAIL_SUCCESS',
    RESEND_EMAIL: 'RESEND_EMAIL',
    RESEND_EMAIL_FAIL: 'VRESEND_EMAIL_FAIL',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',
    GET_ACCESS_TOKEN_SUCCESS: 'GET_ACCESS_TOKEN_SUCCESS',
    GET_ACCESS_TOKEN_FAIL: 'GET_ACCESS_TOKEN_FAIL',
    CREATE_ACCESS_TOKEN: 'CREATE_ACCESS_TOKEN',
    CREATE_ACCESS_TOKEN_SUCCESS: 'CREATE_ACCESS_TOKEN_SUCCESS',
    CREATE_ACCESS_TOKEN_FAIL: 'CREATE_ACCESS_TOKEN_FAIL',
    DELETE_ACCESS_TOKEN: 'DELETE_ACCESS_TOKEN',
    DELETE_ACCESS_TOKEN_SUCCESS: 'DELETE_ACCESS_TOKEN_SUCCESS',
    DELETE_ACCESS_TOKEN_FAIL: 'DELETE_ACCESS_TOKEN_FAIL',
}

export function logout() {
    return {
        type: actionTypes.LOGOUT,
        payload: {
            request: {
                url: 'api/auth/logout/',
                method: 'POST',
            },
        },
    }
}

export function resendEmailByClick({ data }) {
    return {
        type: actionTypes.RESEND_EMAIL,
        payload: {
            client: 'guest',
            request: {
                url: 'api/users/account-validation/resend/',
                method: 'POST',
                data,
            },
        },
    }
}

export function setPasswordByToken({ data, token }) {
    return {
        type: actionTypes.SET_PASSWORD,
        payload: {
            client: 'guest',
            request: {
                url: `api/users/password/change/${token}/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function requestResetPassword(data) {
    return {
        type: actionTypes.REQUEST_RESET_PASSWORD,
        payload: {
            client: 'guest',
            request: {
                url: 'api/users/password/change/',
                method: 'POST',
                data,
            },
        },
    }
}

export function loginUser(data) {
    return {
        type: actionTypes.LOGIN,
        payload: {
            client: 'guest',
            request: {
                url: 'api/auth/login/',
                method: 'POST',
                data,
            },
        },
    }
}

export function validateAccountToken({ token }) {
    return {
        type: actionTypes.VALIDATE_EMAIL,
        payload: {
            client: 'guest',
            request: {
                url: `api/users/account-validation/${token}/`,
                method: 'GET',
            },
        },
    }
}

export function validateOrgToken({ token }) {
    return {
        type: actionTypes.VALIDATE_TOKEN,
        payload: {
            client: 'guest',
            request: {
                url: `api/organizations/memberships/invitations/${token}/`,
                method: 'GET',
            },
        },
    }
}

export function validateBoardingInvitationToken({ token }) {
    return {
        type: actionTypes.VALIDATE_TOKEN,
        payload: {
            request: {
                url: `api/organizations/invitations/${token}/`,
                method: 'GET',
            },
        },
    }
}

export function validateInvitationToken({ token }) {
    return {
        type: actionTypes.VALIDATE_TOKEN,
        payload: {
            client: 'guest',
            request: {
                url: `api/organizations/invitations/${token}/`,
                method: 'GET',
            },
        },
    }
}

export function validateTeamToken({ token }) {
    return {
        type: actionTypes.VALIDATE_TOKEN,
        payload: {
            client: 'guest',
            request: {
                url: `api/teams/invitations/validate/${token}/`,
                method: 'GET',
            },
        },
    }
}

export function changePassword({ data }) {
    return {
        type: actionTypes.CHANGE_PASSWORD,
        payload: {
            request: {
                url: `api/me/password/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateProfile(data) {
    return {
        type: actionTypes.UPDATE_PROFILE,
        payload: {
            responseType: 'blob',
            request: {
                url: `api/me/`,
                method: 'PUT',
                data,
            },
        },
    }
}

export function getProfile() {
    return {
        type: actionTypes.GET_PROFILE,
        payload: {
            request: {
                url: `api/me/`,
                method: 'GET',
            },
        },
    }
}

export function activateToken({ token, type }) {
    if (type === 'organizations')
        return {
            type: actionTypes.ACTIVATE_TOKEN,
            payload: {
                client: 'guest',
                request: {
                    url: `api/organizations/memberships/invitations/${token}/`,
                    method: 'POST',
                    data: {
                        token,
                    },
                },
            },
        }
    if (type === 'teams')
        return {
            type: actionTypes.ACTIVATE_TOKEN,
            payload: {
                client: 'guest',
                request: {
                    url: `api/teams/invitations/validate/${token}/`,
                    method: 'POST',
                    data: {
                        token,
                    },
                },
            },
        }
}

export function activateEmail({ data }) {
    return {
        type: actionTypes.ACTIVATE_EMAIL,
        payload: {
            client: 'guest',
            request: {
                url: `api/auth/register/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function signup({ data }) {
    return {
        type: actionTypes.SIGNUP,
        payload: {
            client: 'guest',
            request: {
                url: `api/auth/register/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getWSToken({ slug }) {
    return {
        type: actionTypes.GET_WS_TOKEN,
        payload: {
            request: {
                url: `api/teams/${slug}/users/ws/token/`,
                method: 'POST',
            },
        },
    }
}

export function getAccessToken() {
    return {
        type: actionTypes.GET_ACCESS_TOKEN,
        payload: {
            request: {
                url: `api/me/api-keys/`,
                method: 'GET',
            },
        },
    }
}

export function createToken({ data }) {
    return {
        type: actionTypes.CREATE_ACCESS_TOKEN,
        payload: {
            request: {
                url: `api/me/api-keys/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function deleteAccessToken(uuid) {
    return {
        type: actionTypes.DELETE_ACCESS_TOKEN,
        payload: {
            request: {
                url: `api/me/api-keys/${uuid}`,
                method: 'DELETE',
            },
        },
    }
}