import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { FormGroup, Label, Button } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { selectors } from 'features/dashboard'
import { createDevice, getDevices, updateDevice } from 'features/dashboard/RosSetup.actionTypes'
import { HiOutlineTrash } from 'react-icons/hi'
import DropdownSelect from 'layout-components/Dropdown'
import { canRedo, canUndo } from 'features/dashboard/Map.actionTypes'
import { useSelector } from 'react-redux'

const DeviceModal = ({ slug, toggle, device = {}, handleNewDevice, setWindows, areaUuid }) => {
    const dispatch = useDispatch()
    const devices = useSelector(selectors.getDevices)
    const [deviceModel, setDeviceModel] = useState(devices?.find((d) => d.uuid === device?.model)?.name || '')

    const handleSubmit = (data) => {
        const { uuid } = data
        if (uuid) {
            dispatch(updateDevice({ data, uuid })).then((res) => {
                dispatch(getDevices({ slug }))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
            })
            toggle()
        } else {
            if (data.points) {
                dispatch(createDevice({ data, uuid: areaUuid })).then((res) => {
                    dispatch(getDevices({ slug }))
                    dispatch(canRedo(areaUuid))
                    dispatch(canUndo(areaUuid))
                    toggle()
                })
            } else {
                handleNewDevice(data)
                toggle()
            }
        }
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/device-modal-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                <strong className="modals-header">
                    {device?.uuid ? 'Edit device' : 'Add device'}
                </strong>
            </h5>
            <Formik initialValues={{ ...device }} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        {device?.uuid && (
                            <FormGroup>
                                <Label className="modals-labels" for="title">
                                    Name
                                </Label>
                                <Field
                                    id="title"
                                    type="text"
                                    name="title"
                                    className="modals-inputs"
                                    placeholder="title"
                                    component={customInputForm}
                                    value={values.title}
                                ></Field>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <Label for="device-model">Device model *</Label>
                            <DropdownSelect
                                selected={deviceModel}
                                setSelected={setDeviceModel}
                                setFieldValue={setFieldValue}
                                fieldValue="model"
                                options={devices}
                            />
                            {/* <Field
                                id="device-model"
                                component={customInputForm}
                                type="select"
                                name="model"
                                required
                                className="modals-inputs"
                                value={values.model || ''}
                            >
                                <option value=""></option>
                                {devices.map((device) => (
                                    <option key={device.uuid} value={device.uuid}>
                                        {device.name}
                                    </option>
                                ))}
                            </Field>*/}
                        </FormGroup>

                        <FormGroup>
                            <Label className="modals-labels">Position</Label>
                            <div className="d-flex justify-content-between align-items-center">
                                <Field
                                    className="mr-1 modals-inputs"
                                    type="number"
                                    placeholder="Metric X"
                                    step={0.1}
                                    name="points[0]"
                                    component={customInputForm}
                                ></Field>
                                <Field
                                    className="ml-1 modals-inputs"
                                    type="number"
                                    placeholder="Metric Y"
                                    step={0.1}
                                    name="points[1]"
                                    component={customInputForm}
                                ></Field>
                            </div>
                            {!device?.uuid && (
                                <span className="small-text">
                                    If position is not entered you can drag and drop on the map
                                </span>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label className="modals-labels" for="identifier">
                                Identifier *
                            </Label>
                            <Field
                                id="identifier"
                                type="text"
                                name="identifier"
                                className="modals-inputs"
                                placeholder="Identifier"
                                required
                                component={customInputForm}
                                value={values.identifier || ''}
                            ></Field>
                        </FormGroup>

                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100
                           ${device?.uuid ? 'justify-content-between' : 'justify-content-end'}
                             align-items-center`}
                        >
                            {device?.uuid && (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setWindows({ removeDevice: true })
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete device
                                </Button>
                            )}
                            <div className="d-flex">
                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    className="save-btn-modal modals-new-btns"
                                    disabled={
                                        device?.uuid &&
                                        JSON.stringify(values) === JSON.stringify(device)
                                    }
                                >
                                    {device?.uuid ? 'Save' : 'Create'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default DeviceModal
