import React, { useState } from 'react'
import useImage from 'use-image'
import { Image as KonvaImage, Text } from 'react-konva'

const MapStation = ({
    station,
    editPoint,
    handleMouse,
    setdraw,
    icon,
    size,
    path,
    destinationPoints,
    setConnectPoint,
    connectPoint,
    handleApiRequest,
    setHover,
    setDataHovered,
    setDestinationPoints,
    handleClickStation,
    handleDragStart,
    handleDragEnd,
    handleMouseDown,
}) => {
    const [stationDefault] = useImage('/svgs/map-layer/station-default.svg')
    const [defaultHover] = useImage('/svgs/map-layer/station-hover.svg')
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [chargingHover] = useImage('/svgs/map-layer/charging-hover.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [restingHover] = useImage('/svgs/map-layer/resting-hover.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [traversalHover] = useImage('/svgs/map-layer/traversal-hover.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')
    const [hov, setHov] = useState('')
    const [highlight, sethighlight] = useState(false)
    const [draggingAction, setDragginAction] = useState(false)

    const showImage = () => {
        switch (station.station_type) {
            case 'charging':
                if (highlight && station.is_active) return chargingHover
                if (!station.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'resting':
                if (highlight && station.is_active) return restingHover
                if (!station.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (highlight && station.is_active) return traversalHover
                if (!station.is_active) {
                    return traversalDisabled
                }
                return traversal
            default:
                if (highlight && station.is_active) return defaultHover
                if (!station.is_active) {
                    return stationDisabled
                }
                return stationDefault
        }
    }

    if (!station) return
    if (!station.points) return

    const name = station.title ? station.title : ''
    const posX = `${station?.points_in_meters && station.points_in_meters[0]}m`
    const posY = `${station?.points_in_meters && station.points_in_meters[1]}m`
    const type = station?.station_type.charAt(0).toUpperCase() + station?.station_type.slice(1)
    const rotation = station?.rotation
    const dataToShow = { name, type, x: posX, y: posY, rotation }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={station?.station_dimensions_in_pixels[1]}
                height={station?.station_dimensions_in_pixels[1]}
                draggable={editPoint && station.station_type !== 'action'}
                offsetX={station?.station_dimensions_in_pixels[1] / 2}
                offsetY={station?.station_dimensions_in_pixels[1] / 2}
                x={station.points[0]}
                y={station.points[1]}
                rotation={station.rotation}
                onDragMove={handleMouse}
                onDragEnd={(e) => {
                    setDragginAction(false)
                    handleDragEnd(e, station)
                }}
                onDragStart={(e) => {
                    setDragginAction(true)
                    handleDragStart(e)
                }}
                onMouseEnter={(e) => {
                    if (station.station_type === 'action') return
                    const container = e.target.getStage().container()
                    container.style.cursor = 'pointer'
                    sethighlight(true)
                    setDataHovered(dataToShow)
                    if (path) {
                        destinationPoints.length === 0 && setHov('Click to create path')
                        setHover('')
                    }
                    if (editPoint) {
                        destinationPoints.length === 0 && setHov('Drag to move/Click to rotate')
                    }
                }}
                onMouseLeave={(e) => {
                    if (station.station_type === 'action') return
                    const container = e.target.getStage()?.container()
                    container.style.cursor = 'default'
                    sethighlight(false)
                    setDataHovered(null)
                    setHov('')
                }}
                onClick={() => {
                    if (station.station_type === 'action') return
                    if (!station.is_active) return
                    if (path === true) {
                        if (destinationPoints.length === 0) {
                            setdraw(true)
                            setConnectPoint([...connectPoint, station.points[0], station.points[1]])
                            setDestinationPoints([...destinationPoints, station.uuid])
                        }
                        if (destinationPoints.length === 1) {
                            setDestinationPoints([...destinationPoints, station.uuid])
                            handleApiRequest(station.uuid)
                        }
                    }

                    if (editPoint) {
                        handleMouseDown(station)
                    } else if (!editPoint && !path) {
                        handleClickStation(station)
                    }
                }}
            />

            {!draggingAction && (
                <Text
                    x={station.points[0]}
                    y={station.points[1] - size}
                    offsetX={station?.station_dimensions_in_pixels[1] / 2}
                    offsetY={station?.station_dimensions_in_pixels[1] / 2}
                    stroke="#670d95"
                    strokeWidth={0.1}
                    text={icon && station.station_type !== 'traversal' ? station.title : hov}
                    fontStyle="bold"
                    fontSize={size}
                />
            )}
        </>
    )
}

export default MapStation
