import React, { useEffect, useState } from 'react'
import { Field, Form, Formik, FieldArray } from 'formik'
import { Button, Label, FormGroup, Col, Modal, ModalBody, ButtonGroup } from 'reactstrap'

import { customInputForm } from 'ui/common'
import { rossetupVehicleSchema } from 'schemas/dashboard'
import { HiOutlineTrash } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'

const RosSetupVehicleForm = ({
    modal,
    toggle,
    team,
    vehicles,
    initialValues,
    handleAdd,
    handleUpdate,
    handleRemove,
}) => {
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('vehicle')
    const [vehicle, setVehicle] = useState(initialValues?.uuid || '')

    useEffect(() => {
        dispatch(getTeamVehicles({ slug: team, text: 'light=true&no_pagination=true' }))
    }, [dispatch, team])

    const ROS_CONSTANTS = {
        pose: {
            label: 'pose',
            topic: '/amcl_pose',
            message_type: 'geometry_msgs/PoseWithCovarianceStamped',
        },
        battery: {
            label: 'battery',
            topic: '/battery_state',
            message_type: 'sensor_msgs/BatteryState',
        },
        goal: {
            label: 'goal',
            topic: '/move_base_simple/goal',
            message_type: 'geometry_msgs/PoseStamped',
        },
        goal_cancell: {
            label: 'goal_cancell',
            topic: '/move_base/cancel',
            message_type: 'actionlib_msgs/GoalID',
        },
        goalArray: {
            label: 'goalArray',
            topic: '/move_base/status',
            message_type: 'actionlib_msgs/GoalStatusArray',
        },
        goalPlan: {
            label: 'goalPlan',
            topic: '/move_base/NavfnROS/plan',
            message_type: 'nav_msgs/Path',
        },
    }
    const addTopicOverrides = ({ values, setFieldValue, topic }) => {
        const existedTopic = values.topic_overrides.find(
            (override) => override.label === topic.label
        )
        if (!existedTopic) setFieldValue('topic_overrides', [...values.topic_overrides, topic])
    }
    return (
        <Modal isOpen={modal} toggle={toggle} centered size="lg">
            <ModalBody
                style={{ padding: '24px 24px 0px 24px', position: 'relative', overflowY: 'hidden' }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img
                        src="/svgs/task-modal/smiley-robot.svg"
                        alt="obstacle-icon"
                        width="48px"
                        height="48px"
                    />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex flex-column">
                    <span className="org-modal-header">
                        {initialValues?.uuid ? 'Edit vehicle' : 'Add vehicle'}
                    </span>
                </div>
                <Formik
                    initialValues={{ topic_overrides: [], ...initialValues }}
                    validationSchema={rossetupVehicleSchema}
                    onSubmit={(data) => {
                        const topic_overrides = data.topic_overrides.filter((override) => {
                            return (
                                JSON.stringify(override) !==
                                JSON.stringify(ROS_CONSTANTS[override.label])
                            )
                        })
                        setVehicle('')
                        if (initialValues?.uuid) return handleUpdate({ ...data, topic_overrides })
                        return handleAdd({ ...data, topic_overrides })
                    }}
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <div className="modals-container-buttons">
                                    <ButtonGroup className="org-button-group">
                                        <Button
                                            className={`${
                                                activeTab === 'vehicle' ? 'org-button-selected' : ''
                                            } org-button`}
                                            style={{ borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('vehicle')
                                            }}
                                        >
                                            Vehicle
                                        </Button>
                                        {Object.values(ROS_CONSTANTS).map((topic) => (
                                            <Button
                                                key={topic.label}
                                                className={`${
                                                    activeTab === topic.label
                                                        ? 'org-button-selected'
                                                        : ''
                                                } org-button`}
                                                style={{ borderRadius: '6px' }}
                                                onClick={() => {
                                                    addTopicOverrides({
                                                        values,
                                                        setFieldValue,
                                                        topic,
                                                    })
                                                    setActiveTab(topic.label)
                                                }}
                                            >
                                                {topic.label.charAt(0).toUpperCase() +
                                                    topic.label.slice(1)}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                                {activeTab === 'vehicle' && (
                                    <>
                                        <FormGroup>
                                            <Label className="modals-labels" for="vehicle">
                                                Vehicle *
                                            </Label>
                                            <DropdownSelect
                                                selected={vehicle}
                                                setSelected={setVehicle}
                                                options={vehicles?.teamVehicle.map(
                                                    (vehicle) => vehicle
                                                )}
                                                setFieldValue={setFieldValue}
                                                fieldValue="uuid"
                                            />
                                            {/* <Field
                                                className="modals-inputs"
                                                component={customInputForm}
                                                type="select"
                                                required
                                                value={values.uuid || ''}
                                                name="uuid"
                                            >
                                                <option value=""></option>
                                                {vehicles?.teamVehicle.map((vehicle) => (
                                                    <option key={vehicle.uuid} value={vehicle.uuid}>
                                                        {vehicle.external_identifier ||
                                                            vehicle.verbose_name}
                                                    </option>
                                                ))}
                                            </Field> */}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels" for="prefix">
                                                Prefix
                                            </Label>
                                            <Field
                                                className="modals-inputs"
                                                component={customInputForm}
                                                type="text"
                                                value={values.prefix || ''}
                                                name="prefix"
                                                placeholder="Prefix"
                                            />
                                        </FormGroup>
                                    </>
                                )}
                                <FieldArray
                                    name="topic_overrides"
                                    render={() =>
                                        values.topic_overrides.map((topic, index) => (
                                            <div key={index}>
                                                {activeTab === topic.label && (
                                                    <FormGroup row>
                                                        <Col md={12}>
                                                            <Label className="modals-labels" for="">
                                                                Label
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                value={topic.label}
                                                                name={`topic_overrides[${index}].label`}
                                                                readOnly={true}
                                                            />
                                                        </Col>
                                                        <Col md={12} className="my-2">
                                                            <Label className="modals-labels" for="">
                                                                Topic
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                values={topic.topic}
                                                                name={`topic_overrides[${index}].topic`}
                                                            />
                                                        </Col>
                                                        <Col md={12}>
                                                            <Label className="modals-labels" for="">
                                                                Message type
                                                            </Label>
                                                            <Field
                                                                component={customInputForm}
                                                                className="modals-inputs"
                                                                type="text"
                                                                values={topic.message_type}
                                                                name={`topic_overrides[${index}].message_type`}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                            </div>
                                        ))
                                    }
                                />
                                <div className="new-modal-footer">
                                    {initialValues?.uuid ? (
                                        <Button
                                            color="none"
                                            onClick={() => {
                                                setVehicle('')
                                                handleRemove(initialValues.uuid)
                                            }}
                                            className="modals-delete-btn"
                                        >
                                            <HiOutlineTrash size={20} className="mr-1" />
                                            Delete
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}

                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns"
                                            onClick={() => {
                                                toggle()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="save-btn-modal modals-new-btns"
                                            style={{ marginLeft: '12px' }}
                                            type="submit"
                                        >
                                            {initialValues?.uuid ? 'Update' : 'Add'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default RosSetupVehicleForm
