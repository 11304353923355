import React, { useState } from 'react'
import { Image as KonvaImage, Label } from 'react-konva'
import { Modal, ModalBody, FormGroup, Button } from 'reactstrap'
import useImage from 'use-image'
import { stationIconSize } from './MapLayerHelpers'
import { Html } from 'react-konva-utils'
import { Field, Form, Formik } from 'formik'
import { customInputForm } from 'ui/common'

const IndoorMapDevices = ({ device, aoi, setDataHovered }) => {
    const [deviceDefault] = useImage('/svgs/map-layer/device.svg')
    const [deviceDefaultHover] = useImage('/svgs/map-layer/device-hover.svg')
    const [deviceOffline] = useImage('/svgs/map-layer/device-disabled.svg')
    const [closedDoorDefault] = useImage('/svgs/map-layer/closed-door.svg')
    const [closedDoorHover] = useImage('/svgs/map-layer/closed-door-hover.svg')
    const [closedDoorOffline] = useImage('/svgs/map-layer/door-offline.svg')
    const [switchOnDefault] = useImage('/svgs/map-layer/switch.svg')
    const [switchOnHover] = useImage('/svgs/map-layer/switch-hover.svg')
    const [switchOffline] = useImage('/svgs/map-layer/switch-offline.svg')
    const [elevatorDefault] = useImage('/svgs/map-layer/elevator.svg')
    const [elevatorHover] = useImage('/svgs/map-layer/elevator-hover.svg')
    const [elevatorOffline] = useImage('/svgs/map-layer/elevator-offline.svg')
    const [trafficLightRedDefault] = useImage('/svgs/map-layer/traffic-light-red.svg')
    const [trafficLightRedHover] = useImage('/svgs/map-layer/traffic-light-red-hover.svg')
    const [trafficLightOffline] = useImage('/svgs/map-layer/traffic-light-offline.svg')
    const { width, height } = aoi
    const biggerMapSize = 1000000
    const [hovered, setHovered] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const toggle = () => setModalOpen(!modalOpen)

    const showImage = () => {
        switch (device.model_type) {
            case 'door':
                if (hovered) return closedDoorHover
                if (!device.online) return closedDoorOffline
                return closedDoorDefault
            case 'switch':
                if (hovered) return switchOnHover
                if (!device.online) return switchOffline
                return switchOnDefault
            case 'elevator':
                if (hovered) return elevatorHover
                if (!device.online) return elevatorOffline
                return elevatorDefault
            case 'traffic_light':
                if (hovered) return trafficLightRedHover
                if (!device.online) return trafficLightOffline
                return trafficLightRedDefault
            default:
                if (device.online) {
                    if (hovered) return deviceDefaultHover
                    return deviceDefault
                }
                return deviceOffline
        }
    }

    if (!device) return
    if (!device.points) return

    const name = device.title
    const identifier = device.identifier
    const model_type = device.model_type
    const online = device.online
    const dataToShow = { name, identifier, model_type, online }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={device.points[0]}
                y={device.points[1]}
                onClick={toggle}
                onMouseEnter={(e) => {
                    if(!device.online) return
                    setHovered(true)
                    setDataHovered(dataToShow)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'pointer'
                }}
                onMouseLeave={(e) => {
                    setHovered(false)
                    setDataHovered(null)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
            />
            <Html>
                <Modal isOpen={modalOpen} toggle={toggle} centered>
                    <ModalBody
                        style={{
                            padding: '24px',
                            position: 'relative',
                            overflowY: 'auto',
                        }}
                    >
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img
                                src="/svgs/modal-icons/device-modal-icon.svg"
                                alt="obstacle-icon"
                                width="48px"
                                height="48px"
                            />
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        <div className="d-flex justify-content-start align-items-start">
                            <h5>
                                <strong className="modals-header">Device details</strong>
                            </h5>

                            <span
                                className="ml-2 d-flex justify-content-center align-items-center pull-top"
                                style={{
                                    backgroundColor: '#F4F4F9',
                                    padding: '0px 8px',
                                    borderRadius: '16px',
                                    color: '#3A3E66',
                                }}
                            >
                                {device.status
                                    ? `${
                                          device.status.charAt(0).toUpperCase() +
                                          device?.status?.slice(1)
                                      }`
                                    : 'Offline'}
                            </span>
                        </div>

                        <Formik initialValues={device}>
                            {({ values }) => (
                                <Form>
                                    <FormGroup>
                                        <Label className="modals-labels" for="name">
                                            Name
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            id="name"
                                            type="text"
                                            name="title"
                                            readOnly
                                            component={customInputForm}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="identifier">
                                            Identifier
                                        </Label>
                                        <Field
                                            className="modals-inputs"
                                            readOnly
                                            id="identifier"
                                            type="text"
                                            name="identifier"
                                            component={customInputForm}
                                        />
                                    </FormGroup>
                                    <div>
                                        <Label className="modals-labels" for="definition">
                                            States
                                        </Label>

                                        {device.states &&
                                            device.states.length > 0 &&
                                            device.states.map((state, i) => {
                                                return (
                                                    <div
                                                        key={state.uuid}
                                                        className="p-2 d-flex justify-content-between align-items-center"
                                                    >
                                                        <div className="d-grid row-2">
                                                            <span className="mr-3">{i + 1}.</span>
                                                            <span>
                                                                {state.title}
                                                                {state.title ? ' -' : null}{' '}
                                                                {state.payload
                                                                    ? state.payload
                                                                    : null}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    <Button
                                        onClick={toggle}
                                        className="save-btn-modal modals-new-btns pull-right mt-2"
                                    >
                                        Close window
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </Modal>
            </Html>
        </>
    )
}

export default IndoorMapDevices
