import React from 'react'
import * as Sentry from '@sentry/react'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import configureStore from './store'

import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './i18n'
import './assets/base.scss'
import './assets/styles.scss'

// New import for createRoot
import { createRoot } from 'react-dom/client'

const store = configureStore()

Sentry.init({
    //dsn: process.env.REACT_APP_SENTRY_DSN,
    // ToDo get this from OPS [Not on the envVars of the development FE pod]
    // ToDo Link with SENTRY_DSN in src/config.js
    dsn: 'https://6ed0ada713a740869fbb636f8a7d31a7@o482981.ingest.us.sentry.io/5962890',
    //release: process.env.REACT_APP_VERSION,
    // ToDo get this from OPS
    release: '1.0.1',
    //sampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0,
    // ToDo get this from OPS (Already exist in OPS)
    sampleRate: 1,
    // ToDo get this from OPS  (Already exist in OPS)
    tracesSampleRate: 1,
    debug: false,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    // ToDo get this from OPS
    environment: 'development',
})
const error = console.error
console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return
    error(...args)
}

const rootElement = document.getElementById('root')

// New way to render the app using createRoot
if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
        <Sentry.ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </Sentry.ErrorBoundary>
    )
}

// Service worker configuration
serviceWorker.unregister()
