import React from 'react'
import MapModal from './MapModal'
import NewZoneForm from './NewZoneForm'
import RemoveModal from './RemoveModal'
import { Modal, ModalBody } from 'reactstrap'
import EditActionZonePreset from './EditActionZonePreset'

const MapEditorModal = ({
    openModal,
    modal,
    status,
    toggle,
    saveMap,
    resetMap,
    areaUuid,
    slug,
    isOriginalImage,
    cancelNewZone,
    handleAdd,
    newZone,
    handleDeleteModalShow,
    setNotification,
    isClicked,
    nextPagePresets,
    currentPage,
    setCurrentPageNumber
}) => {
    const ModalContent = () => {
        switch (openModal) {
            case 'topIcon':
                return (
                    <MapModal
                        status={status}
                        modal={modal}
                        toggle={toggle}
                        saveMap={saveMap}
                        resetMap={resetMap}
                        slug={slug}
                        isOriginalImage={isOriginalImage}
                        // update={loadData}
                        // mapAnalysisData={props.mapAnalysisData}
                    />
                )
            case 'sideIcon':
                return (
                    <NewZoneForm
                        handleAdd={handleAdd}
                        zone={newZone}
                        isOriginalImage={isOriginalImage}
                        modal={modal}
                        toggle={toggle}
                        isClicked={isClicked}
                        nextPagePresets={nextPagePresets}
                        setCurrentPageNumber={setCurrentPageNumber}
                        handleDeleteModalShow={handleDeleteModalShow}
                    />
                )

            case 'actionZone':
                return (
                    <EditActionZonePreset
                        zone={newZone}
                        toggle={toggle}
                        modal={modal}
                        setNotification={setNotification}
                        handleDeleteModalShow={handleDeleteModalShow}
                    />
                )
            case 'removeButton':
                return (
                    <RemoveModal
                        slug={slug}
                        data={newZone}
                        areaUuid={areaUuid}
                        type="zone"
                        toggle={toggle}
                        currentPage={currentPage}
                        cancelNewZone={cancelNewZone}
                        setNotification={setNotification}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            centered
            className="new-modals"
        >
            <ModalBody>
                <ModalContent />
            </ModalBody>
        </Modal>
    )
}

export default MapEditorModal
