import React from 'react'
import { Formik } from 'formik'
import { Button, Form, FormGroup, Input, Modal, ModalBody } from 'reactstrap'

const ChangePresetModal = ({ modal, toggle, layouts, currentLayout, handleLayoutChange }) => {
    return (
        <Modal isOpen={modal} toggle={toggle} centered style={{ maxWidth: '65rem', width: ' auto' }}>
            <ModalBody
                style={{
                    padding: '24px 24px 0px 24px',
                    position: 'relative',
                    overflowY: 'auto',
                    maxHeight: '90vh',
                    width: 'auto',
                }}
            >
                <div
                    className="w-100 d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <img src="/svgs/modal-icons/edit.svg" alt="edit" width="48px" height="48px" />
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
                <div className="d-flex mb-4">
                    <span className="org-modal-header">Page layout presets</span>
                </div>
                <Formik
                    initialValues={{ layout: currentLayout }}
                    onSubmit={(data) => handleLayoutChange(data.layout)}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form
                            style={{ marginTop: '14px' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <div
                                className="w-100 h-100"
                                style={{
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    rowGap: '24px',
                                }}
                            >
                                {Object.keys(layouts).map((layout, index) => (
                                    <FormGroup
                                        key={layout}
                                        className="d-flex flex-column align-items-center justify-content-end"
                                        style={
                                            index === Object.keys(layouts).length - 1
                                                ? {
                                                      gridColumn: '2 / span 2',
                                                      justifySelf: 'center',
                                                  } // Last item centered
                                                : {}
                                        }
                                    >
                                        <img
                                            src={`/svgs/layout-presets/${layout}.svg`}
                                            alt={layout}
                                            for={layout}
                                            width="208px"
                                            height="136px"
                                            className="mb-4"
                                        />

                                        <Input
                                            type="checkbox"
                                            id={layout}
                                            name="layout"
                                            checked={values.layout === layout}
                                            onChange={() => setFieldValue('layout', layout)}
                                        />
                                    </FormGroup>
                                ))}
                            </div>

                            <div className="new-modal-footer d-flex justify-content-end">
                                <Button
                                    className="cancel-btn-modal modals-new-btns"
                                    onClick={() => {
                                        toggle()
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    style={{ marginLeft: '12px' }}
                                    type="submit"
                                >
                                    Save preset
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default ChangePresetModal
