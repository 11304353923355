import React, { memo, useRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardHeader, UncontrolledTooltip, ButtonGroup, Button } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react'
import { selectors } from 'features/dashboard'
import ColumnEditor from 'layout-components/ColumnEditor'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import WhitelabelVehicle from '../WhitelabelVehicle'
import Battery from '../Battery'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import { useDispatch } from 'react-redux'

const Vehicles = memo(({ renderDashboard, slug }) => {
    const dispatch = useDispatch()
    const vehicles = useSelector(selectors.getVehicles)
    const devices = useSelector(selectors.getDevicess)
    const [activeTab, setActiveTab] = useState('vehicles')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPageVeh, setLastClickedPageVeh] = useState(1)

    const parseData = JSON.parse(localStorage.getItem('columns-vehicles'))
    const parsedeviceData  =  JSON.parse(localStorage.getItem('columns-devices'))
    const gridRef = useRef()
    const [api, setApi] = useState(null)
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setApi(params.api)
        currentPageNumber > 1 && params.api.paginationGoToPage(lastClickedPageVeh)
    }

    const defaultColDef = {
        cellClass: 'custom-cell', // Apply custom class to all cells
        headerClass: 'custom-header ',
        resizable: true,
        sortable: true,
        flex: 1, // Apply custom class to all headers
    }
    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPageVeh(currentPage)
        }
    }

    const restoredCol = parseData?.map((colDef) => {
        switch (colDef.field) {
            case 'location':
                return {
                    ...colDef,
                    valueFormatter: (params) => {
                        return params.value
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data.location}</div>
                    },
                }
            case 'vehicleType':
                    return {
                        ...colDef,
                        valueFormatter: (params) => {
                            return params.value
                        },
                        cellRenderer: (params) => {
                            return <div>{params.data.vehicleType}</div>
                        },
                    }
            case 'status':
                return {
                    ...colDef,
                    valueFormatter: (params) => {
                        return params.value
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data.status}</div>
                    },
                }
            case 'battery':
                return {
                    ...colDef,
                    valueFormatter: (params) => {
                        return params.value
                    },
                    cellRenderer: (params) => {
                        return <div>{params.data.battery}</div>
                    },
                    
                }
            default:
                return colDef
        }
    })

    const restoredDeviceDefs = parsedeviceData?.map(colDef => {
        if (colDef.field === 'status') {
            return {
                ...colDef,
                valueFormatter: (params) => {
                    return params.value
                },
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
            }
        }
        return colDef;
      });

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [columnsList, setColumnsList] = useState(
        (parseData && restoredCol) || [
            {
                field: 'verboseName',
                headerName: 'Name',
                sortable: true,
                unSortIcon: true,
                hide: false,
                minWidth: 100,
            },
            {
                field: 'vehicleType',
                headerName: 'Type',
                hide: false,
                minWidth: 100
            },
            {
                field: 'vehicle_state',
                headerName: 'State',
                hide: false,
                minWidth: 100
            },
            {
                field: 'location',
                headerName: 'Location',
                hide: false,
                minWidth: 100,
                cellRenderer: (params) => {
                    return <div>{params.data.location}</div>
                },
            },
            {
                field: 'current_mission',
                headerName: 'Mission',
                hide: false,
                minWidth: 100,
                cellStyle: {marginLeft: "20px"},
            },
            {
                field: 'status',
                headerName: 'Status',
                minWidth: 100,
                hide: false,
                cellStyle: {marginLeft: "20px"},
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
            },
            {
                field: 'speed',
                headerName: 'Speed',
                minWidth: 100,
                hide: false,
            },
            {
                field: 'battery',
                headerName: 'Battery',
                hide: false,
                minWidth: 100,
                cellRenderer: (params) => {
                    return <div>{params.data.battery}</div>
                },
            },
        ]
    )
    useEffect(() => {
        dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line


    const [devicesColumns, setDevicesColumns] = useState(
        (restoredDeviceDefs) || [
            {
                field: 'title',
                headerName: 'Name',
                sortable: true,
                unSortIcon: true,
                hide: false,
                minWidth: 100,
            },
            {
                field: 'identifier',
                headerName: 'Identifier',
                hide: false,
                minWidth: 100,
            },
            {
                field: 'states',
                headerName: 'State',
                hide: false,
                cellStyle: {marginLeft: "10px"},
                minWidth: 100,
            },
            {
                field: 'status',    
                headerName: 'Online',
                cellStyle: {marginLeft: "20px"},
                cellRenderer: (params) => {
                    return <div>{params.data.status}</div>
                },
                hide: false,
            },
        
        ]
    )

    const toggleEdit = () => setDropdownOpen((prevState) => !prevState)

    const VehicleDetails = ({ vehicle, type }) => {
        if (!vehicle.details) {
            return ''
        } else {
            const { xm, ym, lat, lon, rotation } = vehicle.details
            if (lat || lon) {
                return `Lat: ${lat} - Lon: ${lon}`
            } else {
                const formattedXm = xm ? parseFloat(xm).toFixed(2) : '-'
                const formattedYm = ym ? parseFloat(ym).toFixed(2) : '-'
                const formattedRotation = rotation ? parseFloat(rotation).toFixed(2) + '°' : '-'
                const formattedStation = vehicle.current_station ? vehicle.current_station : '-'
                if (type === 'location') return <span>{`${formattedXm}m / ${formattedYm}m`}</span>
                if (type === 'rotation') return <span>{formattedRotation}</span>
                if (type === 'station') return <span>{formattedStation}</span>
            }
        }
    }

    const Status = ({ vehicle }) => {
        if (vehicle.is_online) {
            if (vehicle.vehicle_state === 'emergency_stop') {
                return (
                    <div id="emergency-stop">
                        🟠{' '}
                        <UncontrolledTooltip placement="left" target="emergency-stop">
                            Emergency stop
                        </UncontrolledTooltip>
                    </div>
                )
            }
            if (vehicle.is_charging) {
                return (
                    <div id="charging">
                        🔵{' '}
                        <UncontrolledTooltip placement="left" target="charging">
                            Charging
                        </UncontrolledTooltip>
                    </div>
                )
            }
            if (vehicle.is_on_task) {
                return (
                    <div id="busy">
                        🟡{' '}
                        <UncontrolledTooltip placement="left" target="busy">
                            In use
                        </UncontrolledTooltip>
                    </div>
                )
            }
            return (
                <div id="available">
                    🟢{' '}
                    <UncontrolledTooltip placement="left" target="available">
                        Available
                    </UncontrolledTooltip>
                </div>
            )
        } else {
            return (
                <div id="offline">
                    🔴{' '}
                    <UncontrolledTooltip placement="left" target="offline">
                        Unavailable
                    </UncontrolledTooltip>
                </div>
            )
        }
    }

    const StatusDevice = ({ device }) => {
        if (device.online) {
            return (
                <div id="available">
                    🟢{' '}
                    <UncontrolledTooltip placement="bottom" target="available">
                        Online
                    </UncontrolledTooltip>
                </div>
            )
        } else
            return (
                <div id="offline">
                    ⚪{' '}
                    <UncontrolledTooltip placement="bottom" target="offline">
                        Offline
                    </UncontrolledTooltip>
                </div>
            )
    }

    const visibleColumns = columnsList.filter((column) => !column.hidden)

    const dataList = useMemo(() => {
        if (!vehicles?.teamVehicle) return []
        return vehicles.teamVehicle?.map((vehicle) => ({
            uuid: vehicle.uuid,
            verboseName: vehicle?.verbose_name,
            vehicleType: vehicle?.vehicle_type?.toUpperCase(),
            vehicle_state: vehicle?.vehicle_state,
            status: <Status vehicle={vehicle} />,
            location: <VehicleDetails vehicle={vehicle} type="location" />,
            rotation: <VehicleDetails vehicle={vehicle} type="rotation" />,
            current_mission: vehicle.current_mission || '',
            speed:
                vehicle.details && !isNaN(parseFloat(vehicle.details?.speed))
                    ? parseFloat(vehicle.details.speed).toFixed(2) + 'm/s'
                    : '',
            battery: <Battery percentage={vehicle.battery_level} uuid={vehicle.uuid} />,
            is_active: vehicle.is_online,
            // current_station: vehicle.current_station,
        }))
    }, [vehicles.teamVehicle])

    const visibleDeviceColumns = devicesColumns?.filter((column) => !column.hidden)

    const devicesList = useMemo(() => {
        if (!devices) return []
        return devices.map((device) => ({
            uuid: device.uuid,
            title: device?.title,
            identifier: device?.identifier,
            states: device?.status || '',
            status: <StatusDevice device={device} />,
        }))
    }, [devices])

    if (vehicles.status === 'loading') {
        return (
            <div className="w-100 h-100">
                <LoadingSpinner />
            </div>
        )
    }
    if (renderDashboard === true) {
        return <WhitelabelVehicle />
    } else
        return (
            <Card
                className="card-box w-100 h-100 d-flex flex-column justify-content-start align-items-start"
                style={{ overflow: 'hidden', maxHeight: '100%', minHeight: '100%', minWidth: '100%' }}
            >
                <CardHeader className="w-100 d-flex">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <ButtonGroup className="org-button-group gap-1" style={{ padding: '3px' }}>
                            <Button
                                data-testid="vehicleBtn"
                                className={` ${
                                    activeTab === 'vehicles' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setActiveTab('vehicles')
                                }}
                            >
                                Vehicles
                            </Button>
                            <Button
                                data-testid="deviceBtn"
                                className={`${
                                    activeTab === 'devices' ? 'org-button-selected' : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setActiveTab('devices')
                                }}
                            >
                                Devices
                            </Button>
                        </ButtonGroup>
                        <ColumnEditor
                            toggle={toggleEdit}
                            dropdownOpen={dropdownOpen}
                            activeTab={activeTab}
                            list={activeTab === 'vehicles' ? columnsList : devicesColumns}
                            setList={activeTab === 'vehicles' ? setColumnsList : setDevicesColumns}
                        />
                    </div>
                </CardHeader>
                <div
                                className="rounded-table-container ag-theme-quartz"
                                style={{ height: '530px', width: '100%' }}
                            >
                                {activeTab === 'vehicles' ? (
                                    vehicles.teamVehicle.length > 0 ? (
                                        <AgGridReact
                                            bootstrap4={true}
                                            classes="my-custom-table"
                                            keyField="uuid"
                                            rowData={dataList}
                                            columnDefs={visibleColumns || []}
                                            onGridReady={onGridReady}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={[10, 20, 50, 100]}
                                            defaultColDef={defaultColDef}
                                            rowHeight={60}
                                            onPaginationChanged={getCurrentPage}
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no vehicles.</h5>
                                    )
                                ) : null}
                                
                                {activeTab === 'vehicles' &&
                                    vehicles.team_vehicles_next &&
                                    lastClickedPageVeh ===
                                        Math.ceil(vehicles.teamVehicle.length / 10) && (
                                        <Button
                                            style={{
                                                position: 'absolute',
                                                bottom: '8px',
                                                right: '34px',
                                                borderRadius: '8px',
                                                background: '#f9fafb',
                                                color: '#4e5462',
                                            }}
                                            onClick={() => {
                                                setCurrentPageNumber(currentPageNumber + 1)
                                            }}
                                        >
                                            Load more (
                                            {vehicles.team_vehicles_count -
                                                vehicles.teamVehicle.length}
                                            )
                                        </Button>
                                    )}

                               

                                {activeTab === 'devices' ? (
                                    devices.length > 0 ? (
                                        <AgGridReact
                                            ref={gridRef}
                                            classes="ag-header-group-cell"
                                            keyField="uuid"
                                            rowData={devicesList}
                                            columnDefs={visibleDeviceColumns || devicesColumns}
                                            defaultColDef={defaultColDef}
                                            onGridReady={() =>
                                                gridRef.current.api?.sizeColumnsToFit()
                                            }
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationPageSizeSelector={[10, 20, 50, 100]}
                                        />
                                    ) : (
                                        <h5 className="p-4">There are currently no devices.</h5>
                                    )
                                ) : null}
                            </div>
            </Card>
        )
})

export default Vehicles
