import React from 'react'
import { Formik } from 'formik'
import {
    Button,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
} from 'reactstrap'

const ColumnEditor = ({ dropdownOpen, toggle, list, setList, activeTab }) => {
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle
                outline
                id="editId"
                className="filter-button-org mx-2   "
                style={{ height: '40px', backgroundColor: 'white' }}
            >
                Edit columns
            </DropdownToggle>
            <DropdownMenu
                className="shadow-sm rounded p-4"
                style={{ maxHeight: '600px', overflowY: 'auto' }}
            >
                <Formik
                    initialValues={list}
                    onSubmit={(values) => {
                        if (activeTab === 'vehicles') {
                            window.localStorage.setItem(
                                'columns-vehicles',
                                values.columns ?  JSON.stringify(values.columns) : []
                            )
                        }

                        if (activeTab === 'devices') {
                            window.localStorage.setItem(
                                'columns-devices',
                                JSON.stringify(values.columns)
                            )
                        }

                        toggle()
                    }}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    gap: '1rem',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                }}
                            >
                                {values.map((item, index) => (
                                    <FormGroup
                                        check
                                        key={index}
                                        className="d-flex align-items-start"
                                    >
                                        <Input
                                            key={item.field}
                                            type="checkbox"
                                            name="columns"
                                            checked={!item.hide}
                                            id={item.field}
                                            value={item.headerName}
                                            onChange={(e) => {
                                                const { checked, id } = e.target
                                                const newList = [...values]
                                                const index = newList.findIndex(
                                                    (item) => item.field === id
                                                )
                                                newList[index].hide = !checked
                                                setList(newList)
                                                setFieldValue('columns', newList)
                                            }}
                                        />
                                        <Label for={item.field} check>
                                            {item.headerName}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </div>
                            <hr className="w-100" />
                            <div className="d-flex">
                                <Button
                                    className="cancel-btn-modal modals-new-btns"
                                    onClick={() => {
                                        toggle()
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    style={{ marginLeft: '12px' }}
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DropdownMenu>
        </Dropdown>
    )
}

export default ColumnEditor
