import React, { useState } from 'react'
import useWebSocket from 'react-use-websocket'

const NotificationsWebSocket = ({ token, toggle }) => {
    const socketUrl = `wss://development.meilirobots.com/notifications/ws?token=${token}`
    const [lastMessage, setLastMessage] = useState(null)

    const styles = {
        messageContainer: {
            backgroundColor: '#670D95',
            border: '2px solid #FFF',
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            marginLeft: '-10px',
            marginTop: '14px',
            cursor: 'pointer',
        },
    }

    useWebSocket(socketUrl, {
        onOpen: () => {
            setLastMessage(null)
        },
        onMessage: (event) => {
            if (event && event.data) {
                setLastMessage(event.data) // Store only the last received message
            }
        },
        onError: (event) => {
            console.error('WebSocket error:', event)
        },
        shouldReconnect: (closeEvent) => true, // Auto-reconnect on connection loss
    })

    if (lastMessage && lastMessage !== ' ')
        return <div style={styles.messageContainer} onClick={toggle} />
}

export default NotificationsWebSocket
