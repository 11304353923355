import React from 'react'
import {
    Button,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    UncontrolledTooltip,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'
import { renderDifferentActions } from 'components/dashboard-components/MapComponents/MapEditorHelpers'
import { useDispatch } from 'react-redux'
import { getAreaMapSettings, updateAreaMapSettings } from 'features/dashboard/Map.actionTypes'

const EyeShow = ({
    dropdownOpen,
    toggle,
    areaUuid,
    icon,
    buttonState,
    place,
    handleButtonEvent,
    setIcon,
    hidePaths,
    setHidePaths,
    eyeGrid,
    vehicle,
    grid,
    showTagZonesNames,
    setShowTagZonesNames,
    showVehiclesNames,
    setShowVehiclesNames,
}) => {
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const data = {
            show_paths: !hidePaths,
            show_stations_names: icon,
            show_tag_zones_names: showTagZonesNames,
            show_vehicles_names: showVehiclesNames,
        }

        dispatch(updateAreaMapSettings({ uuid: areaUuid, data })).then(({ error }) => {
            if (!error) {
                dispatch(getAreaMapSettings({ uuid: areaUuid }))
                toggle()
            }
        })
    }

    const renderFilters = () => {
        switch (place) {
            case 'minimap':
            case 'map_layer':
                return (
                    <div>
                        {!vehicle?.every((v) => v === false) && place === 'minimap' && (
                            <FormGroup check className="mb-2">
                                <Input
                                    type="checkbox"
                                    id="vehicleNames"
                                    checked={showVehiclesNames}
                                    onChange={() => setShowVehiclesNames(!showVehiclesNames)}
                                />
                                <Label check className="ml-1" for="vehicleNames">
                                    Vehicle names
                                </Label>
                            </FormGroup>
                        )}
                        <FormGroup check className="mb-2">
                            <Input
                                type="checkbox"
                                id="stationNames"
                                checked={icon}
                                onChange={() => setIcon(!icon)}
                            />
                            <Label check className="ml-1" for="stationNames">
                                Station names
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mb-2">
                            <Input
                                type="checkbox"
                                id="hidePaths"
                                label="Paths"
                                checked={!hidePaths}
                                onChange={() => setHidePaths(!hidePaths)}
                            />
                            <Label check className="ml-1" for="hidePaths">
                                Paths
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mb-2">
                            <Input
                                type="checkbox"
                                id="tagZones"
                                checked={showTagZonesNames}
                                onChange={() => setShowTagZonesNames(!showTagZonesNames)}
                            />
                            <Label check className="ml-1" for="tagZones">
                                Tag zone names
                            </Label>
                        </FormGroup>
                    </div>
                )
            default:
                return (
                    <>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id="stationNames"
                                checked={grid}
                                onChange={eyeGrid}
                            />
                            <Label check>Grid</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id="hidePaths"
                                label="Paths"
                                checked={!hidePaths}
                                onChange={setHidePaths}
                            />
                            <Label check>Paths</Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id="tagZones"
                                checked={showTagZonesNames}
                                onChange={setShowTagZonesNames}
                            />
                            <Label check>Tag zone names</Label>
                        </FormGroup>
                    </>
                )
        }
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle
                outline
                size="sm"
                className="p-0 iconBoader"
                id="icon"
                onMouseEnter={handleButtonEvent({ button: 'isEyeHovered', value: true })}
                onMouseLeave={handleButtonEvent({ button: 'isEyeHovered', value: false })}
            >
                {dropdownOpen
                    ? renderDifferentActions(
                          buttonState.isEyeHovered,
                          '/svgs/map-editor/eye-on-hover.svg',
                          dropdownOpen,
                          '/svgs/map-editor/eye-on-clicked.svg',
                          '/svgs/map-editor/eye-on.svg',
                          'eye'
                      )
                    : renderDifferentActions(
                          buttonState.isEyeHovered,
                          '/svgs/map-editor/eye-off-hover.svg',
                          dropdownOpen,
                          '/svgs/map-editor/eye-off-clicked.svg',
                          '/svgs/map-editor/eye-off.svg',
                          'eye'
                      )}
                <UncontrolledTooltip
                    placement="auto"
                    target="icon"
                    className="tooltip-style"
                    style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                >
                    Show/Hide
                </UncontrolledTooltip>
            </DropdownToggle>
            <DropdownMenu className="shadow-sm rounded p-4" style={{ width: '16rem' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        justifyContent: 'center',
                        alignItems: 'start',
                    }}
                >
                    {renderFilters()}
                </div>
                <hr className="w-100" />
                <div
                    className={`d-flex align-items-center ${
                        place === 'minimap' ? 'justify-content-between' : 'justify-content-center'
                    } `}
                >
                    <Button
                        className={`modals-new-btns ${
                            place === 'minimap' ? 'cancel-btn-modal' : 'save-btn-modal w-100'
                        } `}
                        onClick={toggle}
                    >
                        Close window
                    </Button>
                    {place === 'minimap' && (
                        <Button className="save-btn-modal modals-new-btns" onClick={handleSubmit}>
                            Save
                        </Button>
                    )}
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}

export default EyeShow
