import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import FAQs from './faq'
import Contact from './Contact'
import { TITLE } from 'config'

const Support = () => {
    const [isActive, setIsActive] = useState('faq')


    useEffect(() => {
        document.title = `${TITLE} - Support`
    }, [])


    return (
        <div>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Support</h3>
            </div>
            <ButtonGroup
                className="org-button-group mt-4"
                style={{ width: '25rem', padding: '3px' }}
            >
                <Button
                    className={`${
                        isActive === 'faq' ? 'org-button-selected' : ''
                    } org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                    onClick={() => setIsActive('faq')}
                >
                    FAQs
                </Button>
                <Button
                    className={`${
                        isActive === 'doc' ? 'org-button-selected' : ''
                    } org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                >
                    <a className="org-button-links" href="https://docs.meilirobots.com/">
                        Documentation
                    </a>
                </Button>
                <Button
                    className={`${
                        isActive === 'contact' ? 'org-button-selected' : ''
                    } org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                    onClick={() => setIsActive('contact')}
                >
                    Contact us
                </Button>
            </ButtonGroup>
            {isActive === 'faq' ? (
                <FAQs setIsActive={setIsActive} />
            ) : (
                <Contact />
            )}
        </div>
    )
}

export default Support
