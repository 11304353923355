import React, { useState } from 'react'
import * as moment from 'moment'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Card, CardBody, CardHeader, Badge, Row, CardTitle } from 'reactstrap'
import { WEEKDAYS } from 'export'
import { HiOutlineClock } from 'react-icons/hi'
import { AiOutlineNumber } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { deleteScheduleTaskV2 } from 'features/dashboard/Task.actionTypes'
import TaskModalV2 from './TaskModalV2'

dayjs.extend(relativeTime)

const ScheduleTaskDetailV2 = ({ task, slug, setTaskHovered }) => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleFail = ({ uuid }) => dispatch(deleteScheduleTaskV2({ uuid }))

    const canNotCancelTask = () => {
        return (
            task.status === 'failed' ||
            task.status === 'terminated' ||
            task.status === 'aborted' ||
            task.status === 'completed'
        )
    }

    const canCancelTask = () => !canNotCancelTask()

    var dateTime = moment(task?.date + ' ' + task?.time, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DD HH:mm'
    )

    const today = moment().weekday()
    const weekNum = task?.week_days?.map((week) => week + 1)
    var weekDate = ''
    if (weekNum?.length > 1) {
        if (weekNum[0] >= today) {
            weekDate = moment(
                moment().day(weekNum[0]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[1] >= today) {
            weekDate = moment(
                moment().day(weekNum[1]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[2] >= today) {
            weekDate = moment(
                moment().day(weekNum[2]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[3] >= today) {
            weekDate = moment(
                moment().day(weekNum[2]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[4] >= today) {
            weekDate = moment(
                moment().day(weekNum[2]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[5] >= today) {
            weekDate = moment(
                moment().day(weekNum[2]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else if (weekNum[6] >= today) {
            weekDate = moment(
                moment().day(weekNum[2]).format('YYYY-MM-DD') + ' ' + task.time,
                'YYYY-MM-DD HH:mm'
            ).format('YYYY-MM-DD HH:mm')
        } else {
            weekDate = moment().add(1, 'weeks').day(weekNum[0])
        }
    } else {
        weekNum >= today
            ? (weekDate = moment(
                  moment().day(weekNum).format('YYYY-MM-DD') + ' ' + task.time,
                  'YYYY-MM-DD HH:mm'
              ).format('YYYY-MM-DD HH:mm'))
            : (weekDate = moment().add(1, 'weeks').day(weekNum))
    }
    return (
        <>
            {task.enabled && (
                <Card className="border text-left text-dark card-size mb-3 p-2 overflow-hidden shadow-sm">
                    <CardHeader
                        className="p-2 m-0 d-flex align-items-center"
                        style={{ borderBottom: '1px solid #E7E9EC' }}
                        onMouseEnter={() => setTaskHovered(task.vehicle?.verbose_name)}
                        onMouseLeave={() => setTaskHovered('')}
                    >
                        <strong
                            id="check-number"
                            className="w-75 overflow-hidden"
                            style={{
                                textOverflow: 'ellipsis',
                                height: '1.2rem',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {task.name}
                        </strong>
                        <BsInfoCircle
                            className="font-size-lg ml-auto rounded-circle"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                toggle()
                            }}
                        ></BsInfoCircle>
                    </CardHeader>
                    <CardTitle className="card-fontsize-title">
                        <div className="d-flex justify-content-between time-and-number">
                            <span
                                className="text-black title-time d-flex align-items-center"
                                id="id-for-slide"
                            >
                                <HiOutlineClock className="mr-1"></HiOutlineClock>
                                {task.date_tz && task.date_tz.toLocaleString()}
                                {task.week_days_tz &&
                                    task.week_days_tz
                                        .map((dayIndex) => WEEKDAYS[dayIndex].short)
                                        .join('-')}{' '}
                                {task.time_tz}
                            </span>

                            <Badge
                                color="neutral-primary"
                                className="text-black d-flex align-items-center"
                            >
                                {' '}
                                <AiOutlineNumber /> : {task.task_preset.subtasks.length}
                            </Badge>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p className="text-black">
                                <Badge color="neutral-primary" id="id-for-slide">
                                    {task.execute_every
                                        ? `Every ${task.execute_every} min ( from ${
                                              task.active_from_tz ? task.active_from_tz : ''
                                          } ${task.active_to_tz ? `to ${task.active_to_tz}` : ''} )`
                                        : task.week_days
                                        ? moment.utc(weekDate).fromNow()
                                        : moment.utc(dateTime).fromNow()}
                                </Badge>
                            </p>
                        </div>
                    </CardTitle>
                    <CardBody className="px-3 py-2 mt-2 add-scroll">
                        <Row>
                            <ul
                                className="text-black"
                                style={{
                                    listStylePosition: 'inside',
                                }}
                            >
                                {task.task_preset.subtasks.map(({ action, uuid }, index) => (
                                    <div key={uuid}>
                                        <span className="text-dark d-flex justify-content-start align-items-center">
                                            <span className="mr-2">{index + 1}</span>
                                            {action.definition_information.displayable_name}
                                        </span>
                                    </div>
                                ))}
                            </ul>
                        </Row>
                    </CardBody>
                </Card>
            )}

            <TaskModalV2
                modal={modal}
                toggle={toggle}
                handleFail={handleFail}
                canCancelTask={canCancelTask}
                task={task}
            />
        </>
    )
}

export default ScheduleTaskDetailV2
