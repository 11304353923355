export const layouts = {
    layout1: {
        // Preset 1
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 12, h: 3.7 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 10, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 10, h: 3.7 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.7 },
        ],
    },
    layout2: {
        // Preset 2
        lg: [
            { i: 'missions', x: 0, y: 0, w: 5.2, h: 5.2 },
            { i: 'map', x: 5.2, y: 0, w: 6.8, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 5.2 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 5.2 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout3: {
        // Preset 3
        lg: [
            { i: 'missions', x: 6.8, y: 0, w: 5.2, h: 5.2 },
            { i: 'map', x: 0, y: 0, w: 6.8, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 5.2, w: 8, h: 5.2 },
            { i: 'map', x: 0, y: 0, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 5.2, w: 6, h: 5.2 },
            { i: 'map', x: 0, y: 0, w: 6, h: 5.2 },
        ],
    },
    layout4: {
        // Preset 5
        lg: [
            { i: 'missions', x: 0, y: 0, w: 5, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 5, h: 3.7 },
            { i: 'map', x: 7, y: 0, w: 7, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout5: {
        // Preset 5
        lg: [
            { i: 'missions', x: 7, y: 0, w: 5, h: 1.5 },
            { i: 'vehicles', x: 7, y: 1.5, w: 5, h: 3.7 },
            { i: 'map', x: 0, y: 0, w: 7, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout6: {
        // Preset 6
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'vehicles', x: 8, y: 1.5, w: 4, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 8, h: 3.7 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 10, h: 1.5 },
            { i: 'vehicles', x: 6, y: 1.5, w: 4, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.7 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 5.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.7 },
        ],
    },
    layout7: {
        // Preset 7
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'vehicles', x: 0, y: 5, w: 4, h: 3.7 },
            { i: 'map', x: 8, y: 5, w: 8, h: 3.7 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 6.7, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 6.7, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 5.2 },
        ],
    },
    layout8: {
        // Preset 8
        lg: [
            { i: 'missions', x: 0, y: 3.7, w: 5, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 5, h: 3.7 },
            { i: 'map', x: 7, y: 0, w: 7, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 3.7, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 3.7, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout9: {
        // Preset 9
        lg: [
            { i: 'missions', x: 7, y: 3.7, w: 5, h: 1.5 },
            { i: 'vehicles', x: 7, y: 0, w: 5, h: 3.7 },
            { i: 'map', x: 0, y: 0, w: 7, h: 5.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 3.7, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 3.7, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
}

export const layoutsSmall = {
    layout1: {
        // Preset 1
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 12, h: 3.2 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 10, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 10, h: 3.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.2 },
        ],
    },
    layout2: {
        // Preset 2
        lg: [
            { i: 'missions', x: 0, y: 0, w: 7, h: 4.1 },
            { i: 'map', x: 7, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 5 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 5 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5 },
        ],
    },
    layout3: {
        // Preset 3
        lg: [
            { i: 'missions', x: 5, y: 0, w: 7, h: 4.1 },
            { i: 'map', x: 0, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 5.2, w: 8, h: 5.2 },
            { i: 'map', x: 0, y: 0, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 5.2, w: 6, h: 5.2 },
            { i: 'map', x: 0, y: 0, w: 6, h: 5.2 },
        ],
    },
    layout4: {
        // Preset 4
        lg: [
            { i: 'missions', x: 0, y: 0, w: 7, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 7, h: 2.6 },
            { i: 'map', x: 7, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout5: {
        // Preset 5
        lg: [
            { i: 'missions', x: 5, y: 0, w: 7, h: 1.5 },
            { i: 'vehicles', x: 5, y: 1.5, w: 7, h: 2.6 },
            { i: 'map', x: 0, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 1.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout6: {
        // Preset 6
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'vehicles', x: 8, y: 1.5, w: 4, h: 2.6 },
            { i: 'map', x: 0, y: 1.5, w: 8, h: 2.6 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 10, h: 1.5 },
            { i: 'vehicles', x: 6, y: 1.5, w: 4, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.7 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 5.5, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 3.7 },
        ],
    },
    layout7: {
        // Preset 7
        lg: [
            { i: 'missions', x: 0, y: 0, w: 12, h: 1.5 },
            { i: 'vehicles', x: 0, y: 5, w: 4, h: 2.6 },
            { i: 'map', x: 8, y: 5, w: 8, h: 2.6 },
        ],
        md: [
            { i: 'missions', x: 0, y: 0, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 6.7, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 0, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 6.7, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 1.5, w: 6, h: 5.2 },
        ],
    },
    layout8: {
        // Preset 8
        lg: [
            { i: 'missions', x: 0, y: 3.7, w: 7, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 7, h: 2.6 },
            { i: 'map', x: 7, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 3.7, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 8, h: 2.6 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 4.1 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 3.7, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
    layout9: {
        // Preset 9
        lg: [
            { i: 'missions', x: 5, y: 3.7, w: 7, h: 1.5 },
            { i: 'vehicles', x: 5, y: 0, w: 7, h: 2.6 },
            { i: 'map', x: 0, y: 0, w: 5, h: 4.1 },
        ],
        md: [
            { i: 'missions', x: 0, y: 3.7, w: 8, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 8, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 8, h: 5.2 },
        ],
        sm: [
            { i: 'missions', x: 0, y: 3.7, w: 6, h: 1.5 },
            { i: 'vehicles', x: 0, y: 0, w: 6, h: 3.7 },
            { i: 'map', x: 0, y: 5.2, w: 6, h: 5.2 },
        ],
    },
}


export const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
export const cols = { lg: 12, md: 10, sm: 8, xs: 4, xxs: 2 }