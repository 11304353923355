import React from 'react'
import { Arc, Arrow } from 'react-konva'

const EstimatedPoint = ({ x, y, currentPos }) => {
    return (
        <>
            <Arrow
                stroke="#670d95"
                strokeWidth={2}
                tension={0.1}
                points={currentPos}
                pointerLength={3}
                pointerWidth={5}
                pointerAtBeginning={false}
            />
            <Arc angle={360} outerRadius={3} x={x} y={y} fill="#D99EF7" />
        </>
    )
}

export default EstimatedPoint
