import React, { useEffect, useState } from 'react'
import { ListGroup, ListGroupItem, UncontrolledPopover, Spinner } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getTeams } from 'features/dashboard/Team.actionTypes'
import { selectors } from 'features/dashboard/'
import { getProfile } from 'features/user/actionTypes'
import { startAndStopSimulation } from 'middleware/api'
import { getSetups } from 'features/dashboard/RosSetup.actionTypes'
import useUnload from 'components/dashboard-components/MapComponents/useUnload'

const UserDashboardMenu = ({ history, match, location, organizationSide }) => {
    const dispatch = useDispatch()
    const team = useSelector(selectors.getTeam)
    const user = useSelector(selectors.getUser)
    const setupStore = useSelector(selectors.getSetup)
    const [isSubmitting, setSubmitting] = useState(false)
    const simulationStatus = localStorage.getItem('simulationStatus')
    const version = localStorage.getItem('version')
    const { profile } = user
    let checkIfStartedInterval

    const switchToTeam = (teamSlug) => {
        const urlArray = location.pathname.split('/')
        urlArray[2] = teamSlug
        return urlArray.join('/')
    }

    useEffect(() => {
        dispatch(getTeams())
        dispatch(getProfile())
        if (!organizationSide) {
            dispatch(getSetups(team.details))
        }
        if (simulationStatus === 'starting simulation') {
            setSubmitting(true)
            if (!window.sessionStorage.getItem('isChecking')) {
                checkForSimulationStart()
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const RenderTeamMenu = () => (
        <ListGroup>
            {team.list.map((team) => (
                <ListGroupItem
                    key={team.uuid}
                    tag="button"
                    action
                    className={cx('text-left', {
                        'bg-gray-200 text-black': team.slug === match.params.slug,
                    })}
                    onClick={() => history.push(switchToTeam(team.slug))}
                >
                    {team.title}
                </ListGroupItem>
            ))}
        </ListGroup>
    )

    const RenderSetups = () => (
        <ListGroup>
            {setupStore.setups?.results?.map((setup) => (
                <ListGroupItem
                    key={setup.uuid}
                    tag="button"
                    action
                    onClick={() => startOrStopSimulation(setup.pin)}
                >
                    {setup.verbose_name}
                </ListGroupItem>
            ))}
        </ListGroup>
    )

    const startOrStopSimulation = async (pin) => {
        if (simulationStatus === 'stopped simulation' || !simulationStatus) {
            window.localStorage.setItem('simulationStatus', 'starting simulation')
            setSubmitting(true)
            if (
                window.location.href.substring(8, 12) === 'demo' ||
                window.location.href.substring(8, 12) === 'deve'
            ) {
                await startAndStopSimulation
                    .post('/simulation-orchestrator/pipelines', { pin })
                    .then((response) => {
                        window.localStorage.setItem('id', response.data.id)
                    })
                    .catch((err) => console.log(err))
            }

            checkForSimulationStart()

            return
        }

        if (simulationStatus !== 'stopped simulation') {
            window.localStorage.setItem('simulationStatus', 'stopped simulation')
            setSubmitting(true)
            await startAndStopSimulation
                .get(`/simulation-orchestrator/pipelines/${window.localStorage.getItem('id')}/jobs`)
                .then(async (response) => {
                    const result = response.data.jobs.find(
                        (obj) => obj.name === 'destroy_simulation_ros'
                    )
                    if (result) {
                        await startAndStopSimulation
                            .put(`/simulation-orchestrator/jobs/${result.id}/play`, {
                                pin: pin,
                            })
                            .then(() => {
                                setSubmitting(false)
                                window.localStorage.removeItem('id')
                            })
                            .catch((err) => console.log(err))
                    }
                })
            return
        }
    }

    useUnload(() => {
        window.sessionStorage.removeItem('isChecking')
    })

    const checkForSimulationStart = () => {
        window.sessionStorage.setItem('isChecking', true)

        checkIfStartedInterval = setInterval(async () => {
            const { data } = await startAndStopSimulation.get(
                `/simulation-orchestrator/pipelines/${window.localStorage.getItem('id')}/jobs`
            )
            const result = data.jobs.find((obj) => obj.name === 'apply_simulation_ros')

            if (result.finished_at !== '') {
                window.localStorage.setItem('simulationStatus', 'simulation started')
                setSubmitting(false)
                window.sessionStorage.removeItem('isChecking')
                clearInterval(checkIfStartedInterval)
                return
            }
        }, 2000)
    }
    return (
        <>
            <ListGroup>
                <ListGroupItem
                    tag="button"
                    action
                    className="text-left"
                    onClick={() => {
                        if (!organizationSide) {
                            history.push(`/dashboard/${match.params.slug}/settings/`)
                        } else {
                            history.push('/dashboard/')
                        }
                    }}
                >
                    <b>{`${profile.first_name} ${profile.last_name} `}</b>
                    <br />
                    <small>{profile.email}</small>
                    <br />
                    {!organizationSide && <span>Profile & preferences</span>}
                </ListGroupItem>
                <ListGroupItem
                    tag="button"
                    action
                    className="text-left"
                    onClick={() => {
                        history.push('/dashboard/organization/release_notes/')
                    }}
                >
                    <span className="version-text">Meili FMS version {version}</span>
                </ListGroupItem>
                <ListGroupItem
                    tag="button"
                    action
                    className="text-left"
                    onClick={() =>
                        history.push({
                            pathname: `/dashboard/organization/teams/${team.details.slug}/`,
                            state: { slug: team.details?.slug, team: team.details },
                        })
                    }
                >
                    Team site settings
                </ListGroupItem>
                <ListGroupItem tag="button" action className="text-left" id="teamsList">
                    My teams <FontAwesomeIcon className="mt-1 pull-right" icon={faChevronRight} />
                </ListGroupItem>
                <ListGroupItem tag="button" action disabled className=" text-left">
                    Account & billing
                </ListGroupItem>
                {profile.is_staff &&
                    !organizationSide &&
                    (window.location.href.substring(8, 12) === 'demo' ||
                        window.location.href.substring(8, 12) === 'deve') && (
                        <>
                            <ListGroupItem
                                id="setupsList"
                                tag="button"
                                action
                                onClick={() => {
                                    if (
                                        simulationStatus === 'simulation started' &&
                                        !isSubmitting
                                    ) {
                                        startOrStopSimulation()
                                    }
                                }}
                                className="text-left"
                                disabled={isSubmitting}
                            >
                                {(simulationStatus === 'stopped simulation' || !simulationStatus) &&
                                    !isSubmitting && (
                                        <>
                                            Start simulation
                                            <FontAwesomeIcon
                                                className="mt-1 pull-right"
                                                icon={faChevronRight}
                                            />
                                        </>
                                    )}

                                {simulationStatus === 'starting simulation' &&
                                    'Starting simulation'}

                                {simulationStatus === 'simulation started' &&
                                    !isSubmitting &&
                                    'Stop simulation'}
                                {simulationStatus === 'stopped simulation' &&
                                    isSubmitting &&
                                    'Stopping simulation'}
                                {isSubmitting && <Spinner size="sm" color="#670d95 ml-2" />}
                            </ListGroupItem>
                            <ListGroupItem tag="a" href="/admin/" action className=" text-left">
                                Admin console
                            </ListGroupItem>
                        </>
                    )}
                <ListGroupItem
                    tag="button"
                    action
                    onClick={() => history.push('/logout')}
                    className=" text-left"
                >
                    Log out
                </ListGroupItem>
            </ListGroup>
            <UncontrolledPopover
                placement="auto"
                trigger="legacy"
                popperClassName="rounded border-0"
                target="teamsList"
            >
                <RenderTeamMenu />
            </UncontrolledPopover>
            {profile.is_staff &&
                !organizationSide &&
                (window.location.href.substring(8, 12) === 'demo' ||
                    window.location.href.substring(8, 12) === 'deve') && (
                    <UncontrolledPopover
                        placement="auto"
                        trigger="legacy"
                        popperClassName="rounded border-0"
                        target="setupsList"
                    >
                        <RenderSetups />
                    </UncontrolledPopover>
                )}
        </>
    )
}

export default withRouter(UserDashboardMenu)
