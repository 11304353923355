import React, { useState } from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import axios from 'axios'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { selectors } from 'features/dashboard'
import FileUploaderBridge from './FileUploaderBridge'
import ProgressUploading from './ProgressUploading'
import { fileUpload } from 'middleware/api'
import { getAnalysisStatus } from 'features/dashboard/Map.actionTypes'
import MiniNavbar from './MapEditorNavbar'
import { deleteMapSource } from 'db'
import { setMessages } from 'features/generic/Message.actionTypes'

const MapUpload = (state) => {
    const [image, setImage] = useState(null)
    const [yaml, setYaml] = useState(null)
    const [imageSuccess, setImageSuccess] = useState(false)
    const [yamlSuccess, setYamlSuccess] = useState(false)
    const [progress, setProgress] = useState(0)
    const [onStartUploading, setOnStartUploading] = useState(false)
    const [isUploaded, setIsUploaded] = useState(false)

    const isActive = 'map-upload'

    const map = state.map
    const team = state.team
    const areaUuid = team.teamMap.areas.uuid
    const dispatch = useDispatch()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    let interval = 0

    // const errorText = (text) => {
    //     const msg = JSON.stringify(text).replace(/[\[\]{}":.]+[\This/&{}":.]+/g, '') // eslint-disable-line
    //     const capitalized = msg.charAt(0).toUpperCase() + msg.slice(1)
    //     return capitalized
    // }

    const handleUpload = async (image, yaml, e) => {
        e.preventDefault()
        const formData = new FormData()
        const imagePath = map?.areaDetails?.image
        const extracted = imagePath?.match(/\/areas\/(\d+)-/)[1]
        await deleteMapSource(`gen-svg-${extracted}-${areaUuid}`)
        formData.append('image', image)
        formData.append('configuration_file', yaml)

        await fileUpload
            .post(`/api/indoors/area/${areaUuid}/upload/`, formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100
                    setProgress(progress)
                },
                cancelToken: source.token,
            })
            .then(() => {
                setImageSuccess(false)
                setYamlSuccess(false)

                interval = setInterval(() => {
                    dispatch(getAnalysisStatus(areaUuid)).then((res) => {
                        if (res.payload.data.map_analysis_done === true) {
                            setTimeout(() => {
                                setOnStartUploading(false)
                                clearInterval(interval)
                                setIsUploaded(true)
                                dispatch(
                                    setMessages([
                                        {
                                            type: 'success',
                                            text: 'Success',
                                        },
                                    ])
                                )
                            }, [1000])
                        }
                    })
                }, [1000])
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    setOnStartUploading(false)
                    setImageSuccess(false)
                    setYamlSuccess(false)
                }
                if (err.response.status === 400 && err.response.data.configuration_file) {
                    setOnStartUploading(false)
                    setImageSuccess(false)
                    setYamlSuccess(false)
                }
                setOnStartUploading(false)
                setImageSuccess(false)
                setYamlSuccess(false)
                dispatch(
                    setMessages([
                        {
                            type: 'danger',
                            text: err.response.data.detail,
                        },
                    ])
                )
            })
    }

    const renderContent = () => {
        switch (true) {
            case onStartUploading:
                return (
                    <ProgressUploading
                        progress={progress}
                        image={image}
                        yaml={yaml}
                        cancelSource={source}
                    />
                )
            case isUploaded:
                return <Redirect to={`/dashboard/${team?.details.slug}/maps/`} />
            default:
                return (
                    <>
                        <FileUploaderBridge
                            success={imageSuccess}
                            setSuccess={setImageSuccess}
                            setFile={setImage}
                            fileType={'image'}
                        />
                        <FileUploaderBridge
                            success={yamlSuccess}
                            setSuccess={setYamlSuccess}
                            setFile={setYaml}
                            fileType={'YAML file'}
                        />
                    </>
                )
        }
    }

    const renderButtons = () => {
        switch (true) {
            case imageSuccess && yamlSuccess:
                return (
                    <div className="d-flex flex-column flex-md-row w-100 align-items-center justify-content-center">
                        <Button
                            onClick={(e) => {
                                setOnStartUploading(true)
                                if (image && yaml)
                                    setTimeout(() => {
                                        handleUpload(image, yaml, e)
                                    }, [500])
                            }}
                            className="rounded-pill mb-4 p-2 w-25 bg-success text-center text-white"
                        >
                            Upload
                        </Button>
                        <Link
                            to={`/dashboard/${team?.details.slug}/maps/`}
                            className="rounded-pill mb-4 ml-2 p-2 w-25 bg-meili text-center text-white"
                        >
                            Go back
                        </Link>
                    </div>
                )
            case (!imageSuccess && yamlSuccess) || (imageSuccess && !yamlSuccess):
                return (
                    <Link
                        to={`/dashboard/${team?.details.slug}/maps/`}
                        className="rounded-pill mb-4 ml-2 p-2 w-25 bg-meili text-center text-white"
                    >
                        Go back
                    </Link>
                )
            default:
                return
        }
    }

    return (
        <>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Maps</h3>
            </div>
            <div
                className="d-flex flex-column justify-content-start align-items-start"
                style={{ marginTop: '32px' }}
            >
                <MiniNavbar
                    slug={team.details.slug}
                    isActive={isActive}
                    status={!team.teamMap.areaDetails.image}
                />
                <Card className="w-100 card-box shadow-none border-0">
                    <CardBody className="d-flex w-100 h-100 justify-content-center align-items-center p-5">
                        <div className="d-flex flex-column w-100 h-100 justify-content-center pb-3 align-items-center shadow-sm rounded overflow-hidden">
                            <div className="d-flex w-100 align-items-center justify-content-start bg-gray-300 p-3">
                                Upload map
                            </div>
                            <div className="d-flex flex-column flex-md-row w-100 align-items-center justify-content-center p-5">
                                {renderContent()}
                            </div>
                            {renderButtons()}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        team: selectors.getTeam(state),
        map: selectors.getTeamMap(state),
        mapAnalysisData: selectors.getTeamMap(state).MapAnalysisStatus,
    }
}

export default compose(withRouter, connect(mapStateToProps))(MapUpload)
