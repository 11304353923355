import React, { memo, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { TITLE, API_ROOT } from 'config'
import useWebSocket from 'react-use-websocket'
import { WidthProvider, Responsive } from 'react-grid-layout'
import { Button } from 'reactstrap'
import { getTeamAnalytics } from 'features/dashboard/TeamAnalytics.actionTypes'
import { getDevices, updateDevicesFromWebhook } from 'features/dashboard/RosSetup.actionTypes'
import {
    getTeamVehicles,
    getVehicleCategories,
    updateVehiclesFromWebkook,
} from 'features/dashboard/Vehicle.actionTypes'
import { getWSToken } from 'features/user/actionTypes'
import {
    getActionsPresets,
    getPresetTasksV2,
    getTaskDefinitions,
    updateTaskFromWebhookV2,
} from 'features/dashboard/Task.actionTypes'
import { getScheduleTasksV2, getTasksV2 } from 'features/dashboard/Task.actionTypes'
import { buildWebsocketURL } from 'utils'
import { selectors } from 'features/dashboard'
import { Loading } from 'ui/common'
import Vehicles from './Vehicles'
import Missions from './Missions'
import Map from './Map'
import { getMapSource, saveMapSource } from 'db'
import {
    getAreaMapSettings,
    getAreaZonesAll,
    getMapAreaDetails,
    getMapAreaOfInterest,
    getMapStations,
    getZonesPaths,
} from 'features/dashboard/Map.actionTypes'
import { fileClient } from 'middleware/api'
import CustomPageModals from './Modals'
import { breakpoints, cols, layouts, layoutsSmall } from './layouts'

const CustomPage = memo(({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const taskStore = useSelector(selectors.getTask)
    // const vehicles = useSelector(selectors.getVehicles)
    const whitedevDashboard = useSelector(selectors.getWhitedevDashboard)
    const [currentPresetPage, setCurrentPresetPage] = useState(1)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [currentSocketUrl, setCurrentSocketUrl] = useState(null)
    const [robot, setRobot] = useState(null)
    const [cancelTask, setCancelTask] = useState(false)
    const [status, setStatus] = useState('loading')
    const [taskHovered, setTaskHovered] = useState('')
    const [image, setImage] = useState(null)
    const [messageReceived, setMessageReceived] = useState(false)
    const [currentLayout, setCurrentLayout] = useState(
        localStorage.getItem('currentLayout') || 'layout1'
    )
    const [icon, setIcon] = useState(null)
    const [modal, setModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [parentStatus, setParentStatus] = useState('loading')
    const toggle = useMemo(() => {
        return () => setModal((prevModal) => !prevModal)
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    const ResponsiveGridLayout = useMemo(
        () => WidthProvider(Responsive),
        [currentLayout] // eslint-disable-line react-hooks/exhaustive-deps
    )
    const currentLayoutMemo = useMemo(
        () =>
            window.innerHeight + window.innerWidth < 2500
                ? layoutsSmall[currentLayout]
                : layouts[currentLayout],
        [currentLayout]
    )

    const { uuid } = map?.areas || {}

    const renderDashboard =
        whitedevDashboard.data.influx_gauges?.length !== undefined ? true : false

    const { lastJsonMessage } = useWebSocket(currentSocketUrl, {
        retryOnError: true,
    })

    useEffect(() => {
        document.title = `${TITLE} - Custom missions`
    }, [])

    useEffect(() => {
        if (renderDashboard) dispatch(getTeamAnalytics({ slug }))
    }, [slug, dispatch, renderDashboard])

    useEffect(() => {
        dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    const getSocketUrl = () => () => {
        return new Promise((resolve, reject) => {
            dispatch(getWSToken({ slug })).then(({ error, payload }) => {
                if (error) return reject('something went wrong on websocket')
                resolve(buildWebsocketURL(API_ROOT, payload.data.token))
            })
        })
    }

    useEffect(() => {
        setParentStatus('loading')
        Promise.all([
            dispatch(getMapAreaDetails(uuid)),
            dispatch(getMapAreaOfInterest(uuid)),
            dispatch(getAreaMapSettings({ uuid })),
        ]).then(([{ payload }]) => {
            if (payload) {
                setParentStatus('loaded')
                return
            }
            setParentStatus('error')
        })
    }, [uuid]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (parentStatus !== 'loaded') return
        const imagePath = map?.areaDetails?.image;
        const extracted = imagePath?.match(/\/areas\/(\d+)-/)[1];
        const localStorageKey = `gen-svg-${extracted}-${uuid}`

        // Helper function to update the image and state
        const setImageAndState = (imageSrc) => {
            const image = new window.Image()
            image.crossOrigin = 'Anonymous'
            image.src = imageSrc
            setImage(image)
            setStatus('loaded')
        }

        // Async function to handle data fetching and caching
        const loadImageData = async () => {
            try {
                // Check if the image is cached in IndexedDB
                const cachedImage = await getMapSource(localStorageKey)

                if (cachedImage) {
                    Promise.all([
                        dispatch(getZonesPaths(uuid)),
                        dispatch(getMapStations({ uuid })),
                        dispatch(getDevices({ slug })),
                        dispatch(getTasksV2(slug)),
                        dispatch(getScheduleTasksV2({ slug })),
                        dispatch(getDevices({ slug })),
                        dispatch(getAreaZonesAll(uuid)),
                        dispatch(
                            getTeamVehicles({
                                slug,
                                text: 'detailed=true',
                                page: currentPageNumber,
                            })
                        ),
                    ]).then(() => {
                        setImageAndState(cachedImage) // Use cached image if available
                    })
                    return // Exit early if cached image is used
                }

                // Fetch and cache the image if not available locally
                const [imageRes] = await Promise.all([
                    fileClient.get(`/indoors/area/${uuid}/gen.svg`),
                    dispatch(getZonesPaths(uuid)),
                    dispatch(getMapStations({ uuid })),
                    dispatch(getDevices({ slug })),
                    dispatch(getTasksV2(slug)),
                    dispatch(getScheduleTasksV2({ slug })),
                    dispatch(getDevices({ slug })),
                    dispatch(getAreaZonesAll(uuid)),
                ])

                const reader = new window.FileReader()
                reader.readAsDataURL(imageRes.data)
                reader.onload = async () => {
                    const imageSrc = reader.result
                    setImageAndState(imageSrc) // Set the fetched image
                    await saveMapSource(localStorageKey, imageSrc) // Cache the image
                }
            } catch (error) {
                console.error('Error loading image data:', error)
                setStatus('image-error')
            }
        }

        loadImageData() // Invoke the async function
    }, [slug, parentStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setModalType('')
        }
    }, [modal])

    useEffect(() => {
        if (currentPresetPage > 1) {
            dispatch(getPresetTasksV2({ slug, page: currentPresetPage }))
        }
    }, [currentPresetPage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (status === 'loaded') {
            dispatch(getActionsPresets({ slug }))
            dispatch(getTaskDefinitions(slug))
            dispatch(getPresetTasksV2({ slug, page: 1 }))
            dispatch(getVehicleCategories({ slug }))
        }
    }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!currentSocketUrl) setCurrentSocketUrl(() => getSocketUrl())
    }, [currentSocketUrl]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.task?.team.slug === slug) {
            dispatch(updateTaskFromWebhookV2(lastJsonMessage.task))
        }
        if (lastJsonMessage && lastJsonMessage.vehicles) {
            dispatch(updateVehiclesFromWebkook(lastJsonMessage.vehicles))
            setMessageReceived(true)
        }
        if (lastJsonMessage && lastJsonMessage.devices) {
            dispatch(updateDevicesFromWebhook(lastJsonMessage.devices))
            setMessageReceived(true)
        }
    }, [lastJsonMessage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const interval = setInterval(() => {
            setMessageReceived(false)
        }, 30000)

        return () => clearInterval(interval)
    }, [lastJsonMessage])

    const getRobot = (robot) => {
        setRobot(robot)
    }

    const handleLayoutChange = (layout) => {
        setCurrentLayout(layout)
        window.localStorage.setItem('currentLayout', layout)
        toggle()
    }

    const generateLayout = useMemo(() => {
        return (
            <ResponsiveGridLayout
                autoSize={true}
                isResizable={false}
                isDraggable={false}
                cols={cols}
                breakpoints={breakpoints}
                layouts={currentLayoutMemo}
            >
                <div key="missions" className="d-flex justify-content-center align-items-center">
                    <Missions
                        slug={slug}
                        robot={robot}
                        setCancelTask={setCancelTask}
                        currentLayout={currentLayout}
                        setTaskHovered={setTaskHovered}
                        lastJsonMessage={lastJsonMessage}
                    />
                </div>
                {currentLayout !== 'layout1' &&
                currentLayout !== 'layout2' &&
                currentLayout !== 'layout3' ? (
                    <div
                        key="vehicles"
                        className="d-flex justify-content-center align-items-center"
                    >
                        <Vehicles
                            renderDashboard={renderDashboard}
                            currentPageNumber={currentPageNumber}
                            setCurrentPageNumber={setCurrentPageNumber}
                            slug={slug}
                        />
                    </div>
                ) : null}
                <div key="map" className="d-flex justify-content-center align-items-center">
                    <Map
                        sidebar={false}
                        icon={icon}
                        setIcon={setIcon}
                        image={image}
                        getRobot={getRobot}
                        taskHovered={taskHovered}
                        currentLayout={currentLayout}
                        messageReceived={messageReceived}
                        currentPageNumber={currentPageNumber}
                        actions={taskStore?.taskDefinitions}
                    />
                </div>
            </ResponsiveGridLayout>
        )
    }, [
        currentLayout,
        currentPageNumber,
        taskHovered,
        messageReceived,
        lastJsonMessage,
        icon,
        image,
        robot,
        slug,
        renderDashboard,
        taskStore,
        currentLayoutMemo,
    ])

    const modals = useMemo(() => {
        return (
            <CustomPageModals
                slug={slug}
                modal={modal}
                toggle={toggle}
                setIcon={setIcon}
                layouts={layouts}
                modalType={modalType}
                setCancelTask={setCancelTask}
                currentLayout={currentLayout}
                handleLayoutChange={handleLayoutChange}
                setCurrentPresetPage={setCurrentPresetPage}
            />
        )
    }, [modal, modalType, currentLayout]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="w-100 h-100">
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Custom missions</h3>
            </div>
            <div className="w-100 mt-4 d-flex justify-content-end px-3 gap-2">
                <Button
                    type="button"
                    data-testid="cancelall"
                    disabled={!cancelTask}
                    className="cancel-btn-modal modals-new-btns"
                    style={{ maxHeight: '37px' }}
                    onClick={() => {
                        toggle()
                        setModalType('cancelall')
                    }}
                >
                    Cancel all
                </Button>
                <Button
                    data-testid="runmission"
                    type="button"
                    className="light-btn-modal modals-new-btns"
                    onClick={() => {
                        toggle()
                        setModalType('changepreset')
                    }}
                >
                    Change layout
                </Button>
                <Button
                    data-testid="runmission"
                    type="button"
                    className="save-btn-modal modals-new-btns"
                    onClick={() => {
                        toggle()
                        setModalType('runmission')
                    }}
                    disabled={localStorage.getItem('nuke') === 'stopped'}
                >
                    <img src="/svgs/team-tasks/play-icon-mission.svg" alt="play" />
                    Run mission
                </Button>
            </div>
            {status === 'loading' ? <Loading /> : generateLayout}
            {modals}
        </div>
    )
})

export default CustomPage
