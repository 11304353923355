import { ADMIN_PERMISSIONS } from 'export'

export const actionTypes = {
    GET_ORG: 'GET_ORG',
    GET_ORG_SUCCESS: 'GET_ORG_SUCCESS',
    GET_ORGS: 'GET_ORGS',
    GET_ORGS_FAIL: 'GET_ORGS_FAIL',
    GET_ORGS_SUCCESS: 'GET_ORGS_SUCCESS',
    GET_CUSTOMVAlUE: 'GET_CUSTOMVAlUE',
    GET_CUSTOMVAlUE_FAIL: 'GET_CUSTOMVAlUE_FAIL',
    GET_CUSTOMVAlUE_SUCCESS: 'GET_CUSTOMVAlUE_SUCCESS',
    GET_ORG_CATEGORIES: 'GET_ORG_CATEGORIES',
    GET_ORG_CATEGORIES_FAIL: 'GET_ORG_CATEGORIES_FAIL',
    GET_ORG_CATEGORIES_SUCCESS: 'GET_ORG_CATEGORIES_SUCCESS',
    DELETE_ORG_CATEGORY: 'DELETE_ORG_CATEGORY',
    DELETE_ORG_CATEGORY_FAIL: 'DELETE_ORG_CATEGORY_FAIL',
    DELETE_ORG_CATEGORY_SUCCESS: 'DELETE_ORG_CATEGORY_SUCCESS',
    GET_ORG_WEBHOOKS: 'GET_ORG_WEBHOOKS',
    GET_ORG_WEBHOOKS_FAIL: 'GET_ORG_WEBHOOKS_FAIL',
    GET_ORG_WEBHOOKS_SUCCESS: 'GET_ORG_WEBHOOKS_SUCCESS',
    GET_ORG_EVENTS: 'GET_ORG_EVENTS',
    GET_ORG_EVENTS_FAIL: 'GET_ORG_EVENTS_FAIL',
    GET_ORG_EVENTS_SUCCESS: 'GET_ORG_EVENTS_SUCCESS',
    DELETE_ORG_WEBHOOK: 'DELETE_ORG_WEBHOOK',
    DELETE_ORG_WEBHOOK_FAIL: 'DELETE_ORG_WEBHOOK_FAIL',
    DELETE_ORG_WEBHOOK_SUCCESS: 'DELETE_ORG_WEBHOOK_SUCCESS',
    UPDATE_ORG_WEBHOOK: 'UPDATE_ORG_WEBHOOK',
    UPDATE_ORG_WEBHOOK_FAIL: 'UPDATE_ORG_WEBHOOK_FAIL',
    UPDATE_ORG_WEBHOOK_SUCCESS: 'UPDATE_ORG_WEBHOOK_SUCCESS',
    CREATE_ORG_WEBHOOK: 'CREATE_ORG_WEBHOOK',
    CREATE_ORG_WEBHOOK_FAIL: 'CREATE_ORG_WEBHOOK_FAIL',
    CREATE_ORG_WEBHOOK_SUCCESS: 'CREATE_ORG_WEBHOOK_SUCCESS',
    UPDATE_ORG_CATEGORY: 'UPDATE_ORG_CATEGORY',
    UPDATE_ORG_CATEGORY_FAIL: 'UPDATE_ORG_CATEGORY_FAIL',
    UPDATE_ORG_CATEGORY_SUCCESS: 'UPDATE_ORG_CATEGORY_SUCCESS',
    CREATE_ORG_CATEGORY: 'CREATE_ORG_CATEGORY',
    CREATE_ORG_CATEGORY_FAIL: 'CREATE_ORG_CATEGORY_FAIL',
    CREATE_ORG_CATEGORY_SUCCESS: 'CREATE_ORG_CATEGORY_SUCCESS',
    GET_ORG_TEAMS: 'GET_ORG_TEAMS',
    GET_ORG_TEAMS_FAIL: 'GET_ORG_TEAMS_FAIL',
    GET_ORG_TEAMS_SUCCESS: 'GET_ORG_TEAMS_SUCCESS',
    CREATE_ORG: 'CREATE_ORG',
    CREATE_ORG_FAIL: 'CREATE_ORG_FAIL',
    CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',
    UPDATE_CUSTOMVALUE: 'UPDATE_CUSTOMVALUE',
    UPDATE_CUSTOMVALUE_FAIL: ' UPDATE_CUSTOMVALUE_FAIL',
    UPDATE_CUSTOMVALUE_SUCCESS: ' UPDATE_CUSTOMVALUE_SUCCESS',
    UPDATE_ORG: 'UPDATE_ORG',
    UPDATE_ORG_FAIL: 'UPDATE_ORG_FAIL',
    UPDATE_ORG_SUCCESS: 'UPDATE_ORG_SUCCESS',
    DELETE_ORG: 'DELETE_ORG',
    DELETE_ORG_FAIL: 'DELETE_ORG_FAIL',
    DELETE_ORG_SUCCESS: 'DELETE_ORG_SUCCESS',
    GET_ALL_ORG_MEMBERS: 'GET_ALL_ORG_MEMBERS',
    GET_ALL_ORG_MEMBERS_SUCCESS: 'GET_ALL_ORG_MEMBERS_SUCCESS',
    GET_ALL_ORG_MEMBERS_FAIL: 'GET_ALL_ORG_MEMBERS_FAIL',
    CREATE_ORG_MEMBER: 'CREATE_ORG_MEMBER',
    CREATE_ORG_MEMBER_FAIL: 'CREATE_ORG_MEMBER_FAIL',
    CREATE_ORG_MEMBER_SUCCESS: 'CREATE_ORG_MEMBER_SUCCESS',
    REMOVE_ORG_MEMBER: 'REMOVE_ORG_MEMBER',
    REMOVE_ORG_MEMBER_FAIL: 'REMOVE_ORG_MEMBER_FAIL',
    REMOVE_ORG_MEMBER_SUCCESS: 'REMOVE_ORG_MEMBER_SUCCESS',
    UPDATE_ORG_MEMBER: 'UPDATE_ORG_MEMBER',
    UPDATE_ORG_MEMBER_FAIL: 'UPDATE_ORG_MEMBER_FAIL',
    UPDATE_ORG_MEMBER_SUCCESS: 'UPDATE_ORG_MEMBER_SUCCESS',
    GET_ORG_INTEG: 'GET_ORG_INTEG',
    GET_ORG_INTEG_FAIL: 'GET_ORG_INTEG_FAIL',
    GET_ORG_INTEG_SUCCESS: 'GET_ORG_INTEG_SUCCESS',
    GET_ORG_INTEG_ORDER: 'GET_ORG_INTEG_ORDER',
    GET_ORG_INTEG_ORDER_FAIL: 'GET_ORG_INTEG_ORDER_FAIL',
    GET_ORG_INTEG_ORDER_SUCCESS: 'GET_ORG_INTEG_ORDER_SUCCESS',
    GET_INTEGRATION: 'GET_INTEGRATION',
    GET_INTEGRATION_FAIL: 'GET_INTEGRATION_FAIL',
    GET_INTEGRATION_SUCCESS: 'GET_INTEGRATION_SUCCESS',
    GET_INTEGRATION_ORDERS: 'GET_INTEGRATION_ORDERS',
    GET_INTEGRATION_ORDERS_FAIL: 'GET_INTEGRATION_ORDERS_FAIL',
    GET_INTEGRATION_ORDERS_SUCCESS: 'GET_INTEGRATION_ORDERS_SUCCESS',
    GET_INTEGRATION_LOCATION_GROUP: 'GET_INTEGRATION_LOCATION_GROUP',
    GET_INTEGRATION_LOCATION_GROUP_FAIL: 'GET_INTEGRATION_LOCATION_GROUP_FAIL',
    GET_INTEGRATION_LOCATION_GROUP_SUCCESS: 'GET_INTEGRATION_LOCATION_GROUP_SUCCESS',
    CREATE_WMS: 'CREATE_ORG_MEMBER',
    CREATE_WMS_SUCCESS: 'CREATE_ORG_MEMBER_FAIL',
    CREATE_LOCATION_GROUP: 'CREATE_LOCATION_GROUP',
    CREATE_LOCATION_GROUP_SUCCESS: 'CREATE_LOCATION_GROUP_SUCCESS',
    GET_MANUFACTURER: 'GET_MANUFACTURER',
    GET_MANUFACTURER_FAIL: 'GET_MANUFACTURER_FAIL',
    GET_MANUFACTURER_SUCCESS: 'GET_MANUFACTURER_SUCCESS',
    UPDATE_ORG_IMAGE: 'UPDATE_ORG_IMAGE',
    RELEASE_NOTES: 'RELEASE_NOTES',
    RELEASE_NOTES_FAIL: 'RELEASE_NOTES_FAIL',
    RELEASE_NOTES_SUCCESS: 'RELEASE_NOTES_SUCCESS',
}

export function getManufacturers(slug) {
    return {
        type: actionTypes.GET_MANUFACTURER,
        payload: {
            request: {
                url: `api/organizations/${slug}/manufacturers/`,
                method: 'GET',
            },
        },
    }
}
export function getOrgTeams(slug, detailed, field = 'title') {
    return {
        type: actionTypes.GET_ORG_TEAMS,
        payload: {
            request: {
                url: `api/organizations/${slug}/teams/?ordering=${field}&${
                    detailed ? 'detailed=true' : ''
                }`,
                method: 'GET',
            },
        },
    }
}

export function getIntegrationLocationGroup({ uuid }) {
    return {
        type: actionTypes.GET_INTEGRATION_LOCATION_GROUP,
        payload: {
            request: {
                url: `api/integrations/${uuid}/locations/groups/`,
                method: 'GET',
            },
        },
    }
}

export function getIntegrationOrders({ uuid }) {
    return {
        type: actionTypes.GET_INTEGRATION_ORDERS,
        payload: {
            request: {
                url: `api/integrations/${uuid}/orders/`,
                method: 'GET',
            },
        },
    }
}

export function getIntegration({ uuid }) {
    return {
        type: actionTypes.GET_INTEGRATION,
        payload: {
            request: {
                url: `api/integrations/${uuid}/`,
                method: 'GET',
            },
        },
    }
}

export function createLocationGroup({ uuid, ...data }) {
    return {
        type: actionTypes.CREATE_LOCATION_GROUP,
        payload: {
            request: {
                url: `api/integrations/${uuid}/locations/groups/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function createOrgIntegration({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_WMS,
        payload: {
            request: {
                url: `api/organizations/${slug}/integrations/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getOrgIntegration({ slug }) {
    return {
        type: actionTypes.GET_ORG_INTEG,
        payload: {
            request: {
                url: `api/organizations/${slug}/integrations/`,
                method: 'GET',
            },
        },
    }
}

export function getOrgIntegrationOrders({ slug }) {
    return {
        type: actionTypes.GET_ORG_INTEG_ORDER,
        payload: {
            request: {
                url: `api/organizations/${slug}/integrations/orders/`,
                method: 'GET',
            },
        },
    }
}

export function createOrgMember({ slug, data }) {
    return {
        type: actionTypes.CREATE_ORG_MEMBER,
        payload: {
            request: {
                url: `api/organizations/${slug}/memberships/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getOrgMembers(slug, field = 'user') {
    return {
        type: actionTypes.GET_ALL_ORG_MEMBERS,
        payload: {
            request: {
                url: `api/organizations/${slug}/memberships/?ordering=${field}`,
                method: 'GET',
            },
        },
    }
}

export function removeOrgMember({ uuid }) {
    return {
        type: actionTypes.REMOVE_ORG_MEMBER,
        payload: {
            request: {
                url: `api/organizations/memberships/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateOrgMembership({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_ORG_MEMBER,
        payload: {
            request: {
                url: `api/organizations/memberships/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getWebhooks({ slug }, field = 'url') {
    return {
        type: actionTypes.GET_ORG_WEBHOOKS,
        payload: {
            request: {
                url: `api/organizations/${slug}/webhooks/?ordering=${field}`,
                method: 'GET',
            },
        },
    }
}

export function removeWebHook({ uuid }) {
    return {
        type: actionTypes.DELETE_ORG_WEBHOOK,
        payload: {
            request: {
                url: `api/webhooks/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateWebHook({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_ORG_WEBHOOK,
        payload: {
            request: {
                url: `api/webhooks/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function createWebHook({ slug, data }) {
    return {
        type: actionTypes.CREATE_ORG_WEBHOOK,
        payload: {
            request: {
                url: `api/organizations/${slug}/webhooks/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getOrgVehicleCategories({ slug }, field = 'title') {
    return {
        type: actionTypes.GET_ORG_CATEGORIES,
        payload: {
            request: {
                url: `api/organizations/${slug}/vehicles/categories/?ordering=${field}`,
                method: 'GET',
            },
        },
    }
}

export function deleteCategory({ uuid }) {
    return {
        type: actionTypes.DELETE_ORG_CATEGORY,
        payload: {
            request: {
                url: `api/vehicles/categories/${uuid}`,
                method: 'DELETE',
            },
        },
    }
}

export function updateOrgVehicleCategory({ uuid, ...data }) {
    return {
        type: actionTypes.UPDATE_ORG_CATEGORY,
        payload: {
            request: {
                url: `api/vehicles/categories/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function createOrgVehicleCategory({ slug, ...data }) {
    return {
        type: actionTypes.CREATE_ORG_CATEGORY,
        payload: {
            request: {
                url: `api/organizations/${slug}/vehicles/categories/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getOrgs() {
    return {
        type: actionTypes.GET_ORGS,
        payload: {
            request: {
                url: 'api/organizations/memberships/',
                method: 'GET',
            },
        },
    }
}

export function getCustomValue() {
    return {
        type: actionTypes.GET_CUSTOMVAlUE,
        payload: {
            request: {
                url: 'api/site/',
                method: 'GET',
            },
        },
    }
}

export function createOrg(data) {
    return {
        type: actionTypes.CREATE_ORG,
        payload: {
            request: {
                url: 'api/organizations/',
                method: 'POST',
                data,
            },
        },
    }
}

export function deleteOrg({ uuid }) {
    return {
        type: actionTypes.DELETE_ORG,
        payload: {
            request: {
                url: `api/organizations/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}

export function updateOrg({ uuid, data }) {
    return {
        type: actionTypes.UPDATE_ORG,
        payload: {
            request: {
                url: `api/organizations/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function updateCustomeValue({ data }) {
    return {
        type: actionTypes.UPDATE_CUSTOMVALUE,
        payload: {
            request: {
                url: `api/site/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function getAllAdminOrgAndTeam() {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch(getOrgs()).then(({ payload }) => {
                if (payload?.data?.length > 0) {
                    let teams = []
                    const adminOrgs = payload.data.filter(
                        (org) =>
                            ADMIN_PERMISSIONS.findIndex((perm) => perm === org.membership_type) > -1
                    )
                    if (adminOrgs.length > 0) {
                        dispatch(getOrgTeams(adminOrgs[0].organization.slug)).then(
                            ({ payload }) => {
                                if (payload?.data) teams = payload.data
                                resolve({ list: adminOrgs, teams })
                            }
                        )
                    }
                }
            })
        })
}

export function getOrgEvents({ slug }) {
    return {
        type: actionTypes.GET_ORG_EVENTS,
        payload: {
            request: {
                url: `api/organizations/${slug}/events/`,
                method: 'GET',
            },
        },
    }
}

export function getOrgBySlug({ slug }) {
    return {
        type: actionTypes.GET_ORG,
        payload: {
            request: {
                url: `api/organizations/${slug}/`,
                method: 'GET',
            },
        },
    }
}

export function updateOrgImage({ slug, data }) {
    return {
        type: actionTypes.UPDATE_ORG_IMAGE,
        payload: {
            responseType: 'blob',
            request: {
                url: `api/organizations/${slug}/image/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function getReleaseNotes(uuid) {
    return {
        type: actionTypes.RELEASE_NOTES,
        payload: {
            request: {
                url: `api/organizations/${uuid}/release_note/`,
                method: 'GET',
            },
        },
    }
}
