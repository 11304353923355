import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Button, Input, FormGroup, Label, Spinner } from 'reactstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import { triggerSchema } from 'schemas/dashboard'
import { customInputForm } from 'ui/common'
import { createTrigger, getTriggers, updateTrigger } from 'features/dashboard/Trigger.actionTypes'
import { selectors } from 'features/dashboard'
import DropdownSelect from 'layout-components/Dropdown'

const TriggerModal = ({ slug, toggle, trigger = {}, tasks, toggleRemove, areaUuid }) => {
    const dispatch = useDispatch()
    const stations = useSelector(selectors.getMapStations)
    const actionTypes = [
        { uuid: 'location_confirm', name: 'Location confirm' },
        { uuid: 'location_fail', name: 'Location fail' },
        { uuid: 'task_launch', name: 'Mission launch' },
    ]
    const [action, setAction] = useState(
        actionTypes.find((a) => a.uuid === trigger.action)?.name || ''
    )
    const [station, setStation] = useState(
        stations?.find((s) => s.uuid === trigger.station)?.title || ''
    )
    const [missionPreset, setMissionPreset] = useState(
        tasks.find((t) => t.uuid === trigger.task_preset)?.title || ''
    )

    const handleSubmit = (data, action) => {
        if (trigger.uuid) {
            const uuid = data.uuid
            dispatch(updateTrigger({ uuid, ...data })).then(({ error }) => {
                action.setSubmitting(false)
                if (!error) {
                    dispatch(getTriggers({ slug }))
                    toggle()
                }
            })
        } else {
            dispatch(createTrigger({ slug, data })).then(({ error }) => {
                action.setSubmitting(false)
                if (!error) {
                    dispatch(getTriggers({ slug }))
                    toggle()
                }
            })
        }
    }

    return (
        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`w-100 d-flex ${
                    trigger.uuid ? 'justify-content-end' : 'justify-content-between'
                }`}
            >
                {!trigger.uuid && (
                    <img
                        src="/svgs/trigger-actions/add-plus.svg"
                        alt="plus"
                        width="48px"
                        height="48px"
                    />
                )}
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="x"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>{trigger.uuid ? 'Edit trigger' : 'Add new trigger'}</h5>
            <Formik
                initialValues={trigger}
                validationSchema={triggerSchema}
                onSubmit={handleSubmit}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <FormGroup>
                            <Label for="verbose_name" className="modals-labels">
                                Name *
                            </Label>
                            <Field
                                component={customInputForm}
                                className="modals-inputs"
                                type="text"
                                id="verbose_name"
                                name="verbose_name"
                                placeholder="Name"
                                required
                                value={values?.verbose_name || ''}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="action" className="modals-labels">
                                Action {trigger.uuid ? '' : '*'}
                            </Label>
                            <DropdownSelect
                                selected={action}
                                setSelected={setAction}
                                options={actionTypes}
                                setFieldValue={setFieldValue}
                                fieldValue="action"
                            />
                            {/* <Field
                                component={customInputForm}
                                className="modals-inputs"
                                type="select"
                                required
                                readOnly={trigger.uuid}
                                disabled={trigger.uuid}
                                name="action"
                                value={values?.action || ''}
                            >
                                <option value=""></option>
                                <option value="location_confirm">Location confirm</option>
                                <option value="location_fail">Location fail</option>
                                <option value="task_launch">Mission launch</option>
                            </Field> */}
                        </FormGroup>

                        {values.action === 'task_launch' && (
                            <FormGroup>
                                <Label for="task_preset" className="modals-labels">
                                    Mission preset *
                                </Label>
                                <DropdownSelect
                                    selected={missionPreset}
                                    setSelected={setMissionPreset}
                                    options={tasks.map((task) => task)}
                                    setFieldValue={setFieldValue}
                                    fieldValue="task_preset"
                                />
                                {/* <Field
                                    type="select"
                                    component={customInputForm}
                                    name="task_preset"
                                    required
                                    className="modals-inputs"
                                    value={values?.task_preset || ''}
                                >
                                    <option value=""></option>
                                    {tasks?.map((task) => (
                                        <option key={task?.uuid} value={task.uuid}>
                                            {task.title}
                                        </option>
                                    ))}
                                </Field> */}
                            </FormGroup>
                        )}

                        {values.action && values.action !== 'task_launch' && (
                            <FormGroup>
                                <Label for="station" className="modals-labels">
                                    Station *
                                </Label>
                                <DropdownSelect
                                    selected={station}
                                    setSelected={setStation}
                                    options={stations.map((station) => station)}
                                    setFieldValue={setFieldValue}
                                    fieldValue="station"
                                />
                                {/* <Field
                                type="select"
                                component={customInputForm}
                                name="station"
                                required
                                className="modals-inputs"
                                value={values?.station || ''}
                            >
                                <option value=""></option>
                                {stations.map((station) => (
                                    <option key={station?.uuid} value={station.uuid}>
                                        {station.title}
                                    </option>
                                ))}
                            </Field> */}
                            </FormGroup>
                        )}
                        <FormGroup>
                            <Label for="ip_whitelist" className="modals-labels">
                                ip whiteList
                            </Label>
                            <Field
                                component={customInputForm}
                                className="modals-inputs"
                                type="text"
                                id="ip_whitelist"
                                name="ip_whitelist[0]"
                                placeholder="ip_whitelist"
                                value={values?.ip_whitelist ? values.ip_whitelist[0] : '' || ''}
                            />
                        </FormGroup>

                        <FormGroup check className='ml-1 mb-2'>
                            <Input
                                type="checkbox"
                                id="use_ip_authentication"
                                className="modals-labels"
                                checked={values?.use_ip_authentication || false}
                                onChange={() => {
                                    setFieldValue(
                                        'use_ip_authentication',
                                        !values.use_ip_authentication
                                    )
                                }}
                            />
                            <Label check> Use IP Authentication </Label>
                        </FormGroup>
                        <FormGroup check className='ml-1'>
                            <Input
                                type="checkbox"
                                id="initialized"
                                className="modals-labels"
                                checked={values?.initialized || true}
                                onChange={() => {
                                    setFieldValue(
                                        'initialized',
                                        !values.initialized
                                    )
                                }}
                            />
                            <Label check>Initialized</Label>
                        </FormGroup>
                        <div
                            style={{ marginTop: '24px' }}
                            className={`w-100 d-flex ${
                                trigger.uuid ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {trigger.uuid ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        toggleRemove()
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete trigger
                                </Button>
                            ) : null}
                            <div className="d-flex">
                                <Button
                                    color="white"
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                    onClick={() => toggle()}
                                >
                                    Discard
                                </Button>
                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                    {isSubmitting && <Spinner color="white" size="sm ml-2" />}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default TriggerModal
